import { RiArrowRightSLine, RiAsterisk } from 'react-icons/ri';
import './account.notifications.css';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/AuthProvider';
import { IoMdWarning } from 'react-icons/io';
import { axiosPrivate } from '../../../api/axios';
import Modal from '../../components/modal/Modal';
import { BiSearch, BiSolidMessage } from 'react-icons/bi';
import { BsPerson } from 'react-icons/bs';
import { PiPlus } from 'react-icons/pi';
import { ScaleLoader } from 'react-spinners';

function AccountNotifications() {
  const [content, setContent] = useState(false);
  const [conversation, setNewConversation] = useState(false);
  const [conversations, setConversations] = useState([]);

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const [media, setMedia] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [receiverId, setReceiverid] = useState('');
  const [receiverName, setReceiverName] = useState('');

  const { auth, setAuth, isDark } = useContext(AuthContext);

  const closeModal = () => {
    setNewConversation(false);
  };

  const navigate = useNavigate();

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
    select3: false,
  });

  const [result, setResult] = useState([]);
  const [totalResult, setTotalResult] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const observer = useRef();

  // INTERSECTION OBSERVER THAT INCREASS THE PAGE NUMBER BY ONE EVERY TIME THE LAST ELEMENT OF THE RETURNED PAGINATED PROPERTY RESULT APPEARS ONS CREEN
  const lastProperty = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (loading) {
            setPageNumber((pageNumber) => pageNumber + 1);
          } else if (!loading) {
            setPageNumber(1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    const getNotices = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/notice/notices`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setResult(res.notices);
        setTotalResult(res.totalItems);
        setLoading(true);
        setHasMore(pageNumber < res.totalPages);
      } catch (error) {
        console.log(error);
      }
    };

    getNotices();

    return;
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="account__notifications">
          <section className="personal__section__one">
            <h3>
              account <RiArrowRightSLine className="personal__icon__one" /> notifications
            </h3>
            <h1>Notifications and inboxes</h1>
          </section>
          <section className="account__notifications__section__two">
            <div className="account__notifications__select__div">
              <button
                className={
                  select.select1
                    ? 'account__notifications__select selected'
                    : 'account__notifications__select'
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: true,
                    select2: false,
                    select3: false,
                  }))
                }
              >
                Notifications
              </button>
              <button
                className={
                  select.select2
                    ? 'account__notifications__select selected'
                    : 'account__notifications__select'
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: false,
                    select2: true,
                    select3: false,
                  }))
                }
              >
                Newsletters
              </button>
            </div>
          </section>
          <section className="account__notifications__section__three">
            {select.select2 ? (
              <div className="account__notifications__section__three__newsletter">
                <div className="account__notifications__section__three__newsletter__subscribe">
                  <IoMdWarning className="account__notifications__section__three__newsletter__subscribe__icon" />
                  <div>
                    <h3>You are not subscribed to our newsletters yet</h3>
                    <p>
                      Subscribe to newsletter to get firsthand tip on discounts, trending properties
                      and special tips and offers
                    </p>
                    <h4>Subscribe to newsletter</h4>
                  </div>
                </div>
                <div className="account__notifications__section__three__newsletter__caution">
                  <p>
                    By opting in to receive monthly newsletters, you agree to receive automated
                    marketing messages from Get properties solution at {auth.user.email}. To receive
                    newsletters at a different email,
                    <span
                      onClick={() => navigate('/account/personal-info')}
                      style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                      update your email settings
                    </span>
                    on your personal info page.
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {select.select1 ? (
              <div className="account__notifications__section__three__notifications">
                {result.map((item, i) => {
                  if (result[i].to == 'End Users') {
                    if (result.length === i + 1) {
                      return (
                        <div
                          className="account__notifications__section__three__single__notification"
                          ref={lastProperty}
                        >
                          <span>
                            <h3>{result[i].heading}</h3>
                            <h4>{result[i].createdAt}</h4>
                          </span>
                          <p>{result[i].description}</p>
                        </div>
                      );
                    } else {
                      return (
                        <div className="account__notifications__section__three__single__notification">
                          <span>
                            <h3>{result[i].heading}</h3>
                            <h4>{result[i].createdAt}</h4>
                          </span>
                          <p>{result[i].description}</p>
                        </div>
                      );
                    }
                  } else {
                    return;
                  }
                })}
              </div>
            ) : (
              <></>
            )}
          </section>
        </div>
      )}
    </>
  );
}

export default AccountNotifications;
