import Header from '../../components/layout/header/Header';
import './Single.agency.css';
import { useNavigate } from 'react-router-dom';
import { IoIosSearch } from 'react-icons/io';
import { useContext, useEffect, useState } from 'react';
import CardlistAgents from '../../components/cardlist/CardlistAgents';
import AccountFooter from '../../components/layout/account.footer/Account.footer';
import CardlistAgencyListing from '../../components/cardlist/CardlistAgencyListing';
import CardlistReview from '../../components/cardlist/CardlistReview';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import { RiAsterisk } from 'react-icons/ri';
import Modal from '../../components/modal/Modal';
import { Image, Placeholder, Transformation } from 'cloudinary-react';
import NothingToDisplay from '../../../utils/nothing.to.display/NothingToDisplay';
import { ScaleLoader } from 'react-spinners';

function SingleAgency() {
  const { auth, userId, applicationId, isDark } = useContext(AuthContext);

  const navigate = useNavigate();
  const [result, setResult] = useState({});
  const [user, setUser] = useState({});
  const [listings, setListings] = useState([]);
  const [agents, setAgents] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [comment, setComment] = useState('');
  const [isOpen, setIsOpen] = useState(null);
  const [rating, setRating] = useState(0);

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
    select3: false,
    select4: false,
    select5: false,
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const getSingleAgency = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agency/single-agency/${userId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setResult(res);
      } catch (error) {
        console.log(error);
      }
    };

    const increaseViewCount = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agency/increase-view/${userId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    const getAgencyListing = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agency/agency-listings/${userId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        console.log('agency listings', res);
        setListings(res.agencyListings);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllReviews = async () => {
      try {
        const response = await axiosPrivate.post(
          `api/v1/review/reviews`,
          JSON.stringify({
            type: 'agency',
            id: applicationId,
            userId: userId,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );
        const res = response.data.data;
        setReviews(res.review);
      } catch (error) {
        console.log(error);
      }
    };

    const getAgencyAgents = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agency/agency-agents/${userId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setAgents(res.agencyAgents);
      } catch (error) {
        console.log(error);
      }
    };

    getAgencyListing();
    getAgencyAgents();
    getSingleAgency();
    increaseViewCount();
    getAllReviews();

    return;
  }, []);

  const submit = async () => {
    try {
      const response = await axiosPrivate.post(
        `api/v1/review/create-review`,
        JSON.stringify({
          userId: auth.user.id,
          rating: rating,
          comment: comment,
          type: 'agency',
          agencyId: result.id,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.data;
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div>
          <Header />
          <div className="singleagency">
            <section className="singleagency__section__one">
              <h1>Urban {result.agency_name}</h1>
              <h3>{result.office_address}</h3>
              <div>
                <input placeholder="Search listed properties" type="text" />
                <button>
                  <IoIosSearch className="singleagency__section__one__icon" />
                </button>
              </div>
            </section>
            <section className="singleagency__section__two">
              <div
                className="account__notifications__select__div"
                style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}
              >
                <button
                  className={
                    select.select1
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: true,
                      select2: false,
                      select3: false,
                      select4: false,
                      select5: false,
                    }))
                  }
                >
                  Home
                </button>
                <button
                  className={
                    select.select2
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: false,
                      select2: true,
                      select3: false,
                      select4: false,
                      select5: false,
                    }))
                  }
                >
                  About
                </button>
                <button
                  className={
                    select.select3
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: false,
                      select2: false,
                      select3: true,
                      select4: false,
                      select5: false,
                    }))
                  }
                >
                  Listings
                </button>
                <button
                  className={
                    select.select4
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: false,
                      select2: false,
                      select3: false,
                      select4: true,
                      select5: false,
                    }))
                  }
                >
                  Reviews
                </button>
                <button
                  className={
                    select.select5
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: false,
                      select2: false,
                      select3: false,
                      select4: false,
                      select5: true,
                    }))
                  }
                >
                  Agents
                </button>
              </div>
              <div className="singleagency__section__two__segment2">
                {select.select1 ? (
                  <div className="singleagency__section__two__segment2__home">
                    <section className="singleagency__section__two__segment2__home__section1">
                      <div className="singleagency__section__two__segment2__home__section1__div1">
                        {/* <img src={agency_img} alt="" /> */}
                        {/*  <Image  quality="auto:best"   loading="lazy"   cloudName="du1dvxjo8" publicId={result.profile_image} width="300" crop="scale" >  <Placeholder 
    type="blur">
  </Placeholder>
</Image> */}
                        <Image
                          quality="auto:best"
                          loading="lazy"
                          cloudName="du1dvxjo8"
                          publicId={result.profile_image}
                          width="auto"
                          crop="scale"
                        >
                          <Placeholder type="vectorize"></Placeholder>
                          <Transformation quality="auto" fetchFormat="auto" />
                        </Image>
                      </div>

                      <div className="singleagency__section__two__segment2__home__section1__div2">
                        <h3>Welcome to {result.agency_name}</h3>
                        <p>{result.description}</p>
                        <article>
                          <div>
                            <h3>Mobile</h3>
                            <p>{result.mobile_phone}</p>
                          </div>
                          <div>
                            <h3>Office</h3>
                            <p>{result.office_number}</p>
                          </div>
                          <div>
                            <h3>Email</h3>
                            <p>{result.business_email}</p>
                          </div>
                          <div>
                            <h3>Website</h3>
                            <p>{result.website}</p>
                          </div>
                        </article>
                      </div>
                    </section>
                    <section className="singleagency__section__two__segment2__home__section2">
                      <div>
                        <h3>Number of listings</h3>
                        <h2>{listings.length}</h2>
                      </div>
                      <div>
                        <h3>Number of reviews</h3>
                        <h2>{reviews.length}</h2>
                      </div>
                      <div>
                        <h3>Number of agents</h3>
                        <h2>{agents.length}</h2>
                      </div>
                      <div>
                        <h3>Number of views</h3>
                        <h2>{result.views}</h2>
                      </div>
                    </section>
                    <section>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6654220632436!2d3.507026175985806!3d6.437000024160492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf67d389945e5%3A0x1866152ec04410c2!2sKm%2042%20Lekki%20-%20Epe%20Expy%2C%20Lekki%20Penninsula%20II%2C%20Lagos%20101245%2C%20Lagos!5e0!3m2!1sen!2sng!4v1706347243090!5m2!1sen!2sng"
                        width="600"
                        height="450"
                        style={{ border: '0', width: '100%' }}
                        allowfullscreen=""
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </section>
                  </div>
                ) : (
                  <></>
                )}
                {select.select2 ? (
                  <div className="singleagency__section__two__segment2__about">
                    <h1>Things to know about Urban homes</h1>
                    <div>
                      <h3>Description</h3>
                      <p>{result.description}</p>
                    </div>
                    <div>
                      <h3>Corperate values</h3>
                      <p>{result.corperate_values}</p>
                    </div>
                    <div>
                      <h3>History</h3>
                      <p>{result.history}</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {select.select3 ? (
                  <div className="">
                    {listings && listings.length > 0 ? (
                      <div className="">
                        <CardlistAgencyListing listings={listings} />
                      </div>
                    ) : (
                      <NothingToDisplay />
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {select.select4 ? (
                  <div>
                    <div className="singleagent__section__two__review">
                      <span>
                        <h3>Share your experience with others</h3>
                        <button onClick={() => setIsOpen(true)}>Give review</button>
                      </span>
                    </div>
                    {reviews && reviews.length > 0 ? (
                      <div className="">
                        <CardlistReview reviews={reviews} />
                      </div>
                    ) : (
                      <NothingToDisplay />
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {select.select5 ? (
                  <div className="">
                    {agents && agents.length > 0 ? (
                      <div className="">
                        <CardlistAgents result={agents} />
                      </div>
                    ) : (
                      <NothingToDisplay />
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </section>
          </div>
          <AccountFooter />

          {/* Give review modal */}
          <Modal isOpen={isOpen} onClose={closeModal}>
            <div className="">
              <div className="admin__message__conversation">
                <section className="admin__message__conversation__section__one">
                  <h1>Give review about this agent </h1>
                </section>
                <section className="admin__message__conversation__section__two">
                  <h1>
                    Rating (1 representing leaast and 5 representing highest){' '}
                    <RiAsterisk className="admin__message__conversation__section__two__icons" />
                  </h1>
                  <select
                    name="category"
                    id="category"
                    onChange={(e) => setRating(e.target.value)}
                    className="list__input"
                  >
                    <option value={null}>--</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <h1>
                    Comment{' '}
                    <RiAsterisk className="admin__message__conversation__section__two__icons" />
                  </h1>
                  <textarea
                    type="text"
                    name="text"
                    rows="8"
                    cols="6"
                    wrap="soft"
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </section>
                <section className="admin__message__conversation__section__three">
                  <div>
                    <button onClick={closeModal}>Cancel</button>
                    <button onClick={submit}>Send</button>
                  </div>
                </section>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default SingleAgency;
