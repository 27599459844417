import { RiArrowRightSLine } from 'react-icons/ri';
import './Account.student.css';
import { GiPadlock } from 'react-icons/gi';

function AccountStudent() {
  return (
    <div className="account__student">
      <section className="personal__section__one">
        <h3>
          account <RiArrowRightSLine className="personal__icon__one" /> student
        </h3>
        <h1>Student</h1>
      </section>
      <section className="account__student__two">
        <div className="account__student__two__notstudent">
          <h3>
            <GiPadlock /> Unauthorized
          </h3>
          <p>
            You cannot access this page because are not a register student. To registered as a student user, kindly fill
            out the form and submit
          </p>
          <button>register</button>
        </div>
      </section>
    </div>
  );
}

export default AccountStudent;
