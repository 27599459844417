import React, { useContext } from 'react';
import './CardAgency.css';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/AuthProvider';
import { Image, Placeholder, Transformation } from 'cloudinary-react';

function CardAgency({ name, address, index, mobile, email, office, userId, image, documentImage }) {
  const { setUserId, setApplicationId } = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <div
      className="card__agency"
      onClick={() => {
        setUserId(userId);
        setApplicationId(index);
        navigate(`/agency/${index}`);
      }}
    >
      <div className="card__agency__segment1">
        <Image quality="auto:best" loading="lazy" cloudName="du1dvxjo8" publicId={image} width="300" crop="scale">
          <Placeholder type="vectorize"></Placeholder>
          <Transformation quality="auto" fetchFormat="auto" />
        </Image>
        <h1>{name}</h1>
      </div>
      <div className="card__agency__segment2">
        <span>
          <h3>address</h3>
          <h1>{address}</h1>
        </span>
        <span>
          <h3>Office</h3>
          <h1>{office}</h1>
        </span>
        <span>
          <h3>Mobile</h3>
          <h1>{mobile}</h1>
        </span>
        <span>
          <h3>Email</h3>
          <h1>{email}</h1>
        </span>
      </div>
    </div>
  );
}

export default CardAgency;
