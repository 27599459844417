import { axiosPrivate } from '../api/axios';

export const getSingleProperty = async (propertyId) => {
  try {
    const response = await axiosPrivate.post(`api/v1/property/property/${propertyId}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    const res = response.data.data;
    return res;
  } catch (error) {
    return error;
  }
};
