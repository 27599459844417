import './account.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import Head from '../../components/layout/head/Head';
import AccountFooter from '../../components/layout/account.footer/Account.footer';
import gpslogomain from '../../../assets/gpslogomain.png';
import { PiIdentificationCardLight } from 'react-icons/pi';
import { MdOutlinePayment } from 'react-icons/md';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { SlLogin, SlLogout } from 'react-icons/sl';
import { AiOutlineArrowsAlt, AiOutlineEye } from 'react-icons/ai';
import { RiFilePaper2Line } from 'react-icons/ri';
import AuthContext from '../../../context/AuthProvider';
import { LuTicket } from 'react-icons/lu';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { ScaleLoader } from 'react-spinners';

function Account() {
  const { setAuth, theme, isDark } = useContext(AuthContext);

  const [active, SetActive] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const [colorId, setColorId] = useState(1);

  const logout = async () => {
    try {
      const response = await axiosPrivate.post('api/v1/auth/logout', {
        headers: { 'Content-Type': 'application/json' },
      });
      setAuth({});
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div theme={theme ? `${theme}` : '#008000'}>
          <div className={active ? 'account__navbar active' : 'account__navbar'}>
            <section className="account__navbar__section__one">
              <img
                src={gpslogomain}
                alt=""
                className={active ? 'header__logo__account active' : 'header__logo__account'}
                onClick={() => {
                  navigate('/');
                }}
              />
              <AiOutlineArrowsAlt
                className={active ? 'account__menu active' : 'account__menu'}
                onClick={() => SetActive(!active)}
              />
            </section>
            <section className="account__navbar__section__two">
              <div
                onClick={() => {
                  SetActive(true);
                  navigate('/account/personal-info');
                  setColorId(1);
                }}
                className={colorId === 1 ? 'account__navbar__active' : ''}
              >
                <PiIdentificationCardLight className="account__navbar__icon" />
                <h3>Personal info</h3>
              </div>
              <div
                onClick={() => {
                  SetActive(true);
                  navigate('/account/payment');
                  setColorId(2);
                }}
                className={colorId === 2 ? 'account__navbar__active' : ''}
              >
                <MdOutlinePayment className="account__navbar__icon" />
                <h3>Payments and payouts</h3>
              </div>
              <div
                onClick={() => {
                  SetActive(true);
                  navigate('/account/login');
                  setColorId(3);
                }}
                className={colorId === 3 ? 'account__navbar__active' : ''}
              >
                <SlLogin className="account__navbar__icon" />
                <h3>Login and security</h3>
              </div>
              <div
                onClick={() => {
                  SetActive(true);
                  navigate('/account/notifications');
                  setColorId(4);
                }}
                className={colorId === 4 ? 'account__navbar__active' : ''}
              >
                <IoIosNotificationsOutline className="account__navbar__icon" />
                <h3>Notifications and newsletters</h3>
              </div>

              <div
                onClick={() => {
                  SetActive(true);
                  navigate('/account/ticket');
                  setColorId(10);
                }}
                className={colorId === 10 ? 'account__navbar__active' : ''}
              >
                <LuTicket className="account__navbar__icon" />
                <h3>Tickets</h3>
              </div>
              <div
                onClick={() => {
                  SetActive(true);
                  navigate('/account/privacy-and-sharing');
                  setColorId(5);
                }}
                className={colorId === 5 ? 'account__navbar__active' : ''}
              >
                <AiOutlineEye className="account__navbar__icon" />
                <h3>Privacy and sharing</h3>
              </div>
              {/* <div
            onClick={() => {
              SetActive(true)
              navigate('/account/student');
              setColorId(6);
            }}
            className={colorId === 6 ? 'account__navbar__active' : ''}
          >
            <PiStudentLight className="account__navbar__icon" />
            <h3>Student</h3>
          </div> */}
            </section>
            <section className="account__navbar__section__three">
              <div
                onClick={() => {
                  navigate('/account/terms-and-conditions');
                  setColorId(7);
                }}
                className={colorId === 7 ? 'account__navbar__active' : ''}
              >
                <RiFilePaper2Line className="account__navbar__icon" />
                <h3>Terms and conditions</h3>
              </div>
              <div onClick={logout}>
                <SlLogout className="account__navbar__icon" />
                <h3>Log out</h3>
              </div>
            </section>
          </div>
          <div>
            <div className="account__outlet">
              <Head />
              <Outlet />
              <AccountFooter />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Account;
