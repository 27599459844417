import { useContext, useState } from 'react';
import './Dashboard.header.css';
import { FaPowerOff } from 'react-icons/fa';
import { RiArrowRightSLine } from 'react-icons/ri';
import { IoNotificationsOutline } from 'react-icons/io5';
import { axiosPrivate } from '../../../../api/axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../../context/AuthProvider';
import { Toggle } from '../../ui/toggle/Toggle';
import Toaster from '../../../../utils/toaster/Toaster';

function DashboardHeader({ path }) {
  const { isDark, setIsDark, theme, setAuth } = useContext(AuthContext);
  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const response = await axiosPrivate.post('api/v1/auth/logout', {
        headers: { 'Content-Type': 'application/json' },
      });
      setAuth({});
      navigate('/login');
    } catch (error) {
      setToaster(true);
      setbgcolor('#00e396');
      setMessage(error.response.data.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    }
  };

  return (
    <div
      className="dashboard__header"
      data-theme={isDark ? 'dark' : 'light'}
      theme={theme ? 'theme' : 'default'}
    >
      {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

      <div className="dashboard__header__path">
        <h3>
          Admin <RiArrowRightSLine className="personal__icon__one" /> {path}
        </h3>
      </div>
      <div className="dashboard__header__toggle">
        <Toggle
          isChecked={isDark}
          handleChange={() => {
            setIsDark(!isDark);
          }}
        />
        {/* <span>
          <IoNotificationsOutline className="dashboard__header__icon" />
        </span> */}
        <span onClick={logout} id="dashboard__logout">
          <FaPowerOff className="dashboard__header__icon" />
          Logout
        </span>
      </div>
    </div>
  );
}

export default DashboardHeader;
