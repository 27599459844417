import { useContext } from 'react';
import { Helmet } from 'react-helmet';

import './Terms.css';
import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import AuthContext from '../../../context/AuthProvider';

function Terms() {
  //Context declaration
  const { currency } = useContext(AuthContext);

  return (
    <div className="terms__bg">
      <Helmet>
        <title>Terms and Conditions | GPS</title>
      </Helmet>
      <Header />
      <div className="terms">
        <section className="terms__section__one">
          <h1>The Get Properties Solution Terms of Service</h1>
          <p>
            These Terms and Conditions govern your use of the Get Properties Solution website . By
            accessing or using the Website, you agree to be bound by these Terms. If you do not
            agree with these Terms, please refrain from using the Website.
          </p>
        </section>
        <section className="terms__section__two">
          <h1> 1. Use of the Website</h1>
          <p>a. You must be at least 18 years old to use the Website. </p>
          <p>
            b. You agree to use the Website for lawful purposes only and in compliance with all
            applicable laws and regulations.
          </p>
          <h1>2. User Accounts:</h1>
          <p>
            a. Some features of the Website may require you to create a user account. You are
            responsible for maintaining the confidentiality of your account credentials.
          </p>{' '}
          <p>b. You agree to provide accurate and complete information</p>
          when creating your account. c. You are solely responsible for all activities that occur
          under your account.
          <h1>3. Property Listings:</h1>
          <p>
            {' '}
            a. The Website may display property listings for sale, lease, or rent. These listings
            are provided for informational purposes only and do not constitute an offer to sell or
            lease.
          </p>{' '}
          <p>
            b. We do not guarantee the accuracy or completeness of property listings. Users are
            encouraged to verify information independently.
          </p>
          <h1>4. Intellectual Property:</h1>
          <p>
            a. The content and materials on the Website, including text, images, logos, and
            trademarks, are owned or licensed by Get Properties Solution and protected by
            intellectual property laws.
          </p>{' '}
          <p>
            b. You may not reproduce, modify, distribute, or exploit any content from the Website
            without prior written consent.
          </p>
          <h1>5. Third-Party Links:</h1>
          <p>
            a. The Website may contain links to third-party websites or services. These links are
            provided for your convenience only.
          </p>{' '}
          <p>
            b. We do not endorse or control third-party websites and are not responsible for their
            content or privacy practices.
          </p>
          <h1>6. Privacy Policy:</h1>
          <p>
            a. Your use of the Website is subject to our Privacy Policy, which governs the
            collection, use, and disclosure of your personal information.
          </p>{' '}
          <p>b. By using the Website, you consent to the terms of our Privacy Policy.</p>
          <h1>7. Limitation of Liability:</h1>
          <p>
            a. To the fullest extent permitted by law, Get Properties Solution shall not be liable
            for any direct, indirect, incidental, consequential, or punitive damages arising out of
            your use of the Website.
          </p>{' '}
          <p>
            b. We do not guarantee the availability, accuracy, or reliability of the Website or its
            content.
          </p>
          <h1>8. Changes to Terms:</h1>
          <p>
            {' '}
            a. We reserve the right to modify or update these Terms at any time without prior
            notice. Changes will be effective upon posting to the Website.
          </p>{' '}
          <p>
            b. Your continued use of the Website after any modifications to these Terms constitutes
            acceptance of the revised Terms.
          </p>
          <h1>9. Governing Law:</h1>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of
            {currency === 'NGN' ? ' Nigeria' : ' Sierra Leone'}. Any disputes arising under or in
            connection with these Terms shall be subject to the exclusive jurisdiction of the courts
            of {currency === 'NGN' ? 'Nigeria' : 'Sierra Leone'}.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
