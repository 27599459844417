import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../context/AuthProvider';

const Layout = () => {
  const { isDark } = useContext(AuthContext);

  return (
    <main className="" data-theme={isDark ? 'dark' : 'light'}>
      <Outlet />
    </main>
  );
};

export default Layout;
