import Header from '../../components/layout/header/Header';
import './Agents.css';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountFooter from '../../components/layout/account.footer/Account.footer';
import CardlistAgents from '../../components/cardlist/CardlistAgents';
import listimg from '../../../assets/5164959.jpg';
import CardlistAgency from '../../components/cardlist/CardlistAgency';
import AuthContext from '../../../context/AuthProvider';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { axiosPrivate } from '../../../api/axios';
import NothingToDisplay from '../../../utils/nothing.to.display/NothingToDisplay';
import { ScaleLoader } from 'react-spinners';

function Agents() {
  const { auth, isDark } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });

  const [result, setResult] = useState([]);
  const [totalResult, setTotalResult] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const observer = useRef();

  const [result2, setResult2] = useState([]);
  const [totalResult2, setTotalResult2] = useState();
  const [loading2, setLoading2] = useState(false);
  const [error2, setError2] = useState(false);
  const [hasMore2, setHasMore2] = useState(false);
  const [pageNumber2, setPageNumber2] = useState(1);
  const observer2 = useRef();

  // INTERSECTION OBSERVER THAT INCREASS THE PAGE NUMBER BY ONE EVERY TIME THE LAST ELEMENT OF THE RETURNED PAGINATED PROPERTY RESULT APPEARS ONS CREEN
  const lastProperty = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (loading) {
            setPageNumber((pageNumber) => pageNumber + 1);
          } else if (!loading) {
            setPageNumber(1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const lastProperty2 = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (loading) {
            setPageNumber2((pageNumber) => pageNumber + 1);
          } else if (!loading) {
            setPageNumber2(1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    const getAgent = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agent/agents`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        console.log(res.agent);
        setResult(res.agent);
        setTotalResult(res.totalItems);
        setIsLoading(false);
        setLoading(false);
        setHasMore(pageNumber < res.totalPages);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getAgent();

    return;
  }, []);

  useEffect(() => {
    const getAgency = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agency/agencies`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setResult2(res.agency);
        setTotalResult2(res.totalItems);
        setIsLoading(false);
        setLoading2(true);
        setHasMore2(pageNumber < res.totalPages);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getAgency();

    return;
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
  };
  return (
    <div>
      <Header />
      <div className="agents">
        <section className="agents__section__one">
          <div className="agents__section__one__segment1">
            <div className="wrapper">
              <div className="static-text">GPS agents and agencies</div>
              <div className="static-text">
                get <span style={{ textDecoration: 'underline' }}>Increased visibilities</span>
              </div>
            </div>
            <p>
              Get started today by registering with GPS and get better sales returns and super admin
              proviledges
            </p>
            <button
              onClick={() => {
                auth.user
                  ? navigate('/become-an-agent')
                  : navigate('/login', { state: { from: location }, replace: true });
              }}
            >
              Get started
            </button>
          </div>

          <div className="agents__section__one__segment2">
            <img src={listimg} alt="" />
          </div>
        </section>
        <section className="agents__section__two">
          <div className="account__notifications__select__div agents__section__two__div">
            <button
              className={
                select.select1
                  ? 'account__notifications__select selected'
                  : 'account__notifications__select'
              }
              onClick={() =>
                setSelect((prevState) => ({
                  ...prevState,
                  select1: true,
                  select2: false,
                }))
              }
            >
              Agents
            </button>
            <button
              className={
                select.select2
                  ? 'account__notifications__select selected'
                  : 'account__notifications__select'
              }
              onClick={() =>
                setSelect((prevState) => ({
                  ...prevState,
                  select1: false,
                  select2: true,
                }))
              }
            >
              Agencies
            </button>
          </div>
          <div className="agents__section__two__segment2">
            {isLoading ? (
              <div style={cssDiv}>
                <ScaleLoader color={isDark ? '#fff' : '#000'} />
              </div>
            ) : select.select1 ? (
              result && result.length > 0 ? (
                <div className="">
                  <CardlistAgents result={result} />
                </div>
              ) : (
                <NothingToDisplay />
              )
            ) : select.select2 ? (
              result2 && result2.length > 0 ? (
                <div className="">
                  <CardlistAgency result2={result2} />
                </div>
              ) : (
                <NothingToDisplay />
              )
            ) : (
              <NothingToDisplay />
            )}
          </div>
        </section>
      </div>
      <AccountFooter />
    </div>
  );
}

export default Agents;
