import { useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Toaster from '../../../utils/toaster/Toaster';
import AccountFooter from '../../components/layout/account.footer/Account.footer';
import './Login.css';
import AuthContext from '../../../context/AuthProvider';
import Header from '../../components/layout/header/Header';
import { link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { ScaleLoader } from 'react-spinners';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const { auth, setAuth, persist, setPersist, isDark } = useContext(AuthContext);

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const login = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/login',
        JSON.stringify({ email: email, password: password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      setToaster(true);
      setbgcolor('#00e396');
      setMessage('Login successful');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
      setAuth((prev) => ({ ...prev, user: response.data.data.user }));
      setAuth((prev) => ({ ...prev, accessToken: response.data.data.accessToken }));
      setIsLoading(false);

      if (response.data.data.user && response.data.data.user.role) {
        const userRole = response.data.data.user.role.trim(); // Remove whitespace if any
        if (userRole === 'Admin' || userRole === 'SuperAdmin') {
          navigate('/admin/dashboard');
          return; // Exit the function to prevent further navigation
        }
      }

      navigate(from, { replace: true });
    } catch (error) {
      setIsLoading(false);
      setToaster(true);
      setbgcolor('#d1ecf1');
      setMessage(error.response.data.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    }
  };

  const googleSignin = async (userData) => {
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/google-signin',
        JSON.stringify({ userData: userData }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );

      setToaster(true);
      setbgcolor('#00e396');
      setMessage('Login successful');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
      setAuth((prev) => ({ ...prev, user: response.data.data.user }));
      setAuth((prev) => ({ ...prev, accessToken: response.data.data.accessToken }));
      setIsLoading(false);

      if (response.data.data.user.role == 'Admin' || response.data.data.user.role == 'SuperAdmin') {
        navigate('/admin/dashboard');
      }

      navigate(from, { replace: true });
    } catch (error) {
      setIsLoading(false);

      setToaster(true);
      setbgcolor('#d1ecf1');
      setMessage(error.response.data.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="login__main" data-theme={isDark ? 'dark' : 'light'}>
          <Helmet>
            <title>Signin - GPS</title>
          </Helmet>
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}
          <Header />
          <section className="login__container">
            <div className="login__box">
              <div className="login_body">
                <div className="login_form">
                  <h2>Log in to continue</h2>
                  <p className="login_text">
                    New to Get Properties Solution?{''}
                    <span className="login_h3">sign up</span>
                  </p>
                  <input
                    className="login_input"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    required
                    autoComplete
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    className="login_input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <button className="login_button" onClick={login}>
                    Login
                  </button>
                  <div className="login__remember">
                    <input type="checkbox" id="persist" checked={persist} />
                    <label htmlFor="persist">Remember my login</label>
                  </div>
                  <p className="login_text">Forgot password?</p>
                </div>
                <div>
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      const decoded = jwtDecode(credentialResponse?.credential);
                      // console.log(decoded);
                      googleSignin(decoded);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <AccountFooter />
        </div>
      )}
    </>
  );
}

export default Login;
