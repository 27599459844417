import './Student.property.css';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountFooter from '../../components/layout/account.footer/Account.footer';
import Header from '../../components/layout/header/Header';
import lsimg1 from '../../../assets/5921785.png';
import student_img1 from '../../../assets/10295009 (1).png';
import student_img2 from '../../../assets/10295015 (1).png';
import student_img3 from '../../../assets/10295016 (1).png';
import { useContext, useState } from 'react';
import AuthContext from '../../../context/AuthProvider';
import { ScaleLoader } from 'react-spinners';

function StudentProperty() {
  const { auth, isDark } = useContext(AuthContext);
  const location = useLocation();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div data-theme={isDark ? 'dark' : 'light'}>
          <Header />
          <div className="student__property">
            <section className="student__section__one">
              <div className="student__section__one__segment1">
                <h1>
                  <span style={{ color: '#fdbb30' }}>Student? </span>We have you{' '}
                  <span style={{ textDecoration: 'underline' }}>covered</span>
                </h1>
                <h3>Get up to 60% discount on properties and rooms as a student</h3>
                <span>
                  <button
                    onClick={() => {
                      auth.user
                        ? navigate('/become-a-student')
                        : navigate('/login', { state: { from: location }, replace: true });
                    }}
                  >
                    Register
                  </button>
                  <button
                    onClick={() => {
                      navigate('/student-listing');
                    }}
                  >
                    View listing
                  </button>
                </span>
              </div>
              <div className="student__section__one__segment2">
                <img alt="" src={lsimg1} className="student__section__one__segment2__img" />
              </div>
            </section>
            <section className="student__section__two">
              <h1>Setup your student profile easily and get started</h1>
              <article>
                <div>
                  <img alt="" src={student_img1} className="student__section__two__img" />
                  <h3>One-to-one guidance from a Superhost</h3>
                  <p>
                    We’ll match you with a Superhost in your area, who’ll guide you from your first
                    question to your first guest—by phone, video call, or chat
                  </p>
                </div>
                <div>
                  <img alt="" src={student_img2} className="student__section__two__img" />

                  <h3>An experienced guest for your first booking</h3>
                  <p>
                    For your first booking, you can choose to welcome an experienced guest who has
                    at least three stays and a good track record on GPS.
                  </p>
                </div>
                <div>
                  <img alt="" src={student_img3} className="student__section__two__img" />

                  <h3>Specialized support from GPS</h3>
                  <p>
                    New Hosts get one-tap access to specially trained Community Support agents who
                    can help with everything from account issues to billing support.
                  </p>
                </div>
              </article>
            </section>
          </div>
          <AccountFooter />
        </div>
      )}
    </>
  );
}

export default StudentProperty;
