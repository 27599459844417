import React from 'react';
import './Cardlist.css';
import CardAgency from '../card/CardAgency';

const CardlistAgency = ({ result2 }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {result2.map((item, i) => {
        return (
          <CardAgency
            key={i}
            userId={result2[i].userId}
            index={result2[i].id}
            image={result2[i].profile_image}
            documentImage={result2[i].document_image}
            name={result2[i].agency_name}
            mobile={result2[i].mobile_phone}
            position={result2[i].position}
            office={result2[i].office_phone}
            email={result2[i].business_email}
            address={result2[i].office_address}
          />
        );
      })}
    </div>
  );
};

export default CardlistAgency;
