import './PersonalInfo.css';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useContext, useState } from 'react';
import AuthContext from '../../../context/AuthProvider';
import PhoneInput from 'react-phone-input-2';
import { axiosPrivate } from '../../../api/axios';
import Toaster from '../../../utils/toaster/Toaster';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiFillFileImage } from 'react-icons/ai';
import { Image, Placeholder, Transformation } from 'cloudinary-react';
import { ScaleLoader } from 'react-spinners';

function PersonalInfo() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');

  const [phone, setPhone] = useState('');
  const [state, setState] = useState(null);
  const [address, setAddress] = useState(null);

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('No file selected');

  const { auth, setAuth, isDark } = useContext(AuthContext);

  const [edit, setEdit] = useState({
    name: false,
    gender: false,
    phone: false,
    address: false,
    image: false,
  });

  const submit = async (type) => {
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post(
        `api/v1/user/account-update-user`,
        JSON.stringify({
          userId: auth.user.id,
          type: type,
          firstName: firstName,
          lastName: lastName,
          address: address,
          phone: phone,
          state: state,
          country: country,
          image: image,
          gender: gender,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      setEdit((prevState) => ({
        ...prevState,
        name: false,
        gender: false,
        phone: false,
        address: false,
        image: false,
      }));
      const res = response.data;
      setAuth((prev) => ({ ...prev, user: res.data }));
      setIsLoading(false);
      setToaster(true);
      setbgcolor('#00e396');
      setMessage(res.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="account__personalinfo">
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

          <section className="personal__section__one">
            <h3>
              account <RiArrowRightSLine className="personal__icon__one" /> personal Info
            </h3>
            <h1>Personal info</h1>
          </section>
          <section className="personal__section__two">
            <span>
              <h3>Legal name</h3>{' '}
              {!edit.name ? (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      name: true,
                    }));
                  }}
                >
                  edit
                </h3>
              ) : (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      name: false,
                    }));
                  }}
                >
                  cancel
                </h3>
              )}
            </span>
            {!edit.name ? (
              <p>
                <span>{auth.user && auth.user.full_name}</span>
              </p>
            ) : (
              <div>
                <p>
                  This is the name on your travel document, which could be a license or a passport
                </p>
                <div>
                  <span>
                    <input
                      placeholder="First name"
                      type="text"
                      style={{ width: '49%' }}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                    <input
                      placeholder="Last name"
                      type="text"
                      style={{ width: '49%' }}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </span>
                  <button
                    onClick={(e) => {
                      e.preventDefault(); // Prevents the default button click behavior
                      setEdit((prevState) => ({
                        ...prevState,
                        email: false,
                        name: false,
                        phone: false,
                        address: false,
                      }));
                      submit('name');
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </section>
          <section className="personal__section__two">
            <span>
              <h3>Gender</h3>{' '}
              {!edit.gender ? (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      gender: true,
                    }));
                  }}
                >
                  edit
                </h3>
              ) : (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      gender: false,
                    }));
                  }}
                >
                  cancel
                </h3>
              )}
            </span>
            {!edit.gender ? (
              <p>
                <span>{auth.user && auth.user.gender}</span>
              </p>
            ) : (
              <div>
                <p>Select your gender</p>
                <div>
                  <span>
                    <div className="edit__user__article__div">
                      <label>Gender</label>
                      <select
                        name="gender"
                        id="gender"
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value={null}>--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </span>
                  <button
                    onClick={() => {
                      setEdit((prevState) => ({
                        ...prevState,
                        email: false,
                        name: false,
                        phone: false,
                        address: false,
                      }));
                      submit('gender');
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </section>
          <section className="personal__section__two">
            <span>
              <h3>Phone number</h3>{' '}
              {!edit.phone ? (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      phone: true,
                    }));
                  }}
                >
                  edit
                </h3>
              ) : (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      phone: false,
                    }));
                  }}
                >
                  cancel
                </h3>
              )}
            </span>

            {!edit.phone ? (
              <p>
                <span>{auth.user && auth.user.phone}</span>
              </p>
            ) : (
              <div>
                <p>For notifications, reminders, and help logging in</p>
                <div>
                  <span>
                    <PhoneInput
                      country={'ng'}
                      onChange={(value) => setPhone(value)}
                      inputStyle={{
                        width: '100%',
                        margin: '0',
                      }}
                      containerStyle={{
                        marginBottom: '20px',
                      }}
                    />
                  </span>
                  <button
                    onClick={() => {
                      setEdit((prevState) => ({
                        ...prevState,
                        email: false,
                        name: false,
                        phone: false,
                        address: false,
                      }));
                      submit('phone');
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </section>
          <section className="personal__section__two">
            <span>
              <h3>Address</h3>{' '}
              {!edit.address ? (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      address: true,
                    }));
                  }}
                >
                  edit
                </h3>
              ) : (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      address: false,
                    }));
                  }}
                >
                  cancel
                </h3>
              )}
            </span>

            {!edit.address ? (
              <p>
                <span>{auth.user && auth.user.address}</span>
              </p>
            ) : (
              <div>
                <p>Use a permanent address where you can receive mail</p>
                <div>
                  <span>
                    <div className="edit__user__article__div">
                      <label>Country</label>
                      <CountryDropdown
                        whitelist={['NG', 'SL']}
                        value={country}
                        onChange={(val) => setCountry(val)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>State/Region</label>
                      <RegionDropdown
                        country={country}
                        value={state}
                        blankOptionLabel="State"
                        onChange={(val) => setState(val)}
                      />
                    </div>

                    <div className="edit__user__article__div">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="e.g Lekki, Rosario"
                        alt=""
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </span>
                  <button
                    onClick={() => {
                      setEdit((prevState) => ({
                        ...prevState,
                        email: false,
                        name: false,
                        phone: false,
                        address: false,
                      }));
                      submit('address');
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </section>
          <section className="personal__section__two">
            <span>
              <h3>Profile image</h3>{' '}
              {!edit.image ? (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      image: true,
                    }));
                  }}
                >
                  edit
                </h3>
              ) : (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      image: false,
                    }));
                  }}
                >
                  cancel
                </h3>
              )}
            </span>

            {!edit.image ? (
              <p>
                <span>
                  {auth.user && auth.user.profile_image ? (
                    <Image
                      quality="auto:best"
                      loading="lazy"
                      cloudName="du1dvxjo8"
                      publicId={auth.user.profile_image}
                      width="300"
                      crop="scale"
                    />
                  ) : (
                    <></>
                  )}
                </span>
              </p>
            ) : (
              <div>
                <p>Select picture to use as profile image</p>
                <div>
                  <span>
                    <form
                      className="image__upload"
                      onClick={() => {
                        document.querySelector('.input__field').click();
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="input__field"
                        hidden
                        onChange={({ target: { files } }) => {
                          files[0] && setFileName(files[0].name);
                          if (files) {
                            const reader = new FileReader();
                            reader.readAsDataURL(files[0]);
                            reader.onloadend = () => {
                              setImage(reader.result);
                            };
                          }
                        }}
                      />

                      {image ? (
                        <img src={image} width={75} height={75} alt={fileName} />
                      ) : (
                        <MdCloudUpload color="#1475cf" size={50} />
                      )}
                    </form>
                    <div className="image__upload__label">
                      <AiFillFileImage color="#1475" />
                      <span>
                        {fileName}
                        <MdDelete
                          onClick={() => {
                            setFileName('No file selected');
                            setImage(null);
                          }}
                        />
                      </span>
                    </div>
                  </span>
                  <button
                    onClick={() => {
                      setEdit((prevState) => ({
                        ...prevState,
                        email: false,
                        name: false,
                        phone: false,
                        address: false,
                      }));
                      submit('image');
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </section>
        </div>
      )}
    </>
  );
}

export default PersonalInfo;
