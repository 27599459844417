import React from 'react';
import './Cardlist.css';
import CardAgents from '../card/CardAgent.js';

const CardlistAgents = ({ result, avg }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {result.map((item, i) => {
        return (
          <CardAgents
            key={i}
            userId={result[i].userId}
            index={result[i].id}
            image={result[i].profile_image}
            name={result[i].name}
            mobile={result[i].mobile_phone}
            position={result[i].position}
            office={result[i].office_address}
            email={result[i].business_email}
          />
        );
      })}
    </div>
  );
};

export default CardlistAgents;
