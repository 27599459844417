import { MdCloudUpload, MdDelete, MdKeyboardArrowLeft } from 'react-icons/md';
import { useContext, useState, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import { ScaleLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { AiFillFileImage } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { BsAsterisk } from 'react-icons/bs';

import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import './Become.an.agent.css';
import one from '../../../assets/call-center-agent_3462467.png';
import two from '../../../assets/global_13069726.png';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import Toaster from '../../../utils/toaster/Toaster';

function BecomeAnAgent() {
  //Hooks declaration
  const navigate = useNavigate();

  //Context and state declarations
  const { auth, isDark } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
    select3: false,
  });
  const [step, setStep] = useState({
    select1: true,
    select2: false,
    select3: false,
  });
  const [category, setCategory] = useState(null);
  const [caution, setCaution] = useState(false);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('No file selected');
  const [description, setDescription] = useState(null);
  const [position, setPosition] = useState(null);
  const [agencyId, seTagencyId] = useState(null);
  const [workEmail, setWorkEmail] = useState(null);
  const [linkedin, setLinkedin] = useState(null);
  const [address, setAddress] = useState(null);
  const [gender, setGender] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [officePhone, setOfficePhone] = useState(null);
  const [agencyName, setAgencyName] = useState(null);
  const [website, setWebsite] = useState(null);
  const [licenseNumber, setLicenseNumber] = useState(null);
  const [fax, setFax] = useState(null);
  const [corperateValue, setCorperateValue] = useState(null);
  const [history, setHistory] = useState(null);
  const [documentImage, setDocumentImage] = useState(null);
  const [documentFileName, setDocumentFileName] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);

  /**
   * Country region selector object
   * @type {Object}
   */
  const regionsByCountry = {
    Nigeria: [
      'Abia',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara',
      'Federal Capital Territory',
    ],
    SierraLeone: [
      'Bo',
      'Bombali',
      'Bonthe',
      'Falaba',
      'Kailahun',
      'Kambia',
      'Karene',
      'Kenema',
      'Koinadugu',
      'Kono',
      'Moyamba',
      'Port Loko',
      'Pujehun',
      'Tonkolili',
      'Western Area Rural',
      'Western Area Urban',
    ],
  };

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };

  const next = () => {
    if (!category) {
      setCaution(true);
      return;
    }

    setCaution(false);
    setSelect((prevState) => ({
      ...prevState,
      select1: false,
      select2: true,
      select3: false,
    }));
    setStep((prevState) => ({
      ...prevState,
      select1: true,
      select2: true,
      select3: false,
    }));
  };

  const next2 = () => {
    setSelect((prevState) => ({
      ...prevState,
      select1: false,
      select2: false,
      select3: true,
    }));
    setStep((prevState) => ({
      ...prevState,
      select1: true,
      select2: true,
      select3: true,
    }));
  };

  const formRef = useRef(null);
  const formRef2 = useRef(null);

  const handleNext = (e) => {
    e.preventDefault();
    if (formRef.current && formRef.current.checkValidity()) {
      next2(); // Call next function if all fields are valid
    } else {
      // If form is not valid, focus on the first invalid input field
      const invalidField = formRef.current.querySelector(':invalid');
      if (invalidField) {
        invalidField.focus();
      }
    }
  };

  const handleNext2 = (e) => {
    e.preventDefault();
    if (formRef2.current && formRef2.current.checkValidity()) {
      next2(); // Call next function if all fields are valid
    } else {
      // If form is not valid, focus on the first invalid input field
      const invalidField = formRef2.current.querySelector(':invalid');
      if (invalidField) {
        invalidField.focus();
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (category == 'agent') {
      try {
        const response = await axiosPrivate.post(
          'api/v1/applications/agent',
          JSON.stringify({
            image: image,
            description: description,
            position: position,
            agencyId: agencyId,
            workEmail: workEmail,
            gender: gender,
            linkedin: linkedin,
            address: address,
            mobilePhone: mobilePhone,
            officePhone: officePhone,
            userId: auth.user.id,
            lastName: auth.user.last_name,
            firstName: auth.user.first_name,
            status: 'pending',
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );
        setIsLoading(false);
        navigate('/become-an-agent-submitted');
      } catch (err) {
        if (!err?.response) {
          console.log('No server response');
        } else if (err.response.status === 401) {
          console.log('Unauthorized');
        }
      }
    } else if (category == 'agency') {
      try {
        const response = await axiosPrivate.post(
          'api/v1/applications/agency',
          JSON.stringify({
            image: image,
            documentImage: documentImage,
            description: description,
            position: position,
            agencyId: agencyId,
            workEmail: workEmail,
            gender: gender,
            linkedin: linkedin,
            address: address,
            mobilePhone: mobilePhone,
            officePhone: officePhone,
            userId: auth.user.id,
            status: 'pending',
            agencyName: agencyName,
            website: website,
            fax: fax,
            history: history,
            corperateValue: corperateValue,
            licenseNumber: licenseNumber,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );
        setIsLoading(false);
        navigate('/become-an-agent-submitted');
      } catch (err) {
        if (!err?.response) {
          setIsLoading(false);
          setToaster(true);
          setbgcolor('#d1ecf1');
          setMessage(err.response.data.message);
          setTimeout(() => {
            setToaster(false);
          }, 3100);
        } else if (err.response.status === 401) {
          setIsLoading(false);
          setToaster(true);
          setbgcolor('#d1ecf1');
          setMessage(err.response.data.message);
          setTimeout(() => {
            setToaster(false);
          }, 3100);
        }
      }
    } else {
      return;
    }
  };

  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div data-theme={isDark ? 'dark' : 'light'}>
          <Helmet>
            <title>Get started - GPS</title>
          </Helmet>
          <Header />
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

          <div className="becomeanagent">
            <section className="becomestudent__section__one">
              <h2>Get started in three easy steps</h2>
              <div>
                <span
                  className={
                    step.select1
                      ? 'becomestudent__section__one__div__span active'
                      : 'becomestudent__section__one__div__span'
                  }
                >
                  1
                </span>
                <span
                  className={
                    step.select2
                      ? 'becomestudent__section__one__div__span active'
                      : 'becomestudent__section__one__div__span'
                  }
                >
                  2
                </span>
                <span
                  className={
                    step.select3
                      ? 'becomestudent__section__one__div__span active'
                      : 'becomestudent__section__one__div__span'
                  }
                >
                  3
                </span>
              </div>
            </section>

            <section className="becomeanagent__section__two">
              {select.select1 ? (
                <div className="becomeanagent__section__two__segment1">
                  <h1>Select category</h1>
                  <h3>Do you want to register as an agent or as an agency?</h3>
                  <div className="becomeanagent__section__two__segment1__select">
                    <div
                      onClick={() => {
                        setCategory('agent');
                      }}
                      className={
                        category == 'agent'
                          ? 'becomeanagent__section__two__segment1__option selected'
                          : 'becomeanagent__section__two__segment1__option'
                      }
                    >
                      <img
                        alt=""
                        src={one}
                        className="becomeanagent__section__two__segment1__option__icon"
                      />
                      <h3>Agent</h3>
                    </div>
                    <div
                      onClick={() => {
                        setCategory('agency');
                      }}
                      className={
                        category == 'agency'
                          ? 'becomeanagent__section__two__segment1__option selected'
                          : 'becomeanagent__section__two__segment1__option'
                      }
                    >
                      <img
                        alt=""
                        src={two}
                        className="becomeanagent__section__two__segment1__option__icon"
                      />

                      <h3>Agency</h3>
                    </div>
                  </div>
                  <button onClick={next}>next</button>
                  {caution ? (
                    <p className="becomeanagent__caution">You have not selected any category</p>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {select.select2 && category == 'agent' ? (
                <form className="becomeanagent__section__two__segment2" ref={formRef}>
                  <h1>General information</h1>
                  <h3>Fields with asterick are important</h3>
                  <article>
                    <div className="edit__user__article__div">
                      <label>
                        Position{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input type="text" required onChange={(e) => setPosition(e.target.value)} />
                    </div>
                    <div className="edit__user__article__div">
                      <label>Affiliated agency Id (If any)</label>
                      <input type="text" onChange={(e) => seTagencyId(e.target.value)} />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Work email{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input type="email" required onChange={(e) => setWorkEmail(e.target.value)} />
                    </div>
                    <div className="edit__user__article__div">
                      <label>linkedin social media link</label>
                      <input
                        type="text"
                        placeholder="e.g California, Instanul, Lagos"
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </div>

                    <div className="edit__user__article__div">
                      <label>
                        Office address{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input
                        type="text"
                        required
                        placeholder="e.g Lekki, Rosario"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Gender <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <select
                        name="gender"
                        id="gender"
                        required
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value={null}>--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>

                    <div className="edit__user__article__div">
                      <label>Mobile phone</label>
                      <PhoneInput
                        country={'ng'}
                        onChange={(e) => setMobilePhone(e)}
                        inputStyle={{
                          width: '100%',
                          margin: '0',
                        }}
                        containerStyle={{
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Office phone{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <PhoneInput
                        country={'ng'}
                        required
                        onChange={(e) => setOfficePhone(e)}
                        inputStyle={{
                          width: '100%',
                          margin: '0',
                        }}
                        containerStyle={{
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                  </article>
                  <button onClick={handleNext}>next</button>
                </form>
              ) : (
                <></>
              )}
              {select.select2 && category == 'agency' ? (
                <form className="becomeanagent__section__two__segment2" ref={formRef2}>
                  <h1>Tell us about your agency</h1>
                  <h3>Fields with asterick are important</h3>
                  <article>
                    <div className="edit__user__article__div">
                      <label>
                        Agency name{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input type="text" required onChange={(e) => setAgencyName(e.target.value)} />
                    </div>
                    <div className="edit__user__article__div">
                      <label>Website link</label>
                      <input type="text" onChange={(e) => setWebsite(e.target.value)} />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Work email{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input type="email" required onChange={(e) => setWorkEmail(e.target.value)} />
                    </div>
                    <div className="edit__user__article__div">
                      <label>linkedin social media link</label>
                      <input
                        type="text"
                        placeholder="e.g California, Instanul, Lagos"
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </div>

                    <div className="edit__user__article__div">
                      <label>
                        Office address{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input
                        type="text"
                        required
                        placeholder="e.g Lekki, Rosario"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Country <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <select
                        required
                        name="country"
                        id="country"
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setState(null); //Reset state when the country changes
                        }}
                        value={country}
                        className="list__input"
                      >
                        <option value={null}>Country</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="SierraLeone">Sierra Leone</option>
                      </select>
                    </div>
                    <div className="edit__user__article__div">
                      <label>State/Region</label>
                      <select
                        required
                        name="state"
                        id="state"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                        disabled={!country} // Disable state dropdown if no country is selected
                        className="list__input"
                      >
                        <option value={null}>Region/State</option>
                        {country &&
                          regionsByCountry[country].map((region) => (
                            <option key={region} value={region}>
                              {region}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        License number
                        <BsAsterisk
                          className="edit__user__article__div__icon"
                          onChange={(e) => setLicenseNumber(e.target.value)}
                        ></BsAsterisk>
                      </label>
                      <input type="text" required />
                    </div>
                    <div className="edit__user__article__div">
                      <label>fax</label>
                      <input type="text" />
                    </div>
                    <div className="edit__user__article__div">
                      <label>Mobile phone</label>
                      <PhoneInput
                        country={'ng'}
                        onChange={(e) => setMobilePhone(e)}
                        inputStyle={{
                          width: '100%',
                          margin: '0',
                        }}
                        containerStyle={{
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Office phone{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <PhoneInput
                        country={'ng'}
                        required
                        onChange={(e) => setOfficePhone(e)}
                        inputStyle={{
                          width: '100%',
                          margin: '0',
                        }}
                        containerStyle={{
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Corperate value{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter new message"
                        name="text"
                        rows="8"
                        cols="6"
                        wrap="soft"
                        onChange={(e) => setCorperateValue(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="edit__user__article__div">
                      <label>Brief history</label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter new message"
                        name="text"
                        rows="8"
                        cols="6"
                        wrap="soft"
                        onChange={(e) => setHistory(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Profile image{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <form
                        className="image__upload"
                        onClick={() => document.querySelector('.input__field').click()}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="input__field"
                          required
                          hidden
                          onChange={({ target: { files } }) => {
                            files[0] && setFileName(files[0].name);
                            if (files) {
                              const reader = new FileReader();
                              reader.readAsDataURL(files[0]);
                              reader.onloadend = () => {
                                setImage(reader.result);
                              };
                            }
                          }}
                        />

                        {image ? (
                          <img src={image} width={75} height={75} alt={fileName} />
                        ) : (
                          <MdCloudUpload color="#1475cf" size={50} />
                        )}
                      </form>
                      <div className="image__upload__label">
                        <AiFillFileImage color="#1475" />
                        <span>
                          {fileName}
                          <MdDelete
                            onClick={() => {
                              setFileName('No file selected');
                              setImage(null);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </article>
                  <button onClick={handleNext2}>next</button>
                </form>
              ) : (
                <></>
              )}
              {select.select3 && category == 'agent' ? (
                <div className="becomeanagent__section__two__segment3">
                  <h1>Final details</h1>
                  <article>
                    <div className="edit__user__article__div">
                      <label>
                        Tell us more about you{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <textarea
                        type="text"
                        placeholder="Enter new message"
                        name="text"
                        rows="8"
                        cols="6"
                        wrap="soft"
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Profile image{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <form
                        className="image__upload"
                        onClick={() => document.querySelector('.input__field').click()}
                      >
                        <input
                          type="file"
                          className="input__field"
                          hidden
                          onChange={({ target: { files } }) => {
                            files[0] && setFileName(files[0].name);
                            if (files) {
                              const reader = new FileReader();
                              reader.readAsDataURL(files[0]);
                              reader.onloadend = () => {
                                setImage(reader.result);
                              };
                            }
                          }}
                        />

                        {image ? (
                          <img src={image} width={75} height={75} alt={fileName} />
                        ) : (
                          <MdCloudUpload color="#1475cf" size={50} />
                        )}
                      </form>
                      <div className="image__upload__label">
                        <AiFillFileImage color="#1475" />
                        <span>
                          {fileName}
                          <MdDelete
                            onClick={() => {
                              setFileName('No file selected');
                              setImage(null);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </article>
                  <button onClick={submit}>Submit</button>
                </div>
              ) : (
                <></>
              )}

              {select.select3 && category == 'agency' ? (
                <div className="becomeanagent__section__two__segment3">
                  <h1>One more step!</h1>
                  <article>
                    <div className="edit__user__article__div">
                      <label>
                        Tell us more about your agency{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <textarea
                        type="text"
                        placeholder="Enter new message"
                        name="text"
                        rows="8"
                        cols="6"
                        wrap="soft"
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Registration document{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <form
                        className="image__upload"
                        onClick={() => document.querySelector('.input__field').click()}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="input__field"
                          hidden
                          onChange={({ target: { files } }) => {
                            files[0] && setDocumentFileName(files[0].name);
                            if (files) {
                              const reader = new FileReader();
                              reader.readAsDataURL(files[0]);
                              reader.onloadend = () => {
                                setDocumentImage(reader.result);
                              };
                            }
                          }}
                        />

                        {documentImage ? (
                          <img src={documentImage} width={75} height={75} alt={documentFileName} />
                        ) : (
                          <MdCloudUpload color="#1475cf" size={50} />
                        )}
                      </form>
                      <div className="image__upload__label">
                        <AiFillFileImage color="#1475" />
                        <span>
                          {fileName}
                          <MdDelete
                            onClick={() => {
                              setDocumentFileName('No file selected');
                              setDocumentImage(null);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </article>
                  <button onClick={submit}>Submit</button>
                </div>
              ) : (
                <></>
              )}
            </section>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default BecomeAnAgent;
