import { RiArrowRightSLine } from 'react-icons/ri';
import './Account.privacy.css';
import { IoMdWarning } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import Switch from 'react-switch';
import { ScaleLoader } from 'react-spinners';
import AuthContext from '../../../context/AuthProvider';

function AccountPrivacy() {
  const { isDark } = useContext(AuthContext);
  const [checked, setChecked] = useState(true);
  const [checked2, setChecked2] = useState(true);

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="account__privacy">
          <section className="personal__section__one">
            <h3>
              account <RiArrowRightSLine className="personal__icon__one" /> privacy and sharing
            </h3>
            <h1>Privacy and sharing</h1>
          </section>
          <article className="account__privacy__section__two">
            <section className="account__privacy__section__two__part1">
              <div className="account__notifications__select__div">
                <button
                  className={
                    select.select1
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: true,
                      select2: false,
                    }))
                  }
                >
                  Data
                </button>
                <button
                  className={
                    select.select2
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: false,
                      select2: true,
                      select3: false,
                    }))
                  }
                >
                  Sharing
                </button>
              </div>
              <div>
                {select.select1 ? (
                  <div className="account__privacy__section__two__part2__data">
                    <h1>Manage your account data</h1>
                    <div>
                      <h3>Request your personal data</h3>
                      <p>We’ll create a file for you to download your personal data.</p>
                      <h4>Download account data</h4>
                    </div>
                    <div>
                      <h3>Delete your account data</h3>
                      <p>
                        This will permanently delete your account and your data, in accordance with
                        applicable law.
                      </p>
                      <h4 style={{ color: '#da483c' }}>Delete account data</h4>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {select.select2 ? (
                  <div className="account__privacy__section__two__part2__data">
                    <h1>Activity sharing</h1>
                    <div>
                      <h3>Include my listing(s) in search engines</h3>
                      <p>
                        Turning this on means search engines, like Google, will display your listing
                        page(s) in search results.
                      </p>
                      <Switch
                        onChange={() => {
                          setChecked(!checked);
                        }}
                        checked={checked}
                        className="react-switch"
                      />
                    </div>
                    <div>
                      <h3>Read receipt</h3>
                      <p>When this is on, we’ll show people that you’ve read their messages</p>
                      <Switch
                        onChange={() => {
                          setChecked2(!checked2);
                        }}
                        checked={checked2}
                        className="react-switch"
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </section>
            <section className="account__privacy__section__two__part2">
              <div className="account__notifications__section__three__newsletter__subscribe privacy">
                <div>
                  <h3 style={{ fontWeight: '600' }}>Commited to privacy</h3>
                  <p>
                    Get properties solution is committed to keeping your data protected. See details
                    in our{` `}
                    <span
                      onClick={() => navigate('/privacy-policy')}
                      style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                      Privacy Policy
                    </span>
                    .
                  </p>
                </div>
              </div>
            </section>
          </article>
        </div>
      )}
    </>
  );
}

export default AccountPrivacy;
