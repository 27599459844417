import React, { useContext } from 'react';
import './Modal.css';
import { AiOutlineClose } from 'react-icons/ai';
import AuthContext from '../../../context/AuthProvider';
import { IoIosCloseCircleOutline } from 'react-icons/io';

const Modal = ({ isOpen, onClose, children }) => {
  const { isDark, setIsDark } = useContext(AuthContext);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal" data-theme={isDark ? 'dark' : 'light'}>
      <div className="modal__content">
        <div className="modal__close" onClick={onClose}>
          {/* <AiOutlineClose /> */}
          <IoIosCloseCircleOutline className="modal__close__icon" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
