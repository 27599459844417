import React, { useState } from 'react';
import './Account.footer.css';
import { useNavigate } from 'react-router-dom';

const AccountFooter = () => {
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="account__footer">
      <section className="account__footer__section__one">
        <div className="account__footer__section__one__main">
          <article className="account__footer__section__one__main__foot">
            <div>
              <h1>Product</h1>
              <p
                onClick={() => {
                  navigate('/list-property');
                }}
              >
                List property
              </p>
              <p
                onClick={() => {
                  navigate('/terms-and-conditions');
                }}
              >
                Terms and Conditions
              </p>
            </div>
            <div>
              <h1>Contact</h1>
              <p
                onClick={() => {
                  navigate('/list-property');
                }}
              >
                FAQ
              </p>
              <p
                onClick={() => {
                  navigate('/contact-us');
                }}
              >
                Get in touch
              </p>
            </div>
          </article>
        </div>
      </section>
      <section className="account__footer__section__two">
        <p>&copy; Copyright Get Properties Solution 2024</p>
      </section>
    </div>
  );
};

export default AccountFooter;
