import { useContext, useState, useRef } from 'react';
import './Becomeahost.css';
import Header from '../../components/layout/header/Header';
import Footer from '../../components/layout/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { BsAsterisk } from 'react-icons/bs';
import PhoneInput from 'react-phone-input-2';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiFillFileImage } from 'react-icons/ai';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import { ScaleLoader } from 'react-spinners';
import Toaster from '../../../utils/toaster/Toaster';
import CurrencyInput from 'react-currency-input-field';
import { RiParkingLine } from 'react-icons/ri';

function Becomeahost() {
  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const [image, setImage] = useState([]);
  const [fileName, setFileName] = useState('No file selected');
  const [title, setTitle] = useState(null);
  const [price, setPrice] = useState(null);
  const [buildingSize, setBuildingSize] = useState(null);
  const [landSize, setLandSize] = useState(null);
  const [parkingSize, setParkingSize] = useState(null);
  const [garageSize, setGarageSize] = useState(null);
  const [bedrooms, setBedrooms] = useState(null);
  const [bathrooms, setBathrooms] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [category, setCategory] = useState(null);
  const [propertyType, setPropertyType] = useState(null);
  const [description, setDescription] = useState(null);
  const [detailedDescription, setDetailedDescription] = useState(null);
  const [highlightOne, setHighlightOne] = useState(null);
  const [highlightTwo, setHighlightTwo] = useState(null);
  const [highlightThree, setHighlightThree] = useState(null);
  const [additionalFees, setAdditionalFees] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [duration, setDuration] = useState(null);
  const [address, setAddress] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [yearBuilt, setYearBuilt] = useState(null);
  const [parking, setParking] = useState(null);
  const [heatingType, setHeatingType] = useState(null);
  const [coolingType, setCoolingType] = useState(null);
  const [basement, setBasement] = useState(null);
  const [flooringType, setFlooringType] = useState(null);
  const [ownershipType, setOwnershipType] = useState(null);
  const [occupancy, setOccupancy] = useState(null);
  const [exteriorFeatures, setExteriorFeatures] = useState(null);
  /**
   * Country region selector object
   * @type {Object}
   */
  const regionsByCountry = {
    Nigeria: [
      'Abia',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara',
      'Federal Capital Territory',
    ],
    SierraLeone: [
      'Bo',
      'Bombali',
      'Bonthe',
      'Falaba',
      'Kailahun',
      'Kambia',
      'Karene',
      'Kenema',
      'Koinadugu',
      'Kono',
      'Moyamba',
      'Port Loko',
      'Pujehun',
      'Tonkolili',
      'Western Area Rural',
      'Western Area Urban',
    ],
  };

  const navigate = useNavigate();
  const { auth, isDark } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
    select3: false,
  });

  const [step, setStep] = useState({
    select1: true,
    select2: false,
    select3: false,
  });

  const next = () => {
    setSelect((prevState) => ({
      ...prevState,
      select1: false,
      select2: true,
      select3: false,
    }));

    setStep((prevState) => ({
      ...prevState,
      select1: true,
      select2: true,
      select2: false,
    }));
  };
  const next2 = () => {
    setSelect((prevState) => ({
      ...prevState,
      select1: false,
      select2: false,
      select3: true,
    }));

    setStep((prevState) => ({
      ...prevState,
      select1: true,
      select2: true,
      select3: true,
    }));
  };

  const formRef = useRef(null);
  const formRef2 = useRef(null);

  const handleNext = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      next(); // Call next function if all fields are valid
    } else {
      // If form is not valid, focus on the first invalid input field
      const invalidField = formRef.current.querySelector(':invalid');
      if (invalidField) {
        invalidField.focus();
      }
    }
  };

  const handleNext2 = () => {
    if (formRef2.current && formRef2.current.checkValidity()) {
      next2(); // Call next function if all fields are valid
    } else {
      // If form is not valid, focus on the first invalid input field
      const invalidField = formRef2.current.querySelector(':invalid');
      if (invalidField) {
        invalidField.focus();
      }
    }
  };

  const [amenities, setAmenities] = useState([]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setAmenities([...amenities, name]);
    } else {
      setAmenities(amenities.filter((item) => item !== name));
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post(
        'api/v1/applications/property',
        JSON.stringify({
          userId: auth.user.id,
          status: 'pending',
          title: title,
          price: price,
          buildingSize: buildingSize,
          landSize: landSize,
          parkingSize: parkingSize,
          garageSize: garageSize,
          bedrooms: bedrooms,
          bathrooms: bathrooms,
          mobilePhone: mobilePhone,
          category: category,
          propertyType: propertyType,
          description: description,
          detailedDescription: detailedDescription,
          highlightOne: highlightOne,
          highlightTwo: highlightTwo,
          highlightThree: highlightThree,
          additionalFees: additionalFees,
          address: address,
          country: country,
          state: state,
          city: city,
          images: image,
          amenities: amenities,
          currency: currency,
          duration: duration,
          flooringType: flooringType,
          exteriorFeatures: exteriorFeatures,
          ownershipType: ownershipType,
          basement: basement,
          coolingType: coolingType,
          heatingType: heatingType,
          parking: parking,
          occupancy: occupancy,
          yearBuilt: yearBuilt,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      setIsLoading(false);
      navigate('/list-property/become-a-host/success');
    } catch (err) {
      if (!err?.response) {
        setIsLoading(false);
        setToaster(true);
        setbgcolor('#d1ecf1');
        setMessage(err.response.data.message);
        setTimeout(() => {
          setToaster(false);
        }, 3100);
      } else if (err.response.status === 401) {
        setIsLoading(false);
        setToaster(true);
        setbgcolor('#d1ecf1');
        setMessage(err.response.data.message);
        setTimeout(() => {
          setToaster(false);
        }, 3100);
      } else {
        setIsLoading(false);
        setToaster(true);
        setbgcolor('#d1ecf1');
        setMessage(err.response.data.message);
        setTimeout(() => {
          setToaster(false);
        }, 3100);
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div data-theme={isDark ? 'dark' : 'light'}>
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

          <Header />
          <div className="becomeahost">
            <div className="becomeahost__hero">
              <h1>Apply to list your exceptional home on Get properties Solution</h1>
              <p>
                I simply adore the team at GPS. Top-notch professionals who are a joy to work with.
                The writing and photography for our home is amazing. I know for sure that the guests
                and properties are well vetted.
              </p>
              {/* <img src={listimg} alt="" /> */}
              <section className="becomestudent__section__one">
                {/* <h2></h2> */}
                <div>
                  <span
                    className={
                      step.select1
                        ? 'becomestudent__section__one__div__span active'
                        : 'becomestudent__section__one__div__span'
                    }
                  >
                    1
                  </span>
                  <span
                    className={
                      step.select2
                        ? 'becomestudent__section__one__div__span active'
                        : 'becomestudent__section__one__div__span'
                    }
                  >
                    2
                  </span>
                  <span
                    className={
                      step.select3
                        ? 'becomestudent__section__one__div__span active'
                        : 'becomestudent__section__one__div__span'
                    }
                  >
                    3
                  </span>
                </div>
              </section>
            </div>
            <div className="becomeahost__form">
              {select.select1 ? (
                <form ref={formRef}>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Title <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <input
                        className="list__input"
                        type="text"
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Price <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <CurrencyInput
                        required
                        id="input-example"
                        name="input-name"
                        placeholder=""
                        decimalsLimit={2}
                        onValueChange={(value, name, values) => setPrice(value)}
                      />
                    </div>
                  </span>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Currency{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="currency"
                        id="currency"
                        onChange={(e) => setCurrency(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="NGN">Naira (NGN)</option>
                        <option value="SLL">Sierra Leonean Leone (SLL)</option>
                      </select>
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Duration{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="propertyType"
                        id="propertyType"
                        onChange={(e) => setDuration(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="per day">Per day</option>
                        <option value="per week">Per week</option>
                        <option value="per month">Per month</option>
                        <option value="per year">Per year</option>
                        <option value="outright">Outright</option>
                      </select>
                    </div>
                  </span>

                  <h3 className="listing__h3" for="email">
                    Description (The more detailed the better)
                    <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                  </h3>
                  <textarea
                    type="text"
                    placeholder="Enter new message"
                    name="text"
                    rows="8"
                    cols="6"
                    wrap="soft"
                    required
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  <h3 className="listing__h3">
                    Address <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                  </h3>
                  <input
                    className="listing__input"
                    type="text"
                    required
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                  <h3 className="listing__h3">
                    Country <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                  </h3>

                  <select
                    required
                    name="country"
                    id="country"
                    onChange={(e) => {
                      setCountry(e.target.value);
                      setState(null); //Reset state when the country changes
                    }}
                    value={country}
                    className="list__input"
                  >
                    <option value={null}>Country</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="SierraLeone">Sierra Leone</option>
                  </select>

                  <h3 className="listing__h3">
                    Region/State{' '}
                    <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                  </h3>

                  <select
                    required
                    name="state"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    disabled={!country} // Disable state dropdown if no country is selected
                    className="list__input"
                  >
                    <option value={null}>Region/State</option>
                    {country &&
                      regionsByCountry[country].map((region) => (
                        <option key={region} value={region}>
                          {region}
                        </option>
                      ))}
                  </select>

                  <h3 className="listing__h3">City</h3>
                  <input
                    className="listing__input"
                    type="text"
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />

                  <button onClick={handleNext}>Continue</button>
                </form>
              ) : (
                <></>
              )}

              {select.select2 ? (
                <form ref={formRef2}>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Category{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="category"
                        id="category"
                        onChange={(e) => setCategory(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="rental">Rental</option>
                        <option value="lease">Lease</option>
                        <option value="sale">Sale</option>
                        <option value="shared">Shared</option>
                        <option value="hostel">Hostel</option>
                      </select>
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Property type{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="propertyType"
                        id="propertyType"
                        onChange={(e) => setPropertyType(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="residential">Residential</option>
                        <option value="commercial">Commercial</option>
                        <option value="student">Student</option>
                        <option value="industrial">Industrial</option>
                        <option value="land">Land</option>
                      </select>
                    </div>
                  </span>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Parking <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="parking"
                        id="parking"
                        onChange={(e) => setParking(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Street Parking">Street Parking</option>
                        <option value="Driving">Driving</option>
                        <option value="Attached Garage">Attached Garage</option>
                        <option value="Carport">Carport</option>
                        <option value="No Parking">No Parking</option>
                      </select>
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Heating Type{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="heatingType"
                        id="heatingType"
                        onChange={(e) => setHeatingType(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Central">Central</option>
                        <option value="forced Air">Forced Air</option>
                        <option value="Radiant">Radiant</option>
                        <option value="Baseboard">Baseboard</option>
                        <option value="Heat Pump">Heat Pump</option>
                        <option value="None">None</option>
                      </select>
                    </div>
                  </span>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Cooling Type{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="CoolingType"
                        id="CoolingType"
                        onChange={(e) => setCoolingType(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Central Air">Central Air</option>
                        <option value="Window Units">Window Units</option>
                        <option value="Evaporator Cooler">Evaporator Cooler</option>
                        <option value="None">None</option>
                      </select>
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Basement{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="basement"
                        id="basement"
                        onChange={(e) => setBasement(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Unfinished">Unfinished</option>
                        <option value="Partially finished">Partially finished</option>
                        <option value="Finished">Finished</option>
                        <option value="None">None</option>
                      </select>
                    </div>
                  </span>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Exterior Features
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="ExteriorFeatures"
                        id="ExteriorFeatures"
                        onChange={(e) => setExteriorFeatures(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Balcony">Balcony</option>
                        <option value="Deck">Deck</option>
                        <option value="Patio">Patio</option>
                        <option value="Porch">Porch</option>
                        <option value="Yard">Yard</option>
                        <option value="Fenced Yard">Fenced Yard</option>
                        <option value="Pool">Pool</option>
                        <option value="None">None</option>
                      </select>
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Flooring Type
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="FlooringType"
                        id="FlooringType"
                        onChange={(e) => setFlooringType(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Carpet">Carpet</option>
                        <option value="Hardwood">Hardwood</option>
                        <option value="Tile">Tile</option>
                        <option value="Laminate">Laminate</option>
                        <option value="Vinyl">Vinyl</option>
                        <option value="Mixed">Mixed</option>
                      </select>
                    </div>
                  </span>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Occupancy{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="Occupancy"
                        id="Occupancy"
                        onChange={(e) => setOccupancy(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Owner-Occupied">Owner-Occupied</option>
                        <option value="Tenant-Occupied">Tenant-Occupied</option>
                        <option value="Vacant">Vacant</option>
                      </select>
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Ownership Type
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="OwnwershipType"
                        id="OwnwershipType"
                        onChange={(e) => setOwnershipType(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Owned">Owned</option>
                        <option value="Managed">Managed</option>
                      </select>
                    </div>
                  </span>
                  <button onClick={handleNext2}>Continue</button>
                </form>
              ) : (
                <></>
              )}

              {select.select3 ? (
                <form onSubmit={submit}>
                  <span>
                    <div>
                      <h3 className="listing__h3">
                        Bedrooms{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="bedroooms"
                        id="bedroooms"
                        onChange={(e) => setBedrooms(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5+">5+</option>
                      </select>
                    </div>
                    <div>
                      <h3 className="listing__h3">
                        Bathrooms{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </h3>
                      <select
                        required
                        name="bathrooms"
                        id="bathrooms"
                        onChange={(e) => setBathrooms(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="1">1</option>
                        <option value="1.5">1.5</option>
                        <option value="2">2</option>
                        <option value="2.5">2.5</option>
                        <option value="3">3</option>
                        <option value="2.5">2.5</option>
                        <option value="3">3</option>
                        <option value="3.5">3.5</option>
                        <option value="4+">4+</option>
                      </select>
                    </div>
                  </span>
                  <span>
                    <div>
                      <h3 className="listing__h3">Land size( Square feet (sqft))</h3>
                      <input
                        className="list__input"
                        type="number"
                        onChange={(e) => {
                          setLandSize(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <h3 className="listing__h3">Property Size ( Square feet (sqft))</h3>
                      <select
                        name="buildingSize"
                        id="buildingSize"
                        onChange={(e) => setBuildingSize(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="Less than 500 sq ft">Less than 500 sq ft</option>
                        <option value="500 - 900 sq ft">500 - 900 sq ft</option>
                        <option value="1000 - 1499 sq ft">1000 - 1499 sq ft</option>
                        <option value="1500 - 1999 sq ft">1500 - 1999 sq ft</option>
                        <option value="2000 - 2499 sq ft">2000 - 2499 sq ft</option>
                        <option value="2500 - 2999 sq ft">2500 - 2999 sq ft</option>
                        <option value="3000+ sq ft">3000+ sq ft</option>
                      </select>
                    </div>
                  </span>
                  <span>
                    <div>
                      <h3 className="listing__h3">Garage size( Square feet (sqft))</h3>
                      <input
                        className="list__input"
                        type="number"
                        onChange={(e) => {
                          setGarageSize(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <h3 className="listing__h3">Year Built</h3>
                      <select
                        name="yearBuilt"
                        id="yearBuilt"
                        onChange={(e) => setYearBuilt(e.target.value)}
                        className="list__input"
                      >
                        <option value={null}>--</option>
                        <option value="before 1950">Before 1950</option>
                        <option value="1950 - 1969">1950 - 1969</option>
                        <option value="1970 - 1979">1970 - 1979</option>
                        <option value="1970 - 1989">1970 - 1989</option>
                        <option value="1990 - 2009">1990 - 1999</option>
                        <option value="2010 - 2019">2010 - 2019</option>
                        <option value="2020 or Newer">2020 or Newer</option>
                      </select>
                    </div>
                    {/* <div>
                      <h3 className="listing__h3">Parking size( Square feet (sqft))</h3>
                      <input
                        className="list__input"
                        type="number"
                        onChange={(e) => {
                          setParkingSize(e.target.value);
                        }}
                      />
                    </div> */}
                  </span>
                  <h3 className="listing__h3">Nearby Amenities</h3>
                  <div className="amenities-container">
                    <div className="amenity-item">
                      <label className="amenity-label">
                        <input
                          type="checkbox"
                          name="Schools"
                          checked={amenities.includes('Schools')}
                          onChange={handleCheckboxChange}
                          id="amenity-checkbox"
                        />
                        Schools
                      </label>
                    </div>
                    <div className="amenity-item">
                      <label className="amenity-label">
                        <input
                          type="checkbox"
                          name="Parks"
                          checked={amenities.includes('Parks')}
                          onChange={handleCheckboxChange}
                          id="amenity-checkbox"
                        />
                        Parks
                      </label>
                    </div>
                    <div className="amenity-item">
                      <label className="amenity-label">
                        <input
                          type="checkbox"
                          name="Public Transit"
                          checked={amenities.includes('Public Transit')}
                          onChange={handleCheckboxChange}
                          id="amenity-checkbox"
                        />
                        Public Transit
                      </label>
                    </div>
                    <div className="amenity-item">
                      <label className="amenity-label">
                        <input
                          type="checkbox"
                          name="Shopping centers"
                          checked={amenities.includes('Shopping centers')}
                          onChange={handleCheckboxChange}
                          id="amenity-checkbox"
                        />
                        Shopping centers
                      </label>
                    </div>
                    <div className="amenity-item">
                      <label className="amenity-label">
                        <input
                          type="checkbox"
                          name="Restaurants"
                          checked={amenities.includes('Restaurants')}
                          onChange={handleCheckboxChange}
                          id="amenity-checkbox"
                        />
                        Restaurants
                      </label>
                    </div>
                    <div className="amenity-item">
                      <label className="amenity-label">
                        <input
                          type="checkbox"
                          name="Hospitals"
                          checked={amenities.includes('Hospitals')}
                          onChange={handleCheckboxChange}
                          id="amenity-checkbox"
                        />
                        Hospitals
                      </label>
                    </div>
                  </div>

                  <h3 className="listing__h3">Active phone number</h3>
                  <PhoneInput
                    country={'ng'}
                    onChange={(e) => setMobilePhone(e)}
                    inputStyle={{
                      width: '100%',
                      margin: '0',
                    }}
                    containerStyle={{
                      marginBottom: '20px',
                    }}
                  />

                  <h3 className="listing__h3">
                    Property images (Select at least five images){' '}
                    <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                  </h3>
                  <form
                    className="image__upload"
                    onClick={() => document.querySelector('.input__field').click()}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="input__field"
                      multiple
                      hidden
                      onChange={(e) => {
                        const files = e.target.files;
                        let imageList = [];

                        for (let i = 0; i < files.length; i++) {
                          const reader = new FileReader();
                          reader.readAsDataURL(files[i]);
                          reader.onloadend = () => {
                            imageList.push(reader.result);
                            if (i === files.length - 1) {
                              setImage(imageList);
                              setFileName(`${files.length} files selected`);
                            }
                          };
                        }
                      }}
                    />
                    {image.length > 0 ? (
                      image.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          width={75}
                          height={75}
                          alt={`Image ${index + 1}`}
                        />
                      ))
                    ) : (
                      <MdCloudUpload color="#1475cf" size={50} />
                    )}
                  </form>
                  <div className="image__upload__label">
                    <AiFillFileImage color="#1475" />
                    <span>
                      {fileName}
                      <MdDelete
                        onClick={() => {
                          setFileName('No file selected');
                          setImage(null);
                        }}
                      />
                    </span>
                  </div>

                  <button type="submit">Apply now</button>
                  <p>
                    By signing up, you agree to be contacted on the details you have provided. Your
                    personal information will be handled in accordance with our privacy policy.
                  </p>
                </form>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Becomeahost;
