import { RiArrowRightSLine } from 'react-icons/ri';
import './account.login.css';
import { useContext, useState } from 'react';
import AuthContext from '../../../context/AuthProvider';
import Modal from '../../components/modal/Modal';
import { axiosPrivate } from '../../../api/axios';
import Toaster from '../../../utils/toaster/Toaster';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

function AccountLogin() {
  const { auth, setAuth, isDark } = useContext(AuthContext);
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const [edit, setEdit] = useState({
    password: false,
    social: false,
    phone: false,
    address: false,
  });

  // controls the visiility of the delete modal
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const updatePassword = async (type) => {
    if (password !== newPassword) {
      setToaster(true);
      setbgcolor('#00e396');
      setMessage('New password does not match');
      setTimeout(() => {
        setToaster(false);
      }, 3100);

      return;
    }

    try {
      const response = await axiosPrivate.post(
        `api/v1/auth/update-password`,
        JSON.stringify({
          email: auth.user.email,
          currentPassword: currentPassword,
          newPassword: newPassword,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.data;
      setEdit((prevState) => ({
        ...prevState,
        password: false,
      }));
      setToaster(true);
      setbgcolor('#00e396');
      setMessage('Password updated succesfully');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    } catch (error) {
      setToaster(true);
      setbgcolor('#00e396');
      setMessage(error.response.data.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    }
  };

  const deleteAccount = async () => {
    try {
      const response = await axiosPrivate.post(
        `api/v1/user/account-delete-user`,
        JSON.stringify({
          test: 'this is a string',
          userId: auth.user.id,
          password: password,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.data;
      setAuth({});
      navigate('/');
    } catch (error) {
      if (error.response) {
        setToaster(true);
        setbgcolor('#00e396');
        setMessage(error.response.data.message);
        setTimeout(() => {
          setToaster(false);
        }, 3100);
      } else {
        console.log(error);
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="account__login">
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

          <section className="personal__section__one">
            <h3>
              account <RiArrowRightSLine className="personal__icon__one" /> login and security
            </h3>
            <h1>Login and security</h1>
          </section>
          <section className="account__login__section__two">
            <h1>Login</h1>
            <span>
              <h4>Password</h4>
              {!edit.password ? (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      password: true,
                    }));
                  }}
                >
                  update
                </h3>
              ) : (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      password: false,
                    }));
                  }}
                >
                  cancel
                </h3>
              )}
            </span>
            {!edit.password ? (
              <p>
                <span>*********</span>
              </p>
            ) : (
              <div>
                <div>
                  <span>
                    <label htmlFor="current" type="text">
                      Current password
                    </label>
                    <input
                      type="password"
                      style={{ width: '100%' }}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <label htmlFor="new" type="text">
                      New password
                    </label>
                    <input
                      type="password"
                      style={{ width: '100%' }}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="confirmNew" type="text">
                      Confirm password
                    </label>
                    <input
                      type="password"
                      style={{ width: '100%' }}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </span>
                  <button
                    onClick={() => {
                      setEdit((prevState) => ({
                        ...prevState,
                        email: false,
                        name: false,
                        phone: false,
                        address: false,
                      }));
                      updatePassword();
                    }}
                  >
                    update password
                  </button>
                </div>
              </div>
            )}
          </section>

          <section className="account__login__section__two">
            <h1>Account</h1>
            <span>
              <h4>Deactivate account</h4>
              {!edit.account ? (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      account: true,
                    }));
                  }}
                  style={{ color: 'crimson' }}
                >
                  deactivate
                </h3>
              ) : (
                <h3
                  onClick={() => {
                    setEdit((prevState) => ({
                      ...prevState,
                      account: false,
                    }));
                  }}
                  style={{ color: 'black' }}
                >
                  cancel
                </h3>
              )}
            </span>
            {!edit.account ? (
              <p></p>
            ) : (
              <div>
                <div>
                  <span style={{ lineHeight: '25px' }}>
                    Deactivating your account will delete it permanently and all your user records
                    will be lost. Do you want to continue deactivation?
                  </span>
                  <button
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    style={{ background: 'crimson' }}
                  >
                    delete account
                  </button>
                </div>
              </div>
            )}
          </section>

          {/* POP UP MODAL FOR CONFIRM PASSWORD BEFORE DEACTIVATE */}
          <Modal isOpen={isOpen} onClose={closeModal}>
            <div
              className="login"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <p className="login_text" style={{ fontWeight: '500', fontSize: '16px' }}>
                Enter your password to delete account{' '}
              </p>
              <input
                className="login_input"
                type="password"
                name="password"
                placeholder="Password"
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                style={{ width: '100%' }}
              />
              <button
                className="login_button"
                onClick={() => {
                  closeModal();
                  deleteAccount();
                }}
                style={{ background: 'crimson', color: 'white', marginTop: '10px' }}
              >
                Delete account
              </button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default AccountLogin;
