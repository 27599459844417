import './Payment.css';
import { Helmet } from 'react-helmet';
import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import { GiPadlock } from 'react-icons/gi';
import { SlCalender } from 'react-icons/sl';
import { GrUserManager } from 'react-icons/gr';
import { usePaystackPayment } from 'react-paystack';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/AuthProvider';
import { axiosPrivate } from '../../../api/axios';
import { Image, Placeholder, Transformation } from 'cloudinary-react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import CurrencyConverter from '../../../utils/CurrencyConverter';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/modal/Modal';
import { getSingleProperty } from '../../../services/propertyServices';

function Payment() {
  const navigate = useNavigate();
  const { propertyId } = useContext(AuthContext);
  const [result, setResult] = useState({});
  const [images, setImages] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  const { currency, auth, isDark } = useContext(AuthContext);
  const { title, bedrooms, bathrooms, address, state, country, id, price, duration } = result;

  const convertedPrice = CurrencyConverter(price);

  // Remove non-numeric characters (like commas)
  const numericString = convertedPrice.replace(/[^0-9.-]+/g, '');

  // Parse string to integer
  const integerNumber = parseInt(numericString);

  const config = {
    public_key: 'FLWPUBK_TEST-7487e944dd821b4256f55df8754836d6-X',
    tx_ref: Date.now(),
    amount: 10,
    // amount: integerNumber,

    currency: currency,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: auth.user.email,
      phone_number: auth.user.phone,
      name: auth.user.first_name,
    },
    customizations: {
      title: 'Payment',
      description: `Payment for ${title} property`,
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const createTransactionRecord = async () => {
    try {
      const response = await axiosPrivate.post(
        'api/v1/transaction/create',
        JSON.stringify({
          amount: integerNumber,
          currency: currency,
          subject: 'Property payment',
          description: `Payment for ${title}`,
          senderName: `${auth.user.first_name} ${auth.user.last_name}`,
          senderEmail: auth.user.email,
          status: 'successful',
          propertyId: propertyId,
          userId: auth.user.id,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.data;
      setIsOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // const getSingleProperty = async () => {
    //   try {
    //     const response = await axiosPrivate.post(`api/v1/property/property/${propertyId}`, {
    //       headers: { 'Content-Type': 'application/json' },
    //       withCredentials: true,
    //     });
    //     const res = response.data.data;
    //     setResult(res);
    //     setImages(res.images);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const fetchData = async () => {
      try {
        const res = getSingleProperty(propertyId);
        setResult(res);
        setImages(res.images);
        console.log('here', res);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return;
  }, []);

  return (
    <div>
      <Helmet>
        <title>{`Review details - ${title}`}</title>
      </Helmet>
      <Header />
      <div className="payment">
        <section className="payment__section__one">
          <div className="payment__section__one__start">
            <Image
              quality="auto:best"
              // loading="lazy"
              cloudName="du1dvxjo8"
              publicId={images[0]}
              width="300"
              className="payment__section__one__start__img"
              // crop="scale"
            >
              {/* <Placeholder type="blur"></Placeholder> */}
            </Image>
            <div className="payment__section__one__start1">
              <h1>Review your payment details</h1>
              <h3>{title}</h3>
              <p>
                {bedrooms} bedrooms, {bathrooms} bathrooms
              </p>
              <p>{address}</p>
              <p>
                {state}, {country}
              </p>
            </div>
            {/* <div className="payment__section__one__start2">
              <SlCalender className="payment__section__one__start2__icon" />
              <p>22 Mar → 23 Apr, 2024</p>
            </div> */}
            {/* <div className="payment__section__one__start2">
              <GrUserManager className="payment__section__one__start2__icon" />
              <p>1 guest</p>
            </div> */}
            <div className="payment__section__one__start3">
              <h3>Payment policy</h3>
              <p>
                Sint consequat aute mollit nulla deserunt nostrud. Sunt officia ea pariatur et
                consequat aute velit cillum eu. Lorem ipsum pariatur adipisicing sunt veniam tempor.
                Nulla irure amet quis ullamco cillum excepteur ex sint consequat dolore. Nisi
                aliquip eiusmod exercitation qui quis.
              </p>
            </div>
            {/* <div className="payment__section__one__start4">
              <h1>Add travel information</h1>
              <h3>Tell your host about your trip*</h3>
              <p>Please share a few details about your trip to help prepare for your check-in</p>
              <input type="text" className="payment__section__one__start4__input" />
              <h3>Your contact details*</h3>
              <p>
                GPS will only use this number to communicate information about your booking. Standard message and data
                rates apply
              </p>
              <input className="payment__section__one__start4__input__num" type="number" />
            </div> */}
            <div className="payment__section__one__start5">
              <h1>Your payment method</h1>
              <h3>This transaction is protected with a secure and encrypted payment system </h3>
              <button
                onClick={() => {
                  handleFlutterPayment({
                    callback: (response) => {
                      console.log(response);
                      createTransactionRecord();
                      closePaymentModal(); // this will close the modal programmatically
                    },
                    onClose: () => {},
                  });
                }}
              >
                Pay securely now
              </button>
              <p>
                By clicking “Pay securely now” you agree to the Privacy Policy, Terms of Service,
                Cancellation Policy and Host’s House Rules.
              </p>
            </div>
          </div>
          <div className="payment__section__one__end">
            <div className="payment__section__one__end1">
              <p>SECURE CHEKOUT</p>
              <GiPadlock className="payment__section__one__end1__icon" />
            </div>
            <div className="payment__section__one__end2">
              <h1>Payment summary</h1>
              <div
                className="payment__section__one__end2__calc
                            "
              >
                {`${currency} ${convertedPrice} ${duration}`}
              </div>
              {/* <div
                className="payment__section__one__end2__calc
                            "
              >
                £380.25 x 32 nights
                <h3>£12,167.99</h3>
              </div> */}
              {/* <p>Service & cleaning fees are included</p> */}
              <div className="payment__section__one__end2__book">
                Ammount
                <h3> {`${currency} ${convertedPrice}`}</h3>
              </div>
            </div>
            <div className="payment__section__one__end3">
              <h3>Moving details</h3>
              <p>Get Properties Solution will contact you within 24 hours to 3 days post payment</p>
            </div>
          </div>
          <div></div>
        </section>
      </div>
      <Footer />

      {/* Modal for displaying succesful payment */}

      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="payment__modal">
          <h1 className="payment__modal__header">Succesful payment</h1>
          <p className="payment__modal__content">
            Get Properties Solution will contact you within the next 72 hours for moving details
          </p>
          <button className="payment__modal__cta" onClick={() => navigate('/')}>
            Go back home
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Payment;
