import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import 'react-calendar/dist/Calendar.css';
import { ScaleLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import useGeoLocation from 'react-ipgeolocation';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Image, Placeholder, Transformation } from 'cloudinary-react';

import './Home.css';
import Header from '../../components/layout/header/Header';
import homepageimage from '../../../assets/homepageimage.png';
import homepageimage2 from '../../../assets/homepageimage2.png';
import Footer from '../../components/layout/footer/Footer';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate.js';
import AuthContext from '../../../context/AuthProvider';
import CurrencyConverter from '../../../utils/CurrencyConverter.js';

function Home() {
  //Context and state declarations
  const [displayResult1, setDisplayResult1] = useState([]);
  const [displayResult2, setDisplayResult2] = useState([]);
  const [displayResult3, setDisplayResult3] = useState([]);
  const [propertyResult, setPropertyResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setAuth, setPropertyId, currency, setCurrency, manualCurrencySet, setRate, isDark } =
    useContext(AuthContext);
  /**
   * State variable for filter criteria.
   * @type {Object}
   */
  const [filter, setFilter] = useState({
    keyWord: null,
    bedrooms: null,
    bathrooms: null,
    minPrice: null,
    maxPrice: null,
    country: null,
    state: null,
    city: null,
    category: null,
    title: null,
    propertyType: null,
    yearBuilt: null,
    landSize: null,
    buildingSize: null,
    parkingSize: null,
    garageSize: null,
    cordinates: null,
    views: null,
    tags: null,
  });

  //Hooks invocation
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  /**
   * Initial query string for API requests.
   * @type {string}
   */
  let queryString = `page=1&size=10&`;

  /**
   * Generates a query string based on the filter state.
   */
  Object.keys(filter).forEach((key, index) => {
    if (filter[key]) {
      queryString = queryString + `${key}=${filter[key]}&`;
    }
  });

  /**
   * React-slideshow-image library configurations for hero section background fade.
   * @type {Object}
   */
  const divStyle = {
    backgroundSize: 'cover',
    // minHeight: '47.5vh',
  };

  /**
   * Images for the slideshow.
   * @type {Array}
   */
  const slideImages = [
    {
      url: homepageimage2,
      caption: 'Slide 1',
    },
    {
      url: homepageimage,
      caption: 'Slide 2',
    },
  ];

  /**
   * Button style for the React-slideshow-image.
   * @type {Object}
   */
  const buttonStyle = {
    display: 'none',
  };

  /**
   * Properties for the React-slideshow-image component.
   * @type {Object}
   */
  const properties = {
    prevArrow: <button style={{ ...buttonStyle }}></button>,
    nextArrow: <button style={{ ...buttonStyle }}></button>,
  };

  /**
   * Fetches display properties from the API.
   */
  useEffect(() => {
    const getDisplayProperties = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/property/display`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setDisplayResult1(res.properties1);
        setDisplayResult2(res.properties2);
        setDisplayResult3(res.properties3);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getTrendingProperties = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/property/trending`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setPropertyResult(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getDisplayProperties();
    getTrendingProperties();
  }, []);

  /**
   * CSS style for a div element.
   * @type {Object}
   */
  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    // background: isDark ? '#121212' : '#000',
    backgroundColor: 'white',
  };

  /**
   * Hook to get the user's geographical location.
   * @type {Object}
   */
  const location = useGeoLocation();
  if (location.country === 'NG' && manualCurrencySet === false) {
    setCurrency('NGN');
  } else if (location.country === 'SL' && manualCurrencySet === false) {
    setCurrency('SLL');
  }

  /**
   * Fetches conversion rate from the API.
   */
  useEffect(() => {
    const getConversionRate = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/utils/get-rate`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setRate(res.rate);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getConversionRate();
  }, []);

  /**
   * Country region selector object
   * @type {Object}
   */
  const regionsByCountry = {
    Nigeria: [
      'Abia',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara',
      'Federal Capital Territory',
    ],
    SierraLeone: [
      'Bo',
      'Bombali',
      'Bonthe',
      'Falaba',
      'Kailahun',
      'Kambia',
      'Karene',
      'Kenema',
      'Koinadugu',
      'Kono',
      'Moyamba',
      'Port Loko',
      'Pujehun',
      'Tonkolili',
      'Western Area Rural',
      'Western Area Urban',
    ],
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFilter((prevState) => ({
      ...prevState,
      country: selectedCountry,
      state: null, // Reset state when country changes
    }));
  };

  const handleStateChange = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      state: e.target.value,
    }));
  };

  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="home">
          <Helmet>
            <title>Get properties Solution</title>
          </Helmet>
          <Header />
          <div className="home__main">
            <section className="slide-container home__main__section__one">
              <div className="home__main__section__one__hero">
                <h1>Find Your Perfect Home: Where Comfort Meets Convenience</h1>
                <div className="home__main__section__one__searchbox">
                  <div className="home__main__section__one__searchbox__add__dates">
                    <select
                      required
                      name="country"
                      id="country"
                      onChange={handleCountryChange}
                      value={filter.country}
                    >
                      <option value={null}>Country</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="SierraLeone">Sierra Leone</option>
                    </select>
                  </div>
                  <div className="home__main__section__one__searchbox__add__dates">
                    <select
                      required
                      name="state"
                      id="state"
                      onChange={handleStateChange}
                      value={filter.state}
                      disabled={!filter.country} // Disable state dropdown if no country is selected
                    >
                      <option value={null}>Region/State</option>
                      {filter.country &&
                        regionsByCountry[filter.country].map((region) => (
                          <option key={region} value={region}>
                            {region}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="home__main__section__one__searchbox__add__dates">
                    <select
                      name="category"
                      id="category"
                      onChange={(e) =>
                        setFilter((prevState) => ({
                          ...prevState,
                          category: e.target.value,
                        }))
                      }
                    >
                      <option value={null}>Category</option>
                      <option value="rental">Rental</option>
                      <option value="lease">Lease</option>
                      <option value="sale">Sale</option>
                      <option value="shared">Shared</option>
                      <option value="hostel">Hostel</option>
                    </select>
                  </div>
                  <button
                    className="home__main__section__one__searchbox__search"
                    onClick={() => {
                      setAuth((prev) => ({ ...prev, queryString: queryString }));
                      navigate('/properties');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              {/* </div>
                  </div>
                ))}
              </Fade> */}
              <div></div>
            </section>
            <div className="home__main__section__two__bg">
              <section className="home__main__section__two">
                <span>
                  <h1>Popular properties</h1>
                  <h2 onClick={() => navigate('/properties')}>
                    More <MdKeyboardArrowRight />
                  </h2>
                </span>
                <div className="home__main__section__two__poppty">
                  {propertyResult.map(function (item, i) {
                    // Check if the index is less than six
                    if (i < 6) {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            setPropertyId(propertyResult[i].id);
                            navigate(`/property/${propertyResult[i].id}`);
                          }}
                        >
                          <Image
                            quality="auto:best"
                            // loading="lazy"
                            cloudName="du1dvxjo8"
                            publicId={propertyResult[i].images[0]}
                            width="300"
                            // crop="scale"
                            className="home__main__section__two__poppty__img"
                          >
                            {/* <Placeholder type="blur"></Placeholder> */}
                          </Image>
                          <h1>{propertyResult[i].title}</h1>
                          {/* <h3>{propertyResult[i].address}</h3> */}

                          <h3>
                            {`${currency === 'NGN' ? '₦' : 'Le'}  ${CurrencyConverter(
                              propertyResult[i].price,
                            )}  ${propertyResult[i].duration}`}
                          </h3>
                        </div>
                      );
                    }
                    // If index is greater than or equal to six, return null
                    return null;
                  })}
                </div>
              </section>
            </div>

            <section className="home__main__section__four">
              <div className="home__main__section__four__seg1">
                <h1>Peace of mind guaranteed with every boooking</h1>
                <p>We promise expert-vetted homes, total transparency and exceptional service.</p>
              </div>
              <div>
                <div>
                  <div className="home__main__section__four__seg2">
                    {displayResult3.map(function (item, i) {
                      // Check if the index is less than six
                      if (i < 6) {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              setPropertyId(displayResult3[i].id);
                              navigate(`/property/${displayResult3[i].id}`);
                            }}
                          >
                            <Image
                              quality="auto:best"
                              // loading="lazy"
                              cloudName="du1dvxjo8"
                              publicId={displayResult3[i].images[0]}
                              width="300"
                              // crop="scale"
                              className="home__main__section__four__img"
                            >
                              {/* <Placeholder type="blur"></Placeholder> */}
                            </Image>
                            <h1>{displayResult3[i].title}</h1>
                            <h3>{displayResult3[i].address}</h3>
                          </div>
                        );
                      }
                      // If index is greater than or equal to six, return null
                      return null;
                    })}
                  </div>
                </div>
              </div>
            </section>
            <section className="home__main__section__seven">
              <span>
                <h1>Book your dream stay today</h1>

                <h2 onClick={() => navigate('/properties')}>
                  More <MdKeyboardArrowRight />
                </h2>
              </span>
              <div>
                <div className="home__main__section__seven__seg2">
                  {displayResult1.map(function (item, i) {
                    // Check if the index is less than six
                    if (i < 6) {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            setPropertyId(displayResult1[i].id);
                            navigate(`/property/${displayResult1[i].id}`);
                          }}
                        >
                          <Image
                            quality="auto:best"
                            // loading="lazy"
                            cloudName="du1dvxjo8"
                            publicId={displayResult1[i].images[0]}
                            width="300"
                            // crop="scale"
                            className="home__main__section__seven__img"
                          >
                            {/* <Placeholder type="blur"></Placeholder> */}
                          </Image>
                          <h1>{displayResult1[i].title}</h1>
                          <h3>{displayResult1[i].address}</h3>
                        </div>
                      );
                    }
                    // If index is greater than or equal to six, return null
                    return null;
                  })}
                </div>
              </div>
              <div className="home__main__section__seven__seg2 home__responsive__none">
                {displayResult2.map(function (item, i) {
                  // Check if the index is less than six
                  if (i < 6) {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          setPropertyId(displayResult2[i].id);
                          navigate(`/property/${displayResult2[i].id}`);
                        }}
                      >
                        <Image
                          quality="auto:best"
                          // loading="lazy"
                          cloudName="du1dvxjo8"
                          publicId={displayResult2[i].images[0]}
                          width="300"
                          // crop="scale"
                          className="home__main__section__seven__img"
                        >
                          {/* <Placeholder type="blur"></Placeholder> */}
                        </Image>
                        <h1>{displayResult2[i].title}</h1>
                        <h3>{displayResult2[i].address}</h3>
                      </div>
                    );
                  }
                  // If index is greater than or equal to six, return null
                  return null;
                })}
              </div>
            </section>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Home;
