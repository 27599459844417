import { useContext, useState } from 'react';
import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import './Become.an.agent.success.css';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import AuthContext from '../../../context/AuthProvider';

function BecomeAnAgentSuccess() {
  const navigate = useNavigate();
  const { isDark } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div>
          <Header />
          <div className="studentlistingcomplete">
            <h1>Your application was sent</h1>
            <p>We'll review your application and we will get back to you soonest.</p>
            <h3>
              go back to{' '}
              <span
                onClick={() => {
                  navigate('/');
                }}
              >
                home
              </span>
            </h3>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default BecomeAnAgentSuccess;
