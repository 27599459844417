import './Properties.css';
import Header from '../../components/layout/header/Header';
import Footer from '../../components/layout/footer/Footer';
import { FaHotel } from 'react-icons/fa';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IoBedSharp, IoFilterOutline } from 'react-icons/io5';
import Modal from '../../components/modal/Modal';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { PiLockKeyOpenLight } from 'react-icons/pi';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineBathtub } from 'react-icons/md';
import { BiSolidCategory } from 'react-icons/bi';
import Card from '../../components/card/Card';
import { ScaleLoader } from 'react-spinners';

function Properties() {
  const { auth, setAuth, isDark } = useContext(AuthContext);
  const [propertyResult, setPropertyResult] = useState([]);
  const [totalResult, setTotalResult] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState({
    keyWord: null,
    bedrooms: null,
    bathrooms: null,
    minPrice: null,
    maxPrice: null,
    country: null,
    state: null,
    city: null,
    category: null,
    title: null,
    propertyType: null,
    yearBuilt: null,
    landSize: null,
    buildingSize: null,
    parkingSize: null,
    garageSize: null,
    cordinates: null,
    views: null,
    tags: null,
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
  };

  const observer = useRef();

  let queryString = `page=${pageNumber}&size=10&`;

  //maps through the filter state and requrn a query string for filtered selection
  Object.keys(filter).forEach(function (key, index) {
    if (filter[key]) {
      queryString = queryString + `${key}=${filter[key]}&`;
    }
  });

  // Function to reset filter state and queryString
  const resetFilterAndQueryString = () => {
    setFilter({
      keyWord: null,
      bedrooms: null,
      bathrooms: null,
      minPrice: null,
      maxPrice: null,
      country: null,
      state: null,
      city: null,
      category: null,
      title: null,
      propertyType: null,
      yearBuilt: null,
      landSize: null,
      buildingSize: null,
      parkingSize: null,
      garageSize: null,
      cordinates: null,
      views: null,
      tags: null,
    });
    queryString = `page=1&size=10&`;
  };

  // INTERSECTION OBSERVER THAT INCREASS THE PAGE NUMBER BY ONE EVERY TIME THE LAST ELEMENT OF THE RETURNED PAGINATED PROPERTY RESULT APPEARS ONS CREEN
  const lastProperty = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (loading) {
            setPageNumber((pageNumber) => pageNumber + 1);
            // setAuth((prev) => ({ ...prev, queryString: queryString }));
          } else if (!loading) {
            setPageNumber(1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  // TRIGGERS ON LOAD TO PASSED QUERY STRING FROM OTHER PAGES IN THE APP THAT WAS SET IN THE AUTH STATE
  useEffect(() => {
    setIsLoading(true);

    const getProperties = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/property/search?${auth.queryString}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setPropertyResult(res.properties);
        setTotalResult(res.totalItems);
        setAuth((prev) => ({ ...prev, properties: res.properties }));
        setIsLoading(false);
        setLoading(true);
        setHasMore(pageNumber <= res.totalPages);
        setError(false);
        // setQueryString(auth.queryString);
        // setAuth((prev) => ({ ...prev, queryString: null }));
      } catch (error) {
        console.log(error);
        setPropertyResult([]);
        setIsLoading(false);
      }
    };

    getProperties();

    return;
  }, []);

  // TRIGGERS WHEN THE LAST ELEMENT OF THE RETURNED PROPERTY RESULT IS REACHED; HENCE INFINITE SCROLLING
  useEffect(() => {
    const getProperties = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/property/search?${queryString}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        const newPropertyResult = propertyResult.concat(res.properties);
        setPropertyResult(newPropertyResult);
        setHasMore(pageNumber < res.totalPages);
        closeModal();
        setAuth((prev) => ({ ...prev, properties: res.properties }));
      } catch (error) {
        console.log(error);
        setLoading(false);
        setIsLoading(false);
      }
    };

    if (loading && hasMore) {
      getProperties();
    } else if (!hasMore) {
      setLoading(false);
      setIsLoading(false);
    } else return;
  }, [pageNumber]);

  const search = async () => {
    setIsOpen(false);
    setLoading(true);
    setIsLoading(true);

    try {
      const response = await axiosPrivate.get(`api/v1/property/search?${queryString}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const res = response.data.data;
      setPropertyResult(res.properties);
      setTotalResult(res.totalItems);
      setAuth((prev) => ({ ...prev, properties: res.properties }));
      closeModal();
      setIsLoading(false);
      setLoading(true);
      setHasMore(pageNumber <= res.totalPages);
      setError(false);
      resetFilterAndQueryString(); // Reset filter and queryString
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      setPropertyResult([]);
      resetFilterAndQueryString(); // Reset filter and queryString
    }
  };

  return (
    <div>
      <Header />

      <div className="properties">
        <div className="properties__search__div">
          <div className="properties__search__div__box">
            <span className="properties__search__keywords__section">
              <span>
                <PiLockKeyOpenLight className="properties__search__keywords__section__icon" />{' '}
                <input
                  type="text"
                  placeholder="Enter keyword"
                  onChange={(val) =>
                    setFilter((prevState) => ({
                      ...prevState,
                      keyWord: val,
                    }))
                  }
                />
              </span>
              <button onClick={search}>
                <IoIosSearch className="properties__search__keywords__section__icon" />
              </button>
            </span>
            <div className="properties__search__filter__section">
              <CountryDropdown
                whitelist={['NG', 'SL']}
                value={filter.country}
                onChange={(val) =>
                  setFilter((prevState) => ({
                    ...prevState,
                    country: val,
                  }))
                }
              />
              <RegionDropdown
                country={filter.country}
                value={filter.state}
                blankOptionLabel="State"
                onChange={(val) =>
                  setFilter((prevState) => ({
                    ...prevState,
                    state: val,
                  }))
                }
              />

              <select
                placeholder="Min price"
                type="text"
                onChange={(e) =>
                  setFilter((prevState) => ({
                    ...prevState,
                    minPrice: e.target.value,
                  }))
                }
              >
                <option value={null}>Min Price</option>
                <option value="50000">50,000</option>
                <option value="150000">150,000</option>
                <option value="300000">300,000</option>
                <option value="750000">750,000</option>
                <option value="1000000">1,000,000</option>
                <option value="1500000">1,500,000</option>
              </select>
              <select
                placeholder="Max price"
                type="text"
                onChange={(e) =>
                  setFilter((prevState) => ({ ...prevState, maxPrice: e.target.value }))
                }
              >
                <option value={null}>Min Price</option>
                <option value="500000">500,000</option>
                <option value="750000">750,000</option>
                <option value="1000000">1,000,000</option>
                <option value="1500000">1,500,000</option>
                <option value="2500000">2,500,000</option>
                <option value="4500000">4,500,000</option>
              </select>

              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <IoFilterOutline className="properties__filter__icon" /> More filters
              </button>
            </div>
          </div>
        </div>

        <div className="properties__search__results">
          {isLoading ? (
            <div style={cssDiv}>
              <ScaleLoader color={isDark ? '#fff' : '#000'} />
            </div>
          ) : propertyResult.length === 0 ? (
            <div className="">
              <h3>No results matched your search</h3>
            </div>
          ) : propertyResult.length > 0 ? (
            <div className="">
              {/* Returns list of searched properties */}
              <h3>Properties that matched search filter</h3>
              <p>{totalResult} properties available</p>
              <div className="cardlist">
                {propertyResult.map((item, i) => {
                  if (propertyResult.length === i + 1) {
                    return (
                      <div ref={lastProperty}>
                        <Card
                          key={i}
                          index={propertyResult[i].id}
                          title={propertyResult[i].title}
                          images={propertyResult[i].images}
                          description={propertyResult[i].description}
                          address={propertyResult[i].address}
                          price={propertyResult[i].price}
                          bedrooms={propertyResult[i].bedrooms}
                          bathrooms={propertyResult[i].bathrooms}
                          currency={propertyResult[i].currency}
                          duration={propertyResult[i].duration}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Card
                        key={i}
                        index={propertyResult[i].id}
                        title={propertyResult[i].title}
                        images={propertyResult[i].images}
                        description={propertyResult[i].description}
                        address={propertyResult[i].address}
                        price={propertyResult[i].price}
                        bedrooms={propertyResult[i].bedrooms}
                        bathrooms={propertyResult[i].bathrooms}
                        currency={propertyResult[i].currency}
                        duration={propertyResult[i].duration}
                      />
                    );
                  }
                })}
              </div>
              {loading && <ScaleLoader color={isDark ? '#fff' : '#000'} />}
              {error && <div>'error...'</div>}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* POP UP MODALS FOR MORE FILTER */}
      <Modal isOpen={isOpen} onClose={closeModal}>
        <section className="more__filter__head">
          <h3>More filter</h3>
        </section>
        <section className="more__filter__body">
          <article className="more__filter__body__bath">
            <div>
              <span>
                <IoBedSharp className="more__filter__icon" />
                Bedrooms
              </span>
              <select
                name="bedrooms"
                id="bedrooms"
                onChange={(e) =>
                  setFilter((prevState) => ({
                    ...prevState,
                    bedrooms: e.target.value,
                  }))
                }
              >
                <option value={null}>--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5+">5+</option>
              </select>
            </div>
            <div>
              <span>
                <MdOutlineBathtub className="more__filter__icon" /> Bathrooms
              </span>

              <select
                name="bathrooms"
                id="bathrooms"
                onChange={(e) =>
                  setFilter((prevState) => ({
                    ...prevState,
                    bathrooms: e.target.value,
                  }))
                }
              >
                <option value={null}>--</option>
                <option value="1">1</option>
                <option value="1.5">1.5</option>
                <option value="2">2</option>
                <option value="2.5">2.5</option>
                <option value="3">3</option>
                <option value="2.5">2.5</option>
                <option value="3">3</option>
                <option value="3.5">3.5</option>
                <option value="4+">4+</option>
              </select>
            </div>
          </article>
          <article className="more__filter__body__category">
            <span>
              <BiSolidCategory className="more__filter__icon" />
              Category
            </span>

            <select
              name="category"
              id="category"
              onChange={(e) =>
                setFilter((prevState) => ({
                  ...prevState,
                  category: e.target.value,
                }))
              }
            >
              <option value={null}>--</option>
              <option value="rental">Rental</option>
              <option value="lease">Lease</option>
              <option value="sale">Sale</option>
              <option value="shared">Shared</option>
              <option value="hostel">Hostel</option>
            </select>
          </article>
          <article className="more__filter__body__category">
            <span>
              <FaHotel className="more__filter__icon" /> Property type
            </span>

            <select
              name="property type"
              id="property type"
              onChange={(e) =>
                setFilter((prevState) => ({
                  ...prevState,
                  propertytype: e.target.value,
                }))
              }
            >
              <option value={null}>--</option>
              <option value="residential">Residential</option>
              <option value="commercial">Commercial</option>
              <option value="student">Student</option>
              <option value="industrial">Industrial</option>
              <option value="land">Land</option>
            </select>
          </article>
        </section>
        <section className="more__filter__foot">
          <button onClick={search}>Search</button>
        </section>
      </Modal>

      <Footer />
    </div>
  );
}

export default Properties;
