import { MdKeyboardArrowLeft } from 'react-icons/md';
import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import './Helpcenter.css';
import { useNavigate } from 'react-router-dom';

function Helpcenter() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className="helpcenter">
        <section className="wishlist__section__one">
          <div>
            <MdKeyboardArrowLeft
              className="wishlist__section__one__icon"
              onClick={() => navigate(-1)}
            />
          </div>
          <h1>Helpcenter</h1>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Helpcenter;
