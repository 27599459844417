import React from 'react';
import './Cardlist.css';
import CardReview from '../card/CardReview';

const CardlistReview = ({ reviews }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {reviews.map((item, i) => {
        return (
          <CardReview
            key={i}
            userId={reviews[i].userId}
            index={reviews[i].id}
            img={reviews[i].img}
            rating={reviews[i].rating}
            createdAt={reviews[i].createdAt}
            comment={reviews[i].comment}
            senderName={reviews[i].sender_name}
            profileImage={reviews[i].sender_image}
          />
        );
      })}
    </div>
  );
};

export default CardlistReview;
