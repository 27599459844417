import React, { useContext } from 'react';
import './Dashboard.footer.css';
import AuthContext from '../../../../context/AuthProvider';

const DashboardFooter = () => {
  const { isDark } = useContext(AuthContext);

  return (
    <div className="dashboard__footer" data-theme={isDark ? 'dark' : 'light'}>
      <section className="dashboard__footer__section__one"></section>
      <section className="dashboard__footer__section__two">
        <p>&copy; Copyright Get Properties Solution 2024</p>
        <p>Terms | Privacy | Legal</p>
      </section>
    </div>
  );
};

export default DashboardFooter;
