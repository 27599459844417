import './Become.a.student.css';
import AccountFooter from '../../components/layout/account.footer/Account.footer';
import Header from '../../components/layout/header/Header';
import { RiAsterisk } from 'react-icons/ri';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import { AiFillFileImage } from 'react-icons/ai';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import Toaster from '../../../utils/toaster/Toaster';
import { ScaleLoader } from 'react-spinners';

function BecomeStudent() {
  const { auth, isDark } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const navigate = useNavigate();

  const [universityEmail, setUniversityEmail] = useState('');
  const [university, setUniversity] = useState('');
  const [requestLocation, setRequestLocation] = useState('');
  const [proofType, setProofType] = useState('');

  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('No file selected');

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });
  const [step, setStep] = useState({
    select1: true,
    select2: false,
  });

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };

  const next = () => {
    // Check if geolocation is supported by the browser
    if ('geolocation' in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          // Get the user's latitude and longitude coordinates
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          // Do something with the location data, e.g. display on a map
          setRequestLocation(`Latitude: ${lat}, longitude: ${lng}`);
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error('Error getting user location:', error);
        },
      );
    } else {
      // Geolocation is not supported by the browser
      console.error('Geolocation is not supported by this browser.');
    }
    setSelect((prevState) => ({
      ...prevState,
      select1: false,
      select2: true,
    }));

    setStep((prevState) => ({
      ...prevState,
      select1: true,
      select2: true,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post(
        'api/v1/applications/student',
        JSON.stringify({
          firstName: auth.user.first_name,
          lastName: auth.user.last_name,
          universityEmail: universityEmail,
          university: university,
          userId: auth.user.id,
          email: auth.user.email,
          requestLocation: requestLocation,
          proofType: proofType,
          documents: image,
          status: 'pending',
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );

      setIsLoading(false);
      navigate('/student-listing/submitted');
    } catch (err) {
      if (!err?.response) {
        setIsLoading(false);
        setToaster(true);
        setbgcolor('#d1ecf1');
        setMessage(err.response.data.message);
        setTimeout(() => {
          setToaster(false);
        }, 3100);
      } else if (err.response.status === 401) {
        setIsLoading(false);
        setToaster(true);
        setbgcolor('#d1ecf1');
        setMessage(err.response.data.message);
        setTimeout(() => {
          setToaster(false);
        }, 3100);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div data-theme={isDark ? 'dark' : 'light'}>
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}
          <Header />
          <div className="becomestudent">
            <section className="becomestudent__section__one">
              <h2>Register. Verify. Enjoy benefits.</h2>
              <div>
                <span
                  className={
                    step.select1
                      ? 'becomestudent__section__one__div__span active'
                      : 'becomestudent__section__one__div__span'
                  }
                >
                  1
                </span>
                <span
                  className={
                    step.select2
                      ? 'becomestudent__section__one__div__span active'
                      : 'becomestudent__section__one__div__span'
                  }
                >
                  2
                </span>
              </div>
            </section>

            <section className="becomestudent__section__two">
              {select.select1 ? (
                <div className="becomestudent__section__two__segment1">
                  <h3>
                    What email do you use for your school?{' '}
                    <RiAsterisk className="becomestudent__icons" />
                  </h3>
                  <h4>
                    Note:{' '}
                    <span style={{ color: 'gray', fontWeight: '500' }}>
                      Selecting a school issued email address gives you the est chance of a speedy
                      review
                    </span>
                  </h4>
                  <input
                    type="email"
                    placeholder="email address"
                    required
                    onChange={(e) => {
                      setUniversityEmail(e.target.value);
                    }}
                  />
                  <h3>
                    What is the name of your school? <RiAsterisk className="becomestudent__icons" />
                  </h3>
                  <input
                    type="text"
                    placeholder="Name of university attending"
                    required
                    onChange={(e) => {
                      setUniversity(e.target.value);
                    }}
                  />
                  <p>
                    When you click 'continue' you will e prompted to share you locaation with us,
                    providing your current location helps us verify your affiliation to your current
                    school.
                  </p>
                  <button onClick={next}>Continue</button>
                </div>
              ) : (
                <></>
              )}
              {select.select2 ? (
                <div className="becomestudent__section__two__segment2">
                  <h3>
                    Please upload proof of your academic status{' '}
                    <RiAsterisk className="becomestudent__icons" />
                  </h3>
                  <p>
                    Snap a picture of your qualifying proof of current academic status. We accept
                    JPEGs and PNGs of at least 1024x768 resolution between 100KB and 1MB in size.
                  </p>

                  <form
                    className="image__upload"
                    onClick={() => document.querySelector('.input__field').click()}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="input__field"
                      hidden
                      onChange={({ target: { files } }) => {
                        files[0] && setFileName(files[0].name);
                        if (files) {
                          const reader = new FileReader();
                          reader.readAsDataURL(files[0]);
                          reader.onloadend = () => {
                            setImage(reader.result);
                          };
                        }
                      }}
                    />

                    {image ? (
                      <img src={image} width={75} height={75} alt={fileName} />
                    ) : (
                      <MdCloudUpload color="#1475cf" size={50} />
                    )}
                  </form>
                  <div className="image__upload__label">
                    <AiFillFileImage color="#1475" />
                    <span>
                      {fileName}
                      <MdDelete
                        onClick={() => {
                          setFileName('No file selected');
                          setImage(null);
                        }}
                      />
                    </span>
                  </div>
                  <h3>
                    Proof type <RiAsterisk className="becomestudent__icons" />
                  </h3>
                  <select
                    name="Document type"
                    id="Document type"
                    onChange={(e) => {
                      setProofType(e.target.value);
                    }}
                  >
                    <option value={null}>--Select proof type--</option>
                    <option value="ID">School ID card</option>
                    <option value="Eligibility">Eligiility form</option>
                  </select>
                  <p>
                    Please note, your request cannot be edited once it has been submitted, so please
                    verify your details for accuracy before sending them to us. After clicking
                    "Process my application", please wait as processing can take several seconds.
                  </p>
                  <span>
                    <button onClick={submit}>Process my application</button>
                    <button onClick={() => navigate(-1)}>Cancel</button>
                  </span>
                </div>
              ) : (
                <></>
              )}
            </section>
          </div>
          <AccountFooter />
        </div>
      )}
    </>
  );
}

export default BecomeStudent;
