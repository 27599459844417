import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Image } from 'cloudinary-react';
import { ScaleLoader } from 'react-spinners';
import { GoCheckCircleFill } from 'react-icons/go';
import { GiBed, GiCoolSpices, GiTheater } from 'react-icons/gi';
import { LiaBathSolid } from 'react-icons/lia';
import { TbRulerMeasure } from 'react-icons/tb';

import './Product.css';
import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import AuthContext from '../../../context/AuthProvider';
import { axiosPrivate } from '../../../api/axios';
import { BsFillCalendarDateFill } from 'react-icons/bs';
import CurrencyConverter from '../../../utils/CurrencyConverter';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';

function Product() {
  // Context and state declarations
  const { auth, propertyId, setPropertyId, currency, isDark } = useContext(AuthContext);
  const [result, setResult] = useState({});
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  // Hooks invocations
  const location = useLocation();
  const navigate = useNavigate();

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };

  const {
    title,
    description,
    bedrooms,
    bathrooms,
    id,
    additional_fees,
    detailed_description,
    year_built,
    land_size,
    building_size,
    parking_size,
    garage_size,
    heating_type,
    cooling_type,
    basement,
    exterior_features,
    flooring_type,
    ownership_type,
    occupancy,
    parking,
    address,
    price,
    duration,
  } = result;

  /**
   * Fetches a single property and increases its view count.
   * @param {string} propertyId - The ID of the property to fetch.
   */
  useEffect(() => {
    /**
     * Retrieves a single property from the server.
     * @async
     */
    const getSingleProperty = async () => {
      try {
        const response = await axiosPrivate.post(`api/v1/property/property/${propertyId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setResult(res);
        setImages(res.images);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    /**
     * Increases the view count of the property.
     * @async
     */
    const increaseViewCount = async () => {
      try {
        await axiosPrivate.get(`api/v1/property/increase-view/${propertyId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
      } catch (error) {
        console.error(error);
      }
    };

    setIsLoading(true);
    increaseViewCount();
    getSingleProperty();
  }, [propertyId]);

  /**
   * Moves the image slider to the specified direction
   * @param {String} direction - The direction to move the image slider to
   */
  const handleArrowClick = (direction) => {
    const newStartIndex = direction === 'forward' ? startIndex + 1 : startIndex - 1;
    const maxIndex = window.innerWidth >= 768 ? images.length - 2 : images.length - 1; // Ensure we don't go beyond the last pair of images for desktop view and last image for mobile view

    // Ensure newStartIndex stays within bounds
    setStartIndex(Math.max(0, Math.min(newStartIndex, maxIndex)));
  };

  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Header />
          <div className="product">
            <section className="product__section__one">
              <SlArrowLeft
                className="product__section__one__arrow"
                onClick={() => handleArrowClick('backward')}
              />

              {/* Maps through the images array and display only two items in the array */}
              {images
                .slice(startIndex, startIndex + (window.innerWidth >= 768 ? 2 : 1))
                .map((image, index) => (
                  <Image
                    key={startIndex + index}
                    quality="auto:best"
                    cloudName="du1dvxjo8"
                    publicId={image}
                    crop="scale"
                  />
                ))}
              <SlArrowRight
                className="product__section__one__arrow"
                onClick={() => handleArrowClick('forward')}
              />
            </section>
            <section className="product__section__two">
              <div className="product__section__two__start">
                <article className="product__section__two__article1">
                  <h3>{address}</h3>
                  <h1>{title}</h1>
                  <p>{description}</p>
                  {detailed_description && <p>{detailed_description}</p>}
                </article>
                <article className="product__section__two__articleI">
                  <span>
                    <h1>YEAR BUILT</h1>
                    <span>
                      <BsFillCalendarDateFill className="product__section__two__articleI__icon" />{' '}
                      <p>{year_built}</p>
                    </span>
                  </span>
                  <span>
                    <h1>HEATING TYPE</h1>
                    <span>
                      <GiTheater className="product__section__two__articleI__icon" />{' '}
                      <p>{heating_type}</p>
                    </span>
                  </span>
                  <span>
                    <h1>COOLING TYPE</h1>
                    <span>
                      <GiCoolSpices className="product__section__two__articleI__icon" />{' '}
                      <p>{cooling_type}</p>
                    </span>
                  </span>
                  <span>
                    <h1>LAND SIZE</h1>
                    <span>
                      <TbRulerMeasure className="product__section__two__articleI__icon" />{' '}
                      <p>{`${land_size} sqft`}</p>
                    </span>
                  </span>
                </article>
                <article className="product__section__two__article2">
                  <span>
                    <h3>Key spatial features</h3>
                    <h4>Parking: {parking}</h4>
                    <h4>Basement: {basement}</h4>
                    <h4>Flooring type: {flooring_type}</h4>
                    <h4>Building size: {building_size}</h4>
                  </span>
                  <span>
                    <h3>Letting details</h3>
                    <h4>Occupancy: {occupancy}</h4>
                    <h4>Ownership type: {ownership_type}</h4>
                    <h4>Exterior features: {exterior_features}</h4>
                  </span>
                </article>
                <article className="product__section__two__article3">
                  <h3>More informations</h3>
                  {result.features && result.features.length > 0 && <h4>Nearby Amenities</h4>}
                  <div className="product__section__two__article3__amenities">
                    {result.features &&
                      result.features.length > 0 &&
                      result.features.map((item, i) => (
                        <span key={i}>
                          <GoCheckCircleFill className="product__amenities__checkmark" /> {item}
                        </span>
                      ))}
                  </div>
                  <h4>Bedrooms and bathrooms</h4>
                  <div className="product__section__two__article3__bedrooms">
                    <span>
                      <GiBed className="product__bedrooms__icon" />
                      {bedrooms} bedrooms
                    </span>
                    <span>
                      <LiaBathSolid className="product__bedrooms__icon" />
                      {bathrooms} bathrooms
                    </span>
                  </div>
                </article>

                {additional_fees && (
                  <article className="product__section__two__article4">
                    <h3>Extras</h3>
                    <h4>Additional fees</h4>
                    <ul>
                      <li>{additional_fees}</li>
                    </ul>
                  </article>
                )}
              </div>
              <div className="product__section__two__end">
                <div>
                  <span>
                    {`${currency === 'NGN' ? '₦' : 'Le'}  ${CurrencyConverter(price)} `}
                    <p> {duration}</p>
                  </span>
                  <p onClick={() => navigate('/property-management')}>View other properties</p>
                  <button
                    className="product__section__two__end__button"
                    onClick={() => {
                      if (auth.user) {
                        setPropertyId(id);
                        navigate('/property/payment');
                      } else {
                        navigate('/login', { state: { from: location }, replace: true });
                      }
                    }}
                  >
                    Proceed to pay
                  </button>
                </div>
              </div>
            </section>
            {/* <section className="product__section__three">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6654220632436!2d3.507026175985806!3d6.437000024160492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf67d389945e5%3A0x1866152ec04410c2!2sKm%2042%20Lekki%20-%20Epe%20Expy%2C%20Lekki%20Penninsula%20II%2C%20Lagos%20101245%2C%20Lagos!5e0!3m2!1sen!2sng!4v1706347243090!5m2!1sen!2sng"
                width="600"
                height="450"
                style={{ border: '0', width: '100%' }}
                allowfullscreen=""
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </section> */}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Product;
