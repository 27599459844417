import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL_PROD;

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 10000, // Timeout of 10 seconds
  withCredentials: false, // Send cookies when making requests
  validateStatus: function (status) {
    return status >= 200 && status < 300; // Default status checking function
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
