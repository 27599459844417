import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import '../../../components/modal/modal.children/Signup.css';
import '../../../components/modal/modal.children/Login.css';
import gpslogodark from '../../../../assets/gps logo dark.png';
import gpslogowhite from '../../../../assets/gps logo light.png';
import { RiAccountCircleFill } from 'react-icons/ri';
import Modal from '../../modal/Modal.js';
import AuthContext, { AuthProvider } from '../../../../context/AuthProvider.js';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate.js';
import { AiOutlineClose } from 'react-icons/ai';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Toaster from '../../../../utils/toaster/Toaster.js';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Image, Placeholder, Transformation } from 'cloudinary-react';
import { Toggle } from '../../../../admin/components/ui/toggle/Toggle';
import { BiMenu } from 'react-icons/bi';
import { ScaleLoader } from 'react-spinners';
import { VscSettings } from 'react-icons/vsc';

function Header() {
  //Hooks declaration
  const formRef = useRef(null);
  const formRef2 = useRef(null);

  //Context and state declarations
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [headerAccount, setHeaderAccount] = useState(false);
  const [submenu, setSubmenu] = useState({
    one: false,
    two: false,
    three: false,
  });
  const [preference, setPreference] = useState(false);

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);

  // Access useContext state variables
  const {
    auth,
    setAuth,
    persist,
    setPersist,
    isDark,
    setIsDark,
    setCurrency,
    setManualCurrencySet,
  } = useContext(AuthContext);

  const axiosPrivate = useAxiosPrivate();

  const login = async () => {
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/login',
        JSON.stringify({ email: email, password: password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      setToaster(true);
      setbgcolor('#c6e6fb');
      setMessage('Login successful');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
      setIsLoading(false);

      response.data.data.user && setAuth((prev) => ({ ...prev, user: response.data.data.user }));
      response.data.data.user &&
        setAuth((prev) => ({ ...prev, accessToken: response.data.data.accessToken }));
      setIsLoading(false);

      if (response.data.data.user.role == 'Admin' || response.data.data.user.role == 'SuperAdmin') {
        setAuth((prev) => ({ ...prev, user: response.data.data.user }));
        setAuth((prev) => ({ ...prev, accessToken: response.data.data.accessToken }));
        setIsLoading(false);

        navigate('/admin/dashboard');
      }
    } catch (error) {
      setIsLoading(false);
      setToaster(true);
      setbgcolor('#d1ecf1');
      setMessage(error.response.data.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    }
    setIsLoading(false);
    setHeaderAccount(false);
    setMenu(false);
    closeModal();
  };

  const logout = async () => {
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post('api/v1/auth/logout', {
        headers: { 'Content-Type': 'application/json' },
      });
      setAuth({});
      navigate('/');
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
    setEmail('');
    setPassword('');
    closeModal();
    setMenu(false);
    setToaster(true);
    setbgcolor('#c6e6fb');
    setMessage('Logout successful');
    setTimeout(() => {
      setToaster(false);
    }, 3100);
    setIsLoading(false);
  };

  const googleSignin = async (userData) => {
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/google-signin',
        JSON.stringify({ userData: userData }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      setToaster(true);
      setbgcolor('#00e396');
      setMessage('Login successful');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
      setAuth((prev) => ({ ...prev, user: response.data.data.user }));
      setAuth((prev) => ({ ...prev, accessToken: response.data.data.accessToken }));
      setIsLoading(false);

      if (response.data.data.user.role == 'Admin' || response.data.data.user.role == 'SuperAdmin') {
        setIsLoading(false);
        navigate('/admin/dashboard');
      }
    } catch (error) {
      setIsLoading(false);

      setToaster(true);
      setbgcolor('#d1ecf1');
      setMessage(error.response.data.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    }
    setIsLoading(false);
    setMenu(false);
    closeModal();
  };

  const updatePassword = async () => {
    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/update-password',
        JSON.stringify({ email: 'jamieazamosa@gmail.com', newPassword: 'Carnivals719' }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      setAuth((prev) => ({ ...prev, user: null }));
    } catch (error) {
      console.log(error);
    }

    setMenu(false);
    closeModal();
  };

  const signup = async (e) => {
    if (formRef2.current && !formRef2.current.checkValidity()) {
      // If form is not valid, focus on the first invalid input field
      const invalidField = formRef2.current.querySelector(':invalid');
      if (invalidField) {
        invalidField.focus();
      }
    } else {
      e.preventDefault();

      if (password !== confirmPassword) {
        setPasswordNotMatch(true);
        return;
      }

      setIsLoading(true);

      try {
        const response = await axiosPrivate.post(
          'api/v1/auth/signup',
          JSON.stringify({
            email: email,
            password: password,
            phone: phone,
            firstname: firstname,
            lastname: lastname,
            role: 'EndUser',
          }),

          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );

        setToaster(true);
        setbgcolor('#00e396');
        setMessage('Signup sucecessful! a confirmation email has been sent to your email');
        setTimeout(() => {
          setToaster(false);
        }, 3100);
        setIsLoading(false);
      } catch (err) {
        if (!err?.response) {
          console.log('No server response');
          setIsLoading(false);
        } else if (err.response.status === 401) {
          console.log('Unauthorized');
          setIsLoading(false);
        } else {
          setToaster(true);
          setbgcolor('#d1ecf1');
          setMessage(err.response.data.message);
          setTimeout(() => {
            setToaster(false);
          }, 3100);
          setIsLoading(false);
        }
      }
      setIsLoading(false);
      setMenu(false);
      closeModal();
    }
  };

  // controls the visibility of the modal component
  const [isOpen, setIsOpen] = useState({
    signup: false,
    signup2: false,
    login: false,
    reset: false,
  });

  const openModal1 = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      login: false,
      signup2: false,
      signup: true,
      reset: false,
    }));
  };
  const openModal2 = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      signup: false,
      login: true,
      signup2: false,
      result: false,
    }));
  };

  const openModal3 = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      setIsOpen((prevState) => ({
        ...prevState,
        signup: false,
        login: false,
        signup2: true,
        reset: false,
      })); // Call setIsOpen function if all fields are valid
    } else {
      // If form is not valid, focus on the first invalid input field
      const invalidField = formRef.current.querySelector(':invalid');
      if (invalidField) {
        invalidField.focus();
      }
    }
  };

  const openModal4 = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      signup: false,
      login: false,
      signup2: false,
      reset: true,
    }));
  };

  const closeModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      signup: false,
      login: false,
      signup2: false,
      reset: false,
    }));
  };

  // Changes the background color on scroll
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerStyle = {
    backgroundColor: scrollPosition > 0 ? 'white' : 'white',
    transition: 'background-color 0.3s ease-in-out',
  };

  //persist the user login in memory state
  const togglePersist = () => {
    setPersist(!persist);
  };

  useEffect(() => {
    localStorage.setItem('persist', JSON.stringify(persist));
  }, [persist]);

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    // background: isDark ? '#121212' : '#000',
    backgroundColor: 'white',
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    return regex.test(password);
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
    setIsPasswordValid(validatePassword(password));
  };

  const handlePasswordChange2 = (e) => {
    setConfirmPassword(password);
    setIsPasswordValid(validatePassword(password));
  };

  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="header">
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

          <img
            src={isDark ? gpslogowhite : gpslogodark}
            alt=""
            className="header__logo"
            onClick={() => {
              navigate('/');
            }}
          />
          <div>
            <ul className="header__nav">
              <li
                className="header__nav__list"
                onMouseOver={() => {
                  setSubmenu((prevState) => ({
                    ...prevState,
                    one: true,
                    two: false,
                    three: false,
                  }));
                }}
                onClick={() => {
                  navigate('/property-management');
                }}
              >
                Property management
                {/* <ul
              onMouseOut={() => {
                setSubmenu((prevState) => ({
                  ...prevState,
                  one: false,
                  two: false,
                  three: false,
                }));
              }}
              className={submenu.one ? 'header__submenu mouseHover' : 'header__submenu'}
            >
              <li>
                Rental
                <ul className="header__submenu2">
                  <li>Commercial</li>
                  <li>Residential</li>
                </ul>
              </li>
              <li>
                Buy
                <ul className="header__submenu2">
                  <li>plota</li>
                  <li>plotb</li>
                  <li>plotc</li>
                </ul>
              </li>
              <li>
                Lease
                <ul className="header__submenu2">
                  <li>dataa</li>
                  <li>databb</li>
                  <li>datac</li>
                </ul>
              </li>
            </ul> */}
              </li>
              <li>
                <h3
                  className="header__nav__list"
                  onClick={() => {
                    navigate('/student-property');
                  }}
                >
                  Student property
                </h3>
                <ul className="header__submenu">
                  <li>Shared apartment</li>
                  <li>
                    Hostel
                    <ul className="header__submenu2">
                      <li>Private hostel</li>
                      <li>University hostel</li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <li
            className="header__nav__list"
            onClick={() => {
              navigate('/admin/dashboard');
            }}
          >
            Property construction
          </li> */}
              <li
                className="header__nav__list"
                onClick={() => {
                  navigate('/agents');
                }}
              >
                Agents/Agencies
              </li>
            </ul>
          </div>
          <div className="header__user">
            <h3
              onClick={() => {
                navigate('/list-property');
              }}
            >
              List property
            </h3>

            <div className="header__account">
              <button
                onClick={() => {
                  setHeaderAccount(!headerAccount);
                }}
              >
                <BiMenu className="header__user__icon" />
                {auth.user && auth.user.profile_image ? (
                  <Image
                    quality="auto:best"
                    loading="lazy"
                    cloudName="du1dvxjo8"
                    publicId={auth.user.profile_image}
                    width="30"
                    // crop="scale"
                  >
                    {' '}
                    {/* <Placeholder type="blur"></Placeholder> */}
                  </Image>
                ) : (
                  <RiAccountCircleFill className="header__user__dummy__icon" />
                )}
              </button>
              <ul
                className={
                  headerAccount ? 'header__submenu__account clicked' : 'header__submenu__account'
                }
              >
                <li>
                  {' '}
                  {auth.user ? (
                    <span onClick={logout}>Log out</span>
                  ) : (
                    <span onClick={openModal2}> Login </span>
                  )}
                </li>
                <li onClick={openModal1}>Signup</li>
                {auth.user ? (
                  <li
                    onClick={() => {
                      navigate('/inbox');
                    }}
                  >
                    Inbox
                  </li>
                ) : (
                  <></>
                )}
                {auth.user && (
                  <li
                    onClick={() => {
                      navigate('/wishlist');
                    }}
                  >
                    wishlist
                  </li>
                )}

                <div>
                  {auth.user ? (
                    <li
                      onClick={() => {
                        navigate('/account/personal-info');
                      }}
                    >
                      Account
                    </li>
                  ) : (
                    <></>
                  )}

                  <li>Help center</li>
                  <Toggle
                    isChecked={isDark}
                    handleChange={() => {
                      setIsDark(!isDark);
                    }}
                  />
                  <span className="header__submenu__account__span">
                    <select
                      name="currency"
                      id="currency"
                      onChange={(e) => {
                        setCurrency(e.target.value);
                        setManualCurrencySet(true);
                      }}
                    >
                      <option value="NGN">NGN</option>
                      <option value="SLL">SLL</option>
                    </select>
                    <h3>Currency</h3>
                  </span>
                </div>
              </ul>
            </div>
          </div>

          {/* SIDE NAVBAR COMPONENTS */}
          <span className="home_menu">
            <VscSettings
              onClick={() => {
                setPreference(!preference);
              }}
            />
            <ul
              className={
                preference ? 'home__menu__submenu__account clicked' : 'home__menu__submenu__account'
              }
            >
              <span>
                <Toggle
                  isChecked={isDark}
                  handleChange={() => {
                    setIsDark(!isDark);
                  }}
                />
                <h3>Dark mode</h3>
              </span>
              <span>
                <select
                  name="currency"
                  id="currency"
                  onChange={(e) => {
                    setCurrency(e.target.value);
                    setManualCurrencySet(true);
                  }}
                >
                  <option value="NGN">NGN</option>
                  <option value="SLL">SLL</option>
                </select>
                <h3>Currency</h3>
              </span>
            </ul>
          </span>
          <BiMenu
            className="home_menu_icon"
            onClick={() => {
              setMenu(true);
            }}
          />
          <div className={menu ? 'home_sidebar_true' : 'home_sidebar'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3>
                <img
                  src={isDark ? gpslogowhite : gpslogodark}
                  alt=""
                  className="gps_header2"
                  onClick={() => {
                    navigate('/');
                    setMenu(false);
                  }}
                />
              </h3>
              <AiOutlineClose
                className="home_sidebar_close"
                onClick={() => {
                  setMenu(false);
                }}
              />
            </div>

            <div className="header__navbar__account">
              {auth.user && auth.user.profile_image ? (
                <Image
                  quality="auto:best"
                  loading="lazy"
                  cloudName="du1dvxjo8"
                  publicId={auth.user.profile_image}
                  width="30"
                  // crop="scale"
                >
                  {' '}
                  {/* <Placeholder type="blur"></Placeholder> */}
                </Image>
              ) : (
                <RiAccountCircleFill className="header__user__icon" />
              )}
              <h3>Hello {auth.user ? auth.user.full_name : 'there'}</h3>
            </div>

            <ul className="header__navbar__dropdown">
              <li
                className="dropdown"
                onClick={() => {
                  navigate('/property-management');
                  setMenu(false);
                }}
              >
                <div className="dropdown_div">
                  <h1>PROPERTY MANAGEMENT</h1>
                </div>
              </li>
              <li
                className="dropdown"
                onClick={() => {
                  navigate('/student-property');
                  setMenu(false);
                }}
              >
                <div className="dropdown_div">
                  <h1>STUDENT PROPERTY</h1>
                </div>
              </li>
              <li
                className="dropdown"
                onClick={() => {
                  navigate('/agents');
                  setMenu(false);
                }}
              >
                <div className="dropdown_div">
                  <h1>AGENTS/AGENCIES</h1>
                </div>
              </li>
            </ul>

            <ul className="home_sidebar_navbar">
              <li>
                <h3>
                  {auth.user ? (
                    <span onClick={logout}>Log out</span>
                  ) : (
                    <span onClick={openModal2}> Login </span>
                  )}
                </h3>
              </li>
              <li onClick={openModal1}>
                <h3 onClick={() => {}}>Signup</h3>
              </li>
              <li>
                <h3
                  onClick={() => {
                    navigate('/list-property');
                    setMenu(false);
                  }}
                >
                  List property
                </h3>
              </li>
              {auth.user && (
                <li>
                  <h3
                    onClick={() => {
                      navigate('/wishlist');
                      setMenu(false);
                    }}
                  >
                    Wishlist
                  </h3>
                </li>
              )}
              {auth.user && (
                <li>
                  <h3
                    onClick={() => {
                      navigate('/inbox');
                      setMenu(false);
                    }}
                  >
                    Inbox
                  </h3>
                </li>
              )}
              {auth.user && (
                <li>
                  <h3
                    onClick={() => {
                      navigate('/account/personal-info');
                      setMenu(false);
                    }}
                  >
                    Account
                  </h3>
                </li>
              )}
              <li>
                <h3>Help center</h3>
              </li>
            </ul>
          </div>

          {/* POP UP MODALS FOR SIGNIN, LOGIN AND SIGNUP */}
          <Modal isOpen={isOpen.login} onClose={closeModal}>
            <div className="login">
              <div className="login_body">
                <div className="login_form">
                  <h2>Welcome back</h2>
                  <p className="login_text">
                    New to Get Properties Solution?{''}
                    <span onClick={openModal1} className="login_h3">
                      sign up
                    </span>
                  </p>
                  <input
                    className="login_input"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    required
                    autoComplete
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    className="login_input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <button className="login_button" onClick={login}>
                    Login
                  </button>
                  <div className="login__remember">
                    <input
                      type="checkbox"
                      id="persist"
                      onChange={togglePersist}
                      checked={persist}
                    />
                    <label htmlFor="persist">Remember my login</label>
                  </div>
                  <p className="login_text" onClick={openModal4}>
                    Forgot password?
                  </p>
                </div>
                <div>
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      const decoded = jwtDecode(credentialResponse?.credential);
                      // console.log(decoded);
                      googleSignin(decoded);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={isOpen.reset} onClose={closeModal}>
            <div className="signup">
              <div className="signup_div">
                <div className="signup_form">
                  <h1 style={{ margin: '20px 0' }}>
                    We’ll send a message to your email to reset your password
                  </h1>
                  <input
                    placeholder="Enter email address"
                    id="email"
                    className="signup_input"
                    type="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />
                  <div>
                    <button onClick={updatePassword}>Send reset message</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={isOpen.signup} onClose={closeModal}>
            <form ref={formRef} className="signup">
              <div className="signup_div">
                <div className="signup_form">
                  <h1>Create an account on GPS</h1>
                  <input
                    placeholder="Enter email address"
                    id="email"
                    className="signup_input"
                    type="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />
                  <div>
                    <button onClick={openModal3}> Continue</button>
                    <p>
                      already have an account? <span onClick={openModal2}>Login</span>
                    </p>
                  </div>
                </div>
                <p>
                  By signing in or creating an account, you agree with our Terms & Conditions and
                  Privacy Statement
                </p>
              </div>
              <div>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    const decoded = jwtDecode(credentialResponse?.credential);
                    // console.log(decoded);
                    googleSignin(decoded);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </div>
            </form>
          </Modal>
          <Modal isOpen={isOpen.signup2} onClose={closeModal}>
            <form ref={formRef2} className="signup">
              <div className="signup_div">
                <div className="signup_form">
                  <h1>Let's get started</h1>
                  <input
                    placeholder="First name"
                    id="firstname"
                    className="signup_input"
                    type="text"
                    required
                    onChange={(e) => {
                      setFirstname(e.target.value);
                    }}
                    style={{ width: '49%' }}
                  />
                  <input
                    placeholder="Last name"
                    id="lastname"
                    className="signup_input"
                    type="text"
                    required
                    onChange={(e) => {
                      setLastname(e.target.value);
                    }}
                    style={{ width: '49%' }}
                  />

                  <div>
                    <PhoneInput
                      onlyCountries={['ng', 'sl']}
                      inputProps={{
                        name: 'phone',
                        required: true,
                      }}
                      country={'ng'}
                      onChange={(phone) => setPhone(phone)}
                      inputStyle={{
                        width: '100%',
                        margin: '0',
                      }}
                      containerStyle={{
                        marginBottom: '20px',
                      }}
                    />
                  </div>
                  <input
                    placeholder="Password"
                    id="password"
                    className="signup_input"
                    type="password"
                    required
                    onChange={(e) => {
                      handlePasswordChange(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />
                  <input
                    placeholder="Confirm Password"
                    id="password"
                    className="signup_input"
                    type="password"
                    required
                    onChange={(e) => {
                      handlePasswordChange2(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />

                  {passwordNotMatch && (
                    <div className="signup__mismatch">
                      <p className="signup__mismatch__text">Password does not match</p>
                    </div>
                  )}
                  {password && (
                    <div>
                      {isPasswordValid ? (
                        <p className="signup__match__text">Password is valid</p>
                      ) : (
                        <p className="signup__match__text__invalid">
                          Password must contain at least 1 uppercase letter, 1 lowercase letter, 1
                          number and must be at least 8 letters long
                        </p>
                      )}
                    </div>
                  )}
                  <div>
                    <button onClose={closeModal} onClick={signup}>
                      Sign up
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      )}
    </>
  );
}

export default Header;
