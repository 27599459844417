import { useContext, useState } from 'react';
import './Ticket.css';
import Toaster from '../../../utils/toaster/Toaster';
import { useNavigate } from 'react-router-dom';
import { AiFillFileImage } from 'react-icons/ai';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { BsAsterisk } from 'react-icons/bs';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import { RiArrowRightSLine } from 'react-icons/ri';
import { PiWarningOctagonFill } from 'react-icons/pi';
import { ScaleLoader } from 'react-spinners';

function Ticket() {
  const { auth, isDark } = useContext(AuthContext);

  const navigate = useNavigate();
  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const [status, setStatus] = useState('pending');
  const [subject, setSubject] = useState(null);
  const [description, setDescription] = useState(null);
  const [senderName, setSenderName] = useState(null);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('No file selected');

  const saveTicket = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(
        'api/v1/ticket/new-ticket',
        JSON.stringify({
          senderName: senderName,
          fileName: fileName,
          senderId: auth.user.id,
          senderEmail: auth.user.email || 'test@gmail.com',
          description: description,
          status: status,
          subject: subject,
          image: image,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );

      setStatus('');
      setSubject('');
      setSenderName('');
      setDescription('');
      setImage(null);
      setFileName('No file selected');

      setToaster(true);
      setbgcolor('#00e396');
      setMessage('Ticket created successfully');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
      setIsLoading(false);
    } catch (err) {
      if (!err?.response) {
        console.log('No server response');
      } else if (err.response.status === 401) {
        console.log('Unauthorized');
      }
    }
    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="create__ticket account">
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}
          <section className="personal__section__one">
            <h3>
              account <RiArrowRightSLine className="personal__icon__one" /> tickets
            </h3>
            <h1>Tickets</h1>
          </section>

          <section className="create__ticket__section1">
            <div className="admin__settings__section__two__theme__alert">
              <PiWarningOctagonFill className="admin__settings__section__two__theme__alert__icon" />
              Do you have any complaints or issues? Create a ticket and let us know.
            </div>
            <h3>Ticket details</h3>
            <article>
              <div className="edit__user__article__div">
                <label>
                  Requester Name
                  <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                </label>
                <input
                  type="text"
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                />
              </div>
              <div className="edit__user__article__div">
                <label>
                  Ticket subject
                  <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                </label>
                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
              </div>
              <div className="edit__user__article__div">
                <label>Image (optional)</label>
                <form
                  className="image__upload"
                  onClick={() => document.querySelector('.input__field').click()}
                >
                  <input
                    type="file"
                    className="input__field"
                    hidden
                    onChange={({ target: { files } }) => {
                      files[0] && setFileName(files[0].name);
                      if (files) {
                        const reader = new FileReader();
                        reader.readAsDataURL(files[0]);
                        reader.onloadend = () => {
                          setImage(reader.result);
                        };
                      }
                    }}
                  />

                  {image ? (
                    <img src={image} width={75} height={75} alt={fileName} />
                  ) : (
                    <MdCloudUpload color="#1475cf" size={50} />
                  )}
                </form>
                <div className="image__upload__label">
                  <AiFillFileImage color="#1475" />
                  <span>
                    {fileName}
                    <MdDelete
                      onClick={() => {
                        setFileName('No file selected');
                        setImage(null);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="edit__user__article__div">
                <label>
                  Description <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                </label>
                <textarea
                  type="text"
                  placeholder="Why are you raising this ticket?"
                  name="text"
                  rows="8"
                  cols="6"
                  wrap="soft"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </article>
            <span>
              <button onClick={saveTicket}>Save</button>
              <button
                onClick={() => {
                  navigate('/admin/tickets');
                }}
              >
                Cancel
              </button>
            </span>
          </section>
        </div>
      )}
    </>
  );
}

export default Ticket;
