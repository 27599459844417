import { MdKeyboardArrowLeft } from 'react-icons/md';
import './Property.management.css';
import { useNavigate } from 'react-router-dom';
import AccountFooter from '../../components/layout/account.footer/Account.footer.js';
import Header from '../../components/layout/header/Header';
import { useContext, useEffect, useState } from 'react';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import Cardlist from '../../components/cardlist/Cardlist';
import NothingToDisplay from '../../../utils/nothing.to.display/NothingToDisplay.js';
import { ScaleLoader } from 'react-spinners';

function PropertyManagement() {
  const { setAuth, isDark } = useContext(AuthContext);
  const navigate = useNavigate();

  const [propertyResult, setPropertyResult] = useState([]);
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
    select3: false,
  });

  useEffect(() => {
    // Function to fetch properties based on the selected category
    const getProperties = async (category) => {
      try {
        const response = await axiosPrivate.get(`api/v1/property/search?category=${category}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setPropertyResult(res.properties);
        setAuth((prev) => ({ ...prev, properties: res.properties }));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    // Fetch rental properties initially
    getProperties('rental');
  }, []);

  // Function to handle category selection
  const handleCategorySelect = async (category) => {
    setSelect({
      select1: category === 'rental',
      select2: category === 'sale',
      select3: category === 'lease',
    });

    // Fetch properties based on selected category
    await getProperties(category);
  };

  // Function to fetch properties based on the selected category
  const getProperties = async (category) => {
    setIsLoading(true);

    try {
      const response = await axiosPrivate.get(`api/v1/property/search?category=${category}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const res = response.data.data;
      setPropertyResult(res.properties);
      setAuth((prev) => ({ ...prev, properties: res.properties }));
      setIsLoading(false);
    } catch (error) {
      setPropertyResult([]);
      setIsLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh',
  };
  return (
    <div>
      <Header />
      <div className="property__management">
        <section className="wishlist__section__one">
          <div>
            <MdKeyboardArrowLeft
              className="wishlist__section__one__icon"
              onClick={() => navigate(-1)}
            />
          </div>
          <h1>Property management</h1>
        </section>
        <section className="property__management__section__one">
          <div className="account__notifications__select__div">
            <button
              className={
                select.select1
                  ? 'account__notifications__select selected'
                  : 'account__notifications__select'
              }
              onClick={() => handleCategorySelect('rental')}
            >
              Rental
            </button>
            <button
              className={
                select.select2
                  ? 'account__notifications__select selected'
                  : 'account__notifications__select'
              }
              onClick={() => handleCategorySelect('sale')}
            >
              Sale
            </button>
            <button
              className={
                select.select3
                  ? 'account__notifications__select selected'
                  : 'account__notifications__select'
              }
              onClick={() => handleCategorySelect('lease')}
            >
              Lease
            </button>
          </div>
          <div>
            {select.select1 || select.select2 || select.select3 ? (
              <div className="">
                {isLoading ? (
                  <div style={cssDiv}>
                    <ScaleLoader color={isDark ? '#fff' : '#000'} />
                  </div>
                ) : (
                  <>
                    {propertyResult && propertyResult.length > 0 ? (
                      <Cardlist propertyResult={propertyResult} />
                    ) : (
                      <NothingToDisplay />
                    )}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </section>
      </div>
      <AccountFooter />
    </div>
  );
}

export default PropertyManagement;
