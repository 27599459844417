import { Image, Placeholder, Transformation } from 'cloudinary-react';
import './CardReview.css';
import { BsStarFill } from 'react-icons/bs';
import { RiAccountCircleFill } from 'react-icons/ri';

function CardReview({ comment, createdAt, rating, senderName, profileImage }) {
  return (
    <div className="review">
      <section className="review__section__one">
        {profileImage ? (
          <Image
            quality="auto:best"
            loading="lazy"
            cloudName="du1dvxjo8"
            publicId={profileImage}
            width="300"
            crop="scale"
            className="review__img"
            alt=""
          >
            <Placeholder type="blur"></Placeholder>
          </Image>
        ) : (
          <RiAccountCircleFill className="review__user__icon" />
        )}

        <div>
          <h3>{senderName}</h3>
          <p>{createdAt}</p>
          {/* <p>Oslo, Norway</p> */}
        </div>
      </section>
      <section className="review__section__two">
        <span>
          {Array(rating)
            .fill()
            .map(() => {
              return <BsStarFill className="review_icon_star" />;
            })}
        </span>
        {/* <p>{createdAt}</p> */}
      </section>
      <section className="review__section__three">
        <p>{comment}</p>
      </section>
    </div>
  );
}

export default CardReview;
