import React from 'react';
import './Cardlist.css';
import CardAgencyListing from '../card/CardAgencyListing.js';

const CardlistAgencyListing = ({ listings }) => {
  return (
    <div className="cardlist">
      {listings.map((item, i) => {
        return (
          <CardAgencyListing
            key={i}
            index={listings[i].id}
            title={listings[i].title}
            images={listings[i].images}
            description={listings[i].description}
            address={listings[i].address}
            price={listings[i].price}
            bedrooms={listings[i].bedrooms}
            bathrooms={listings[i].bathrooms}
            currency={listings[i].currency}
            duration={listings[i].duration}
          />
        );
      })}
    </div>
  );
};

export default CardlistAgencyListing;
