import Header from '../../components/layout/header/Header';
import './Single.agent.css';
import { useNavigate } from 'react-router-dom';
import { IoIosSearch } from 'react-icons/io';
import { useContext, useEffect, useState } from 'react';
import AccountFooter from '../../components/layout/account.footer/Account.footer';
import CardlistAgencyListing from '../../components/cardlist/CardlistAgencyListing';
import CardlistReview from '../../components/cardlist/CardlistReview';
import { axiosPrivate } from '../../../api/axios';
import AuthContext from '../../../context/AuthProvider';
import Modal from '../../components/modal/Modal';
import { RiAsterisk } from 'react-icons/ri';
import { Image, Placeholder, Transformation } from 'cloudinary-react';
import NothingToDisplay from '../../../utils/nothing.to.display/NothingToDisplay';
import { ScaleLoader } from 'react-spinners';

function SingleAgent() {
  const { auth, userId, applicationId, isDark } = useContext(AuthContext);
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const [isOpen, setIsOpen] = useState(null);
  const [rating, setRating] = useState(0);

  const closeModal = () => {
    setIsOpen(false);
  };

  const [result, setResult] = useState({});
  const [user, setUser] = useState({});
  const [listings, setListings] = useState([]);
  const [reviews, setReviews] = useState([]);

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
    select3: false,
  });

  const getAllReviews = async () => {
    try {
      const response = await axiosPrivate.post(
        `api/v1/review/reviews`,
        JSON.stringify({
          type: 'agent',
          id: applicationId,
          userId: userId,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.data;
      setReviews(res.review);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const getSingleAgent = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agent/single-agent/${userId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setResult(res.singleAgent);
        setUser(res.singleUser);
      } catch (error) {
        console.log(error);
      }
    };

    // const increaseViewCount = async () => {
    //   try {
    //     const response = await axiosPrivate.get(`api/v1/agent/increase-view/${userId}`, {
    //       headers: { 'Content-Type': 'application/json' },
    //       withCredentials: true,
    //     });
    //     const res = response.data.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const getAgentListing = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/agent/agent-listings/${userId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setListings(res.agentListings);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getAllReviews();
    getSingleAgent();
    // increaseViewCount();
    getAgentListing();

    return;
  }, []);

  const submit = async () => {
    try {
      const response = await axiosPrivate.post(
        `api/v1/review/create-review`,
        JSON.stringify({
          userId: auth.user.id,
          rating: rating,
          comment: comment,
          type: 'agent',
          agentId: result.id,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.data;
      getAllReviews();
      closeModal();
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log(error);
    }
  };
  const [isLoading, setIsLoading] = useState(true);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div>
          <Header />
          <div className="singleagent">
            <section className="singleagent__section__one">
              <div>
                <input placeholder="Search listed properties" type="text" />
                <button>
                  <IoIosSearch className="singleagent__section__one__icon" />
                </button>
              </div>
            </section>
            <section className="singleagent__section__two">
              <div className="account__notifications__select__div  agents__section__two__div">
                <button
                  className={
                    select.select1
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: true,
                      select2: false,
                      select3: false,
                    }))
                  }
                >
                  Home
                </button>
                <button
                  className={
                    select.select2
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: false,
                      select2: true,
                      select3: false,
                    }))
                  }
                >
                  Listings
                </button>
                <button
                  className={
                    select.select3
                      ? 'account__notifications__select selected'
                      : 'account__notifications__select'
                  }
                  onClick={() =>
                    setSelect((prevState) => ({
                      ...prevState,
                      select1: false,
                      select2: false,
                      select3: true,
                    }))
                  }
                >
                  Reviews
                </button>
              </div>
              <div className="singleagent__section__two__segment2">
                {select.select1 ? (
                  <div className="singleagent__section__two__segment2__home">
                    <section className="singleagent__section__two__segment2__home__section1">
                      <div className="singleagent__section__two__segment2__home__section1__div1">
                        {result.profile_image && (
                          <Image
                            quality="auto:best"
                            loading="lazy"
                            cloudName="du1dvxjo8"
                            publicId={result.profile_image}
                            width="300"
                            crop="scale"
                          />
                        )}
                      </div>

                      <div className="singleagent__section__two__segment2__home__section1__div2">
                        <h3>Agent {user.full_name}</h3>
                        <p>{result.description}</p>
                        <article>
                          <div>
                            <h3>Mobile</h3>
                            <p>{result.mobile_phone}</p>
                          </div>
                          <div>
                            <h3>Office</h3>
                            <p>{result.office_phone}</p>
                          </div>
                          <div>
                            <h3>Email</h3>
                            <p>{result.business_email}</p>
                          </div>
                          <div>
                            <h3>Website</h3>
                            <p>{result.website}</p>
                          </div>
                        </article>
                      </div>
                    </section>
                    <section className="singleagent__section__two__segment2__home__section2">
                      <div>
                        <h3>Number of listings</h3>
                        <h2>{listings.length}</h2>
                      </div>
                      <div>
                        <h3>Number of reviews</h3>
                        <h2>{reviews.length}</h2>
                      </div>
                      <div>
                        <h3>Number of views</h3>
                        <h2>{result.views}</h2>
                      </div>
                    </section>
                  </div>
                ) : (
                  <></>
                )}

                {select.select2 ? (
                  <div className="">
                    {listings && listings.length > 0 ? (
                      <div className="">
                        <CardlistAgencyListing listings={listings} />
                      </div>
                    ) : (
                      <NothingToDisplay />
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {select.select3 ? (
                  <div>
                    {auth.user && auth.user.id !== userId && (
                      <div className="singleagent__section__two__review">
                        <span>
                          <h3>Share a review</h3>
                          <button onClick={() => setIsOpen(true)}>Comment</button>
                        </span>
                      </div>
                    )}
                    {reviews && reviews.length > 0 ? (
                      <div className="">
                        <CardlistReview reviews={reviews} />
                      </div>
                    ) : (
                      <NothingToDisplay />
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </section>
          </div>
          <AccountFooter />

          {/* Give review modal */}
          <Modal isOpen={isOpen} onClose={closeModal}>
            <div className="">
              <div className="admin__message__conversation">
                <section className="admin__message__conversation__section__one">
                  <h1>Give review about this agent </h1>
                </section>
                <section className="admin__message__conversation__section__two">
                  <h1>
                    Rating (1 representing leaast and 5 representing highest){' '}
                    <RiAsterisk className="admin__message__conversation__section__two__icons" />
                  </h1>
                  <select
                    name="category"
                    id="category"
                    onChange={(e) => setRating(e.target.value)}
                    className="list__input"
                  >
                    <option value={null}>--</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <h1>
                    Comment{' '}
                    <RiAsterisk className="admin__message__conversation__section__two__icons" />
                  </h1>
                  <textarea
                    type="text"
                    name="text"
                    rows="8"
                    cols="6"
                    wrap="soft"
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </section>
                <section className="admin__message__conversation__section__three">
                  <div>
                    <button onClick={closeModal}>Cancel</button>
                    <button onClick={submit}>Send</button>
                  </div>
                </section>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default SingleAgent;
