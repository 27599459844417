import { useNavigate } from 'react-router-dom';
import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import './Student.listing.complete.css';

function StudentListingComplete() {
  const navigate = useNavigate();

  return (
    <div className="studentlistingcomplete__main">
      <Header />
      <div className="studentlistingcomplete">
        <h1>Your application was sent</h1>
        <p>
          We'll review your student application and we will get back to you soonest after reviewing
          your documents sent.
        </p>
        <h3>
          go back to{' '}
          <span
            onClick={() => {
              navigate('/');
            }}
          >
            home
          </span>
        </h3>
      </div>
      <Footer />
    </div>
  );
}

export default StudentListingComplete;
