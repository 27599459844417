import { RiArrowRightSLine } from 'react-icons/ri';
import './AccountPayment.css';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import AuthContext from '../../../context/AuthProvider';

function AccountPayment() {
  const { isDark } = useContext(AuthContext);
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="account__payment">
          <section className="personal__section__one">
            <h3>
              account <RiArrowRightSLine className="personal__icon__one" /> payment and transactions
            </h3>
            <h1>Payments and payouts</h1>
          </section>
          <section className="account__payment__section__one">
            <div className="account__notifications__select__div">
              <button
                className={
                  select.select1
                    ? 'account__notifications__select selected'
                    : 'account__notifications__select'
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: true,
                    select2: false,
                  }))
                }
              >
                Payments
              </button>
              <button
                className={
                  select.select2
                    ? 'account__notifications__select selected'
                    : 'account__notifications__select'
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: false,
                    select2: true,
                    select3: false,
                  }))
                }
              >
                Payouts
              </button>
            </div>
            <div>
              {select.select1 ? (
                <>
                  <div className="account__payment__section__one__payout">
                    <h1>Transaction history</h1>
                    <div>
                      <p>Keep track of you payment and payouts</p>
                      <button
                        onClick={() => {
                          navigate('/account/payment/transactions');
                        }}
                      >
                        View transactions
                      </button>
                    </div>
                  </div>
                  <div className="account__payment__section__one__payout">
                    <h1>Payments method</h1>
                    <div>
                      <p>
                        Add a payment method using our secure payment system, then start planning
                        your next trip.
                      </p>
                      <button>Add payment method</button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {select.select2 ? (
                <div className="account__payment__section__one__payout">
                  <h1>How you’ll get paid</h1>
                  <div>
                    <p>Add at least one payout method so we know where to send your money</p>
                    <button>Set up payouts</button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default AccountPayment;
