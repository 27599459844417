import { useContext } from 'react';
import AuthContext from '../context/AuthProvider';

export default function CurrencyConverter(amount) {
  const { currency, rate } = useContext(AuthContext);

  const fromCurrency = currency;

  if (fromCurrency === 'NGN') {
    return parseFloat(amount).toLocaleString();
  } else if (fromCurrency === 'SLL') {
    const convertedPrice = amount * rate;
    const convertedPriceToLocale = parseFloat(convertedPrice).toLocaleString();

    return convertedPriceToLocale;
  }
}
