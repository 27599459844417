import React from 'react';
import './NothingToDisplay.css';
import image from '../../assets/file.png';

const NothingToDisplay = () => {
  return (
    <div className="nothing-to-display-container">
      <div className="nothing-to-display-content">
        <img src={image} alt="" />
        <h2>Nothing to display</h2>
        <p>Sorry, there is nothing to display at the moment.</p>
      </div>
    </div>
  );
};

export default NothingToDisplay;
