import React, { Component, useContext, useState } from 'react';
import './Card.css';
import './CardAgencyListing.css';
import { IoLocationOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/AuthProvider';
import { FaHeart } from 'react-icons/fa';
import Toaster from '../../../utils/toaster/Toaster';
import { axiosPrivate } from '../../../api/axios';
import { Image, Placeholder, Transformation } from 'cloudinary-react';

function CardAgencyListing({
  title,
  description,
  index,
  address,
  price,
  bathrooms,
  bedrooms,
  images,
  duration,
  currency,
}) {
  const { auth, setAuth, propertyId, setPropertyId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [clicked, setCLicked] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const addToWishlist = async () => {
    console.log('images', images);
    try {
      const response = await axiosPrivate.post(
        `api/v1/wishlist/create-wishlist`,
        JSON.stringify({
          userId: auth.user.id,
          propertyId: index,
          propertyTitle: title,
          bathrooms: bathrooms,
          bedrooms: bedrooms,
          description: description,
          images: images,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response;
      setToaster(true);
      setbgcolor('#00e396');
      setMessage('Property added to wishlist');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card card__agency__listing">
      {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

      {images && images.length > 0 && (
        <Image
          quality="auto:best"
          loading="lazy"
          cloudName="du1dvxjo8"
          publicId={images[0]}
          width="300"
          crop="scale"
          onClick={() => {
            setPropertyId(index);
            navigate(`/property/${index}`);
          }}
        >
          <Placeholder type="blur"></Placeholder>
        </Image>
      )}

      <h1
        onClick={() => {
          setPropertyId(index);
          navigate(`/property/${index}`);
        }}
      >
        {title}
      </h1>
      <h2
        onClick={() => {
          setPropertyId(index);
          navigate(`/property/${index}`);
        }}
      >
        {description}
      </h2>
      <h3
        onClick={() => {
          setPropertyId(index);
          navigate(`/property/${index}`);
        }}
      >
        <IoLocationOutline /> {address}
      </h3>

      <span>
        <h4
          onClick={() => {
            setPropertyId(index);
            navigate(`/property/${index}`);
          }}
        >
          {`${currency} ${price} ${duration}`}
        </h4>
        <span>
          <p className={show ? 'heart__wishlist__p showing' : 'heart__wishlist__p'}>Add to wishlist</p>
          <FaHeart
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
            onClick={() => {
              addToWishlist();
              setCLicked(true);
            }}
          />
        </span>
      </span>
    </div>
  );
}

export default CardAgencyListing;
