import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import './Contact.css';
// import image1 from '../../../assets/pavan-trikutam-71CjSSB83Wo-unsplash.jpg';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <div className="contact__bg">
      <Header />
      <div className="contact">
        <Helmet>
          <title>Contact us | GPS</title>
        </Helmet>
        <div className="contact__section__one">
          <h1>Get in touch with our dedicated team</h1>
        </div>
        <div className="contact__section__two">
          <div>
            <h1>For help and enquiry</h1>
            <p>
              Email us at{' '}
              <span style={{ textDecoration: 'underline', color: 'burlywood', cursor: 'pointer' }}>
                enquire@getpropertySolution.com
              </span>
              .
            </p>
            <p> We'll be happy to provide information</p>
          </div>
          <div>
            <h1>For business and promotion</h1>
            <p>
              Email us at{' '}
              <span style={{ textDecoration: 'underline', color: 'burlywood', cursor: 'pointer' }}>
                business@getpropertySolution.com
              </span>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
