import { useContext, useEffect, useState } from 'react';
import Footer from '../../components/layout/footer/Footer';
import Header from '../../components/layout/header/Header';
import './Wishlist.css';
import '../../components/card/CardWishlist.css';
import { BiSolidHide } from 'react-icons/bi';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import AuthContext from '../../../context/AuthProvider';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../components/modal/Modal';
import { FaHeart } from 'react-icons/fa';
import { axiosPrivate } from '../../../api/axios';
import Toaster from '../../../utils/toaster/Toaster';
import { Image, Placeholder, Transformation } from 'cloudinary-react';
import NothingToDisplay from '../../../utils/nothing.to.display/NothingToDisplay';
import { ScaleLoader } from 'react-spinners';

function Wishlist() {
  const { auth, isDark } = useContext(AuthContext);
  const [note, setNote] = useState('');
  const [name, setName] = useState('');
  const [wishlist, setWishlist] = useState([]);
  const [wishlistId, setWishlistId] = useState(null);

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  // controls the visiility of the delete modal
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  var getAllWishlists = async () => {
    try {
      const response = await axiosPrivate.post(
        `api/v1/wishlist/wishlists`,
        JSON.stringify({
          userId: auth.user.id,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.data;
      setWishlist(res.wishlist);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    var getAllWishlists = async () => {
      try {
        const response = await axiosPrivate.post(
          `api/v1/wishlist/wishlists`,
          JSON.stringify({
            userId: auth.user.id,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );
        const res = response.data.data;
        console.log(response);
        setWishlist(res.wishlist);
      } catch (error) {
        console.log(error);
      }
    };

    getAllWishlists();

    return;
  }, []);

  const removeFromWishlist = async (index) => {
    try {
      const response = await axiosPrivate.delete(`api/v1/wishlist/remove-wishlist/${index}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const res = response.data.message;
      setToaster(true);
      setbgcolor('#00e396');
      setMessage(res);
      setTimeout(() => {
        setToaster(false);
      }, 3100);

      getAllWishlists();
    } catch (error) {
      console.log(error);
    }
  };

  const updateNote = async () => {
    try {
      const response = await axiosPrivate.post(
        `api/v1/wishlist/update-note`,
        JSON.stringify({
          wishlistId: wishlistId,
          note: note,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const res = response.data.message;
      getAllWishlists();
      closeModal();
      setToaster(true);
      setbgcolor('#00e396');
      setMessage('Note updated succesfully');
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    } catch (error) {
      console.log(error);
    }
  };

  function CardWishlist({ name, description, index, note, bedrooms, bathrooms, images }) {
    const [hide, setHide] = useState(false);

    return (
      <div
        className="card__wishlist"
        onMouseOver={() => {
          setNote(note);
          setName(name);
          setHide(true);
        }}
        onMouseLeave={() => setHide(false)}
      >
        <FaHeart
          className={auth.wishlist ? 'card__wishlist__heart clicked' : 'card__wishlist__heart'}
          onClick={() => removeFromWishlist(index)}
        />
        <Link to={`/property/${index}`}>
          {images && images.length > 0 && (
            <Image
              quality="auto:best"
              loading="lazy"
              cloudName="du1dvxjo8"
              publicId={images[0]}
              width="300"
              crop="scale"
            >
              {' '}
              <Placeholder type="blur"></Placeholder>
            </Image>
          )}
        </Link>
        <h1>{name}</h1>
        <h3>{description}</h3>
        <h3>
          {bedrooms} bedrooms, {bathrooms} bathrooms
        </h3>
        {note ? (
          <h4
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Edit note
          </h4>
        ) : (
          <h4
            onClick={() => {
              setWishlistId(index);
              setIsOpen(true);
            }}
          >
            Add note
          </h4>
        )}
        <h5
          className={
            hide ? 'card__wishlist__hide__property' : 'card__wishlist__hide__property hover'
          }
        >
          <BiSolidHide className="card__wishlist__hide__property__icon" /> Hide
        </h5>
      </div>
    );
  }

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div>
          <Header />
          <div className="wishlist">
            {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

            <section className="wishlist__section__one">
              <div>
                <MdKeyboardArrowLeft
                  className="wishlist__section__one__icon"
                  onClick={() => navigate(-1)}
                />
              </div>
              <h1>My wishlist</h1>
            </section>
            <section className="wishlist__section__two">
              <div className="wishlist__section__two__segment1">
                {wishlist && wishlist.length === 0 && <NothingToDisplay />}

                <div className="cardlist">
                  {wishlist &&
                    wishlist.length > 0 &&
                    wishlist.map((item, i) => {
                      return (
                        <CardWishlist
                          key={i}
                          index={wishlist[i].id}
                          img={wishlist[i].img}
                          name={wishlist[i].property_title}
                          description={wishlist[i].description}
                          note={wishlist[i].note}
                          bedrooms={wishlist[i].bedrooms}
                          bathrooms={wishlist[i].bathrooms}
                          images={wishlist[i].images}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="wishlist__section__two__segment2">
                <h1>Note on {name}</h1>
                {note ? (
                  <p>{note}</p>
                ) : (
                  <div>
                    <h3>No note written for this property</h3>
                  </div>
                )}
              </div>
            </section>

            <Modal
              isOpen={isOpen}
              onClose={() => {
                closeModal();
                setWishlistId(null);
              }}
            >
              <div className="wishlist__note__modal">
                <textarea
                  placeholder={note ? 'Edit note' : 'Add note'}
                  type="text"
                  className="wishlist__note__modal__input"
                  name="text"
                  rows="14"
                  cols="10"
                  wrap="soft"
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                >
                  {note ? note : ''}
                </textarea>
                <p>Max 1000 characters</p>
                <button className="wishlist_button" onClick={() => updateNote()}>
                  Save
                </button>
              </div>
            </Modal>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Wishlist;
