import { useContext, useState, useEffect } from 'react';
import './Admin.settings.css';
import AuthContext from '../../../context/AuthProvider';
import { PiWarningOctagonFill } from 'react-icons/pi';
import { SketchPicker } from 'react-color';
import { BsAsterisk } from 'react-icons/bs';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiFillFileImage } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Toaster from '../../../utils/toaster/Toaster';
import { axiosPrivate } from '../../../api/axios';
import { ScaleLoader } from 'react-spinners';

function AdminSettings() {
  const navigate = useNavigate();

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState('');
  const [bgcolor, setbgcolor] = useState('');

  const { auth, setTheme, chartTheme, setChartTheme, isDark } = useContext(AuthContext);
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });

  const [result, setResult] = useState({});
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('No file selected');
  const [lastName, setLastName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [gender, setGender] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [state, setState] = useState(null);
  const [address, setAddress] = useState(null);
  const [role, setRole] = useState(null);
  const [verification, setVerification] = useState(false);

  const handleChangeComplete1 = (color) => {
    setChartTheme((prevState) => ({ ...prevState, primaryColor: color.hex }));
  };
  const handleChangeComplete2 = (color) => {
    setChartTheme((prevState) => ({ ...prevState, secondaryColor: color.hex }));
  };

  useEffect(() => {
    const getSingleUser = async () => {
      try {
        const response = await axiosPrivate.get(`api/v1/user/user/${auth.user.id}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const res = response.data.data;
        setResult(res);
      } catch (error) {
        console.log(error);
      }
    };

    getSingleUser();

    return;
  }, []);

  const updateUser = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post(
        'api/v1/user/admin-update-user',
        JSON.stringify({
          email: email,
          password: password,
          phone: phone,
          firstName: firstName,
          lastName: lastName,
          role: role,
          state: state,
          nationality: nationality,
          role: role,
          gender: gender,
          address: address,
          image: image,
          verification: verification,
          userId: auth.user.id,
        }),

        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      navigate('/admin/users');
      setToaster(true);
      setbgcolor('#00e396');
      setMessage(response.data.data.message);
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    } catch (err) {
      console.log(err);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="admin__settings">
          {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}
          <section className="account__notifications__section__two">
            <div className="account__notifications__select__div">
              <button
                className={
                  select.select1
                    ? 'account__notifications__select selected'
                    : 'account__notifications__select'
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: true,
                    select2: false,
                    select3: false,
                  }))
                }
              >
                Account
              </button>
              <button
                className={
                  select.select2
                    ? 'account__notifications__select selected'
                    : 'account__notifications__select'
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: false,
                    select2: true,
                    select3: false,
                  }))
                }
              >
                Theme
              </button>
            </div>
          </section>

          <section>
            {select.select1 ? (
              <div className="edit__user">
                {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

                <h1>Accout details</h1>
                <section className="edit__user__section1">
                  <h3>Edit your account details</h3>
                  <article>
                    <div className="edit__user__article__div">
                      <label>
                        First Name{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input
                        type="text"
                        alt=""
                        defaultValue={result.first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Last Name{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input
                        type="text"
                        alt=""
                        defaultValue={result.last_name}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Email <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input
                        type="email"
                        alt=""
                        defaultValue={result.email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="edit__user__article__div">
                      <label>
                        Country <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <CountryDropdown
                        whitelist={['NG', 'SL']}
                        value={nationality}
                        onChange={(val) => setNationality(val)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>State/Region</label>
                      <RegionDropdown
                        country={nationality}
                        value={state}
                        blankOptionLabel="State"
                        onChange={(val) => setState(val)}
                      />
                    </div>

                    <div className="edit__user__article__div">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="e.g Lekki, Rosario"
                        alt=""
                        defaultValue={result.address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Gender <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <select name="gender" id="gender">
                        <option value={null}>--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Password{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <input
                        type="password"
                        placeholder="Password must contain and upper case, lowercase and special character"
                        alt=""
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="edit__user__article__div">
                      <label>
                        Phone <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <PhoneInput
                        // value={result.phone}
                        country={'ng'}
                        onChange={(e) => setPhone(e)}
                        inputStyle={{
                          width: '100%',
                          margin: '0',
                        }}
                        containerStyle={{
                          marginBottom: '20px',
                        }}
                      />
                    </div>

                    <div className="edit__user__article__div">
                      <label>
                        Profile image{' '}
                        <BsAsterisk className="edit__user__article__div__icon"></BsAsterisk>
                      </label>
                      <form
                        className="image__upload"
                        onClick={() => document.querySelector('.input__field').click()}
                      >
                        <input
                          type="file"
                          className="input__field"
                          hidden
                          onChange={({ target: { files } }) => {
                            files[0] && setFileName(files[0].name);
                            if (files) {
                              const reader = new FileReader();
                              reader.readAsDataURL(files[0]);
                              reader.onloadend = () => {
                                setImage(reader.result);
                              };
                            }
                          }}
                        />

                        {image ? (
                          <img src={image} width={75} height={75} alt={fileName} />
                        ) : (
                          <MdCloudUpload color="#1475cf" size={50} />
                        )}
                      </form>
                      <div className="image__upload__label">
                        <AiFillFileImage color="#1475" />
                        <span>
                          {fileName}
                          <MdDelete
                            onClick={() => {
                              setFileName('No file selected');
                              setImage(null);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </article>
                </section>

                <span>
                  <button onClick={updateUser}>Save</button>
                  <button
                    onClick={() => {
                      navigate('/admin/user');
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            ) : (
              <></>
            )}

            {select.select2 ? (
              <div className="admin__settings__section__two__theme">
                <h1>Theme settings</h1>
                <div className="admin__settings__section__two__theme__alert">
                  <PiWarningOctagonFill className="admin__settings__section__two__theme__alert__icon" />{' '}
                  Theme color settings allows you to select different themes to suite your
                  aesthetics.
                </div>
                <div className="admin__settings__section__two__theme__alert__select">
                  <button
                    className="admin__settings__section__two__theme__alert__button one"
                    onClick={() => setTheme('#8b0000')}
                  >
                    Dark red
                  </button>
                  <button
                    className="admin__settings__section__two__theme__alert__button two"
                    onClick={() => setTheme('#b8860b')}
                  >
                    Dark golden rod
                  </button>
                  <button
                    className="admin__settings__section__two__theme__alert__button three"
                    onClick={() => setTheme('#228b22')}
                  >
                    Forest green
                  </button>
                  <button
                    className="admin__settings__section__two__theme__alert__button four"
                    onClick={() => setTheme('#000000')}
                  >
                    black
                  </button>
                  <button
                    className="admin__settings__section__two__theme__alert__button five"
                    onClick={() => setTheme('#7a5af5')}
                  >
                    purple
                  </button>
                </div>

                <div className="admin__settings__section__two__theme__alert__chart">
                  <h1>Chart color scheme</h1>
                  <article>
                    <div>
                      <SketchPicker
                        color={chartTheme.primaryColor}
                        onChangeComplete={handleChangeComplete1}
                      />
                      <h3>Primary chart color</h3>
                    </div>
                    <div>
                      <SketchPicker
                        color={chartTheme.secondaryColor}
                        onChangeComplete={handleChangeComplete2}
                      />
                      <h3>Secondary chart color</h3>
                    </div>
                  </article>
                </div>
              </div>
            ) : (
              <></>
            )}
          </section>
        </div>
      )}
    </>
  );
}

export default AdminSettings;
