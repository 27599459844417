import './Listproperty.css';
import Footer from '../../components/layout/footer/Footer';
import lsimg2 from '../../../assets/6183557_3105417.png';
import { FaGlobe } from 'react-icons/fa';
import { FaPeopleGroup } from 'react-icons/fa6';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/layout/header/Header';
import AuthContext from '../../../context/AuthProvider';
import { ScaleLoader } from 'react-spinners';

function Listproperty() {
  const { isDark, auth } = useContext(AuthContext);

  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [clicked3, setClicked3] = useState(false);
  const [clicked4, setClicked4] = useState(false);
  const [clicked5, setClicked5] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div data-theme={isDark ? 'dark' : 'light'}>
          <Header />
          <div className="ls__main">
            <section className="ls__main__section__one">
              <div className="ls__main__section__one__start">
                <h1>List your homes - Get better deals on Get Properties Solution</h1>
                <p>
                  Get Properties Solution is the destination for finding exceptional homes and
                  properties
                </p>
              </div>
              <div className="ls__main__section__one__end">
                <img src={lsimg2} alt="" />
                <div>
                  <h3>
                    We’ve partnered with apartment buildings across the US that let you rent a place
                    to live and GPS it part-time.{' '}
                  </h3>
                  <button
                    onClick={() => {
                      auth.user
                        ? navigate('/list-property/become-a-host')
                        : navigate('/login', { state: { from: location }, replace: true });
                    }}
                  >
                    Apply for listing now
                  </button>
                </div>
              </div>
            </section>
            <section className="ls__main__section__two">
              <h1>Bring the right guests within reach</h1>
              <h3>
                Connect with millions of people whose purpose, taste and budget make your property
                the perfect place to stay.
              </h3>
              <div className="ls__main__section__two__flex">
                <div>
                  <FaGlobe className="ls__main__section__two__flex__icon" />
                  <h3>Access a world of travelers</h3>
                  <p>
                    From long-range planners to last-minute bookers, bring travelers to your door
                    from around the world.
                  </p>
                </div>
                <div>
                  <FaPeopleGroup className="ls__main__section__two__flex__icon" />
                  <h3>Attract your ideal guests</h3>
                  <p>
                    Book your ideal guests—travelers who delight in what you provide and want to
                    return again and again.
                  </p>
                </div>
                <div>
                  <FaHandHoldingHeart className="ls__main__section__two__flex__icon" />
                  <h3>Grow your business</h3>
                  <p>
                    Make decisions based on real-time data, be more competitive & help increase
                    visibility and bookings.
                  </p>
                </div>
              </div>
            </section>
            <section className="ls__main__section__three">
              <h1>Frequently asked questions?</h1>
              <div className="ls__main__section__three__faq">
                <div
                  className="ls__main__section__three__faq__ques"
                  onClick={() => {
                    setClicked1(!clicked1);
                  }}
                >
                  <p>Can I continue to host on other platforms?</p>
                  <MdKeyboardArrowDown
                    className={
                      clicked1
                        ? 'ls__main__section__three__faq__icon clicked'
                        : 'ls__main__section__three__faq__icon'
                    }
                  />
                </div>
                <div
                  className={
                    clicked1
                      ? 'ls__main__section__three__faq__ans'
                      : 'ls__main__section__three__faq__ans clicked'
                  }
                >
                  <p>
                    Yes, you can. You don’t have to list your home exclusively on GPS Guide.All we
                    ask is that you synchronise your calendars with your other platforms. You do
                    this just once when you join, and it ensures there are no double bookings or
                    overlaps from your other platforms.
                  </p>
                </div>
              </div>
              <div className="ls__main__section__three__faq">
                <div
                  className="ls__main__section__three__faq__ques"
                  onClick={() => {
                    setClicked2(!clicked2);
                  }}
                >
                  <p>What happens in case of damages?</p>
                  <MdKeyboardArrowDown
                    className={
                      clicked2
                        ? 'ls__main__section__three__faq__icon clicked'
                        : 'ls__main__section__three__faq__icon'
                    }
                  />
                </div>
                <div
                  className={
                    clicked2
                      ? 'ls__main__section__three__faq__ans'
                      : 'ls__main__section__three__faq__ans clicked'
                  }
                >
                  <p>
                    We are there to help resolve your disputes personally and have a dedicated and
                    hands-on customer service team who are there to protect both our hosts and our
                    guests. As a host, you can set the security deposit for your property. Upon
                    booking, our guests agree with our Terms of Service which state that they’re
                    responsible for any damage. In the event of damage, all you need to do is notify
                    GPS Guide and the guest within 48 hours of checkout. In appropriate cases we
                    will then seek to charge the guest’s payment card. We will offer you our
                    hands-on customer service team who are there to protect both our hosts and our
                    guests. For added security, we offer the option to require ID verification for
                    guests via Onfido if you choose to enable instant book for your listing. In
                    addition, it’s helpful to note that almost 75% of our bookings are made through
                    our Matchmaking service, which means someone from our team has already
                    personally communicated with the guest.
                  </p>
                </div>
              </div>
              <div className="ls__main__section__three__faq">
                <div
                  className="ls__main__section__three__faq__ques"
                  onClick={() => {
                    setClicked3(!clicked3);
                  }}
                >
                  <p>When will I get paid?</p>
                  <MdKeyboardArrowDown
                    className={
                      clicked3
                        ? 'ls__main__section__three__faq__icon clicked'
                        : 'ls__main__section__three__faq__icon'
                    }
                  />
                </div>
                <div
                  className={
                    clicked3
                      ? 'ls__main__section__three__faq__ans'
                      : 'ls__main__section__three__faq__ans clicked'
                  }
                >
                  <p>
                    You should receive your payment on the Friday after the guest's check-in
                    following a grace period of 24 hours. This means you’ll have your payment
                    between 1 and 8 days after check-in.
                  </p>
                </div>
              </div>
              <div className="ls__main__section__three__faq">
                <div
                  className="ls__main__section__three__faq__ques"
                  onClick={() => {
                    setClicked4(!clicked4);
                  }}
                >
                  <p>I've never hosted before. Can I still apply?</p>
                  <MdKeyboardArrowDown
                    className={
                      clicked4
                        ? 'ls__main__section__three__faq__icon clicked'
                        : 'ls__main__section__three__faq__icon'
                    }
                  />
                </div>
                <div
                  className={
                    clicked4
                      ? 'ls__main__section__three__faq__ans'
                      : 'ls__main__section__three__faq__ans clicked'
                  }
                >
                  <p>
                    Yes, absolutely. We have many hosts who list their home exclusively with GPS
                    Guide.
                  </p>
                </div>
              </div>
              <div className="ls__main__section__three__faq">
                <div
                  className="ls__main__section__three__faq__ques"
                  onClick={() => {
                    setClicked5(!clicked5);
                  }}
                >
                  <p>What are your fees?</p>
                  <MdKeyboardArrowDown
                    className={
                      clicked5
                        ? 'ls__main__section__three__faq__icon clicked'
                        : 'ls__main__section__three__faq__icon'
                    }
                  />
                </div>
                <div
                  className={
                    clicked5
                      ? 'ls__main__section__three__faq__ans'
                      : 'ls__main__section__three__faq__ans clicked'
                  }
                >
                  <p>
                    To help us run the GPS Guide platform and services smoothly, we charge hosts a
                    service fee of 3% plus any local and sales taxes, with every booking. The
                    service fee allows us to offer a concierge team that can guide guests towards
                    your home, a dedicated GPS Host Team that offers support in making your home
                    more sellable, as well as customer support. .
                  </p>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Listproperty;
