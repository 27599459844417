import { useNavigate } from 'react-router-dom';
import './Head.css';
import gpslogomain from '../../../../assets/gpslogomain.png';
import { RiAccountCircleFill } from 'react-icons/ri';
import { useContext, useState, useEffect } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import AuthContext from '../../../../context/AuthProvider';
import PhoneInput from 'react-phone-input-2';
import Modal from '../../modal/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import { Image, Placeholder, Transformation } from 'cloudinary-react';
import { Toggle } from '../../../../admin/components/ui/toggle/Toggle';
import { BiMenu } from 'react-icons/bi';
import { ScaleLoader } from 'react-spinners';

function Head() {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [menu, setMenu] = useState(false);
  const [headerAccount, setHeaderAccount] = useState(false);

  const navigate = useNavigate();

  // Access useContext state variables
  const { auth, setAuth, persist, setPersist, isDark, setIsDark } = useContext(AuthContext);

  const axiosPrivate = useAxiosPrivate();

  const login = async () => {
    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/login',
        JSON.stringify({ email: email, password: password }),
        {
          heads: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );

      setAuth((prev) => ({ ...prev, user: response.data.data.user }));
      setAuth((prev) => ({ ...prev, accessToken: response.data.data.accessToken }));
    } catch (error) {
      console.log(error);
    }
    setMenu(false);
    closeModal();
  };

  const logout = async () => {
    try {
      const response = await axiosPrivate.post('api/v1/auth/logout', {
        heads: { 'Content-Type': 'application/json' },
      });

      setAuth({});
      navigate('/');
    } catch (error) {
      console.log(error);
    }
    setEmail('');
    setPassword('');
    closeModal();
    setMenu(false);
  };
  const updatePassword = async () => {
    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/update-password',
        JSON.stringify({ email: 'jamieazamosa@gmail.com', newPassword: 'Carnivals719' }),
        {
          heads: { 'Content-Type': 'application/json' },
        },
      );

      setAuth((prev) => ({ ...prev, user: null }));
    } catch (error) {
      console.log(error);
    }

    setMenu(false);
    closeModal();
  };

  const signin = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post(
        'api/v1/auth/signin',
        JSON.stringify({
          email: email,
          password: password,
          phone: phone,
          firstname: firstname,
          lastname: lastname,
        }),
        {
          heads: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
    } catch (err) {
      if (!err?.response) {
        console.log('No server response');
      } else if (err.response.status === 401) {
        console.log('Unauthorized');
      }
    }
    setMenu(false);
    closeModal();
  };

  // controls the visiility of the navbar dropdown
  const [dropdown, setDropdown] = useState({
    dropdown1: false,
    dropdown2: false,
    dropdown3: false,
    dropdown4: false,
  });

  // controls the visibility of the modal component
  const [isOpen, setIsOpen] = useState({
    signup: false,
    signup2: false,
    login: false,
    reset: false,
  });

  const openModal1 = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      login: false,
      signup2: false,
      signup: true,
      reset: false,
    }));
  };
  const openModal2 = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      signup: false,
      login: true,
      signup2: false,
      result: false,
    }));
  };

  const openModal3 = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      signup: false,
      login: false,
      signup2: true,
      reset: false,
    }));
  };

  const openModal4 = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      signup: false,
      login: false,
      signup2: false,
      reset: true,
    }));
  };

  const closeModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      signup: false,
      login: false,
      signup2: false,
      reset: false,
    }));
  };

  //persist the user login in memory state
  const togglePersist = () => {
    setPersist(!persist);
  };

  useEffect(() => {
    localStorage.setItem('persist', JSON.stringify(persist));
  }, [persist]);

  const [isLoading, setIsLoading] = useState(false);

  const cssDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: isDark ? '#fff' : '#000',
    background: isDark ? '#121212' : '#000',
  };
  return (
    <>
      {isLoading ? (
        <div style={cssDiv}>
          <ScaleLoader color={isDark ? '#fff' : '#000'} />
        </div>
      ) : (
        <div className="head">
          <div className="head__user">
            <h3
              onClick={() => {
                navigate('/list-property');
              }}
            >
              List property
            </h3>

            <div className="head__account">
              <button
                className="head__account__button"
                onClick={() => {
                  setHeaderAccount(!headerAccount);
                }}
              >
                <BiMenu className="head__user__icon" />
                {auth.user && auth.user.profile_image ? (
                  <Image
                    quality="auto:best"
                    loading="lazy"
                    cloudName="du1dvxjo8"
                    publicId={auth.user.profile_image}
                    width="30"
                    crop="scale"
                  >
                    <Placeholder type="blur"></Placeholder>
                  </Image>
                ) : (
                  <RiAccountCircleFill className="head__user__dummy__icon" />
                )}
              </button>
              <ul className={headerAccount ? 'head__submenu clicked' : 'head__submenu'}>
                <li>
                  {auth.user ? (
                    <span onClick={logout}>Log out</span>
                  ) : (
                    <span onClick={openModal2}> Login </span>
                  )}
                </li>
                <li onClick={openModal1}>Signup</li>
                {auth.user ? (
                  <li
                    onClick={() => {
                      navigate('/inbox');
                    }}
                  >
                    Inbox
                  </li>
                ) : (
                  <></>
                )}
                {auth.user && (
                  <li
                    onClick={() => {
                      navigate('/wishlist');
                    }}
                  >
                    wishlist
                  </li>
                )}
                <div>
                  {auth.user ? (
                    <li
                      onClick={() => {
                        navigate('/account/personal-info');
                      }}
                    >
                      Account
                    </li>
                  ) : (
                    <></>
                  )}
                  <li>Help center</li>
                </div>
              </ul>
            </div>
          </div>
          {/* SIDE NAVBAR COMPONENTS */}
          <span className="home_menu">
            <Toggle
              isChecked={isDark}
              handleChange={() => {
                setIsDark(!isDark);
              }}
            />
          </span>
          <BiMenu
            className="home_menu_icon"
            onClick={() => {
              setMenu(true);
            }}
          />
          <div className={menu ? 'home_sidebar_true' : 'home_sidebar'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3>
                <img
                  src={gpslogomain}
                  alt=""
                  className="gps_header2"
                  onClick={() => {
                    navigate('/');
                    setMenu(false);
                  }}
                />
              </h3>
              <AiOutlineClose
                className="home_sidebar_close"
                onClick={() => {
                  setMenu(false);
                }}
              />
            </div>

            <div className="header__navbar__account">
              {auth.user && auth.user.profile_image ? (
                <Image
                  quality="auto:best"
                  loading="lazy"
                  cloudName="du1dvxjo8"
                  publicId={auth.user.profile_image}
                  width="30"
                  crop="scale"
                >
                  {' '}
                  <Placeholder type="blur"></Placeholder>
                </Image>
              ) : (
                <RiAccountCircleFill className="header__user__icon" />
              )}
              <h3>Hello {auth.user ? auth.user.full_name : 'there'}</h3>
            </div>

            <ul className="header__navbar__dropdown">
              <li
                className="dropdown"
                onClick={() => {
                  navigate('/property-management');
                  setMenu(false);
                }}
              >
                <div className="dropdown_div">
                  <h1>PROPERTY MANAGEMENT</h1>
                </div>
              </li>
              <li
                className="dropdown"
                onClick={() => {
                  navigate('/student-property');
                  setMenu(false);
                }}
              >
                <div className="dropdown_div">
                  <h1>STUDENT PROPERTY</h1>
                </div>
              </li>
              <li
                className="dropdown"
                onClick={() => {
                  navigate('/agents');
                  setMenu(false);
                }}
              >
                <div className="dropdown_div">
                  <h1>AGENT/AGENCIES</h1>
                </div>
              </li>
            </ul>

            <ul className="home_sidebar_navbar">
              <li>
                <h3>
                  {auth.user ? (
                    <span onClick={logout}>Log out</span>
                  ) : (
                    <span onClick={openModal2}> Login </span>
                  )}
                </h3>
              </li>
              <li onClick={openModal1}>
                <h3 onClick={() => {}}>Signup</h3>
              </li>
              <li>
                <h3
                  onClick={() => {
                    navigate('/list-property');
                    setMenu(false);
                  }}
                >
                  List property
                </h3>
              </li>
              {auth.user && (
                <li>
                  <h3
                    onClick={() => {
                      navigate('/wishlist');
                      setMenu(false);
                    }}
                  >
                    Wishlist
                  </h3>
                </li>
              )}
              {auth.user && (
                <li>
                  <h3
                    onClick={() => {
                      navigate('/inbox');
                      setMenu(false);
                    }}
                  >
                    Inbox
                  </h3>
                </li>
              )}
              {auth.user && (
                <li>
                  <h3
                    onClick={() => {
                      navigate('/account/personal-info');
                      setMenu(false);
                    }}
                  >
                    Account
                  </h3>
                </li>
              )}
              <li>
                <h3>Help center</h3>
              </li>
            </ul>
          </div>

          {/* POP UP MODALS FOR SIGNIN, LOGIN AND SIGNUP */}
          <Modal isOpen={isOpen.login} onClose={closeModal}>
            <div className="login">
              <div className="login_body">
                <div className="login_form">
                  <h2>Welcome back</h2>
                  <p className="login_text">
                    New to Get Properties Solution?{''}
                    <span onClick={openModal1} className="login_link">
                      sign up
                    </span>
                  </p>
                  <input
                    className="login_input"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    required
                    autoComplete
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    className="login_input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <button className="login_button" onClick={login}>
                    Login
                  </button>
                  <div className="login__remember">
                    <input
                      type="checkbox"
                      id="persist"
                      onChange={togglePersist}
                      checked={persist}
                    />
                    <label htmlFor="persist">Remember my login</label>
                  </div>
                  <p className="login_text" onClick={openModal4}>
                    Forgot password?
                  </p>
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={isOpen.reset} onClose={closeModal}>
            <div className="signup">
              <div className="signup_div">
                <div className="signup_form">
                  <h1 style={{ margin: '20px 0' }}>
                    We’ll send a link to your email to reset your password
                  </h1>
                  <input
                    placeholder="Enter email address"
                    id="email"
                    className="signup_input"
                    type="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />
                  <div>
                    <button onClick={updatePassword}>Send reset link</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={isOpen.signup} onClose={closeModal}>
            <div className="signup">
              <div className="signup_div">
                <div className="signup_form">
                  <h1>Signin or create an account</h1>
                  <input
                    placeholder="Enter email address"
                    id="email"
                    className="signup_input"
                    type="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />
                  <div>
                    <button onClick={openModal3}>Continue</button>
                    <p>
                      already have an account? <span onClick={openModal2}>Login</span>
                    </p>
                  </div>
                </div>
                <p>
                  By signing in or creating an account, you agree with our Terms & Conditions and
                  Privacy Statement
                </p>
              </div>
            </div>
          </Modal>
          <Modal isOpen={isOpen.signup2} onClose={closeModal}>
            <div className="signup">
              <div className="signup_div">
                <div className="signup_form">
                  <h1>Let's get started</h1>
                  <input
                    placeholder="First name"
                    id="firstname"
                    className="signup_input"
                    type="text"
                    required
                    onChange={(e) => {
                      setFirstname(e.target.value);
                    }}
                    style={{ width: '49%' }}
                  />
                  <input
                    placeholder="Last name"
                    id="lastname"
                    className="signup_input"
                    type="text"
                    required
                    onChange={(e) => {
                      setLastname(e.target.value);
                    }}
                    style={{ width: '49%' }}
                  />

                  <div>
                    <PhoneInput
                      country={'ng'}
                      onChange={(phone) => setPhone({ phone })}
                      inputStyle={{
                        width: '100%',
                        margin: '0',
                      }}
                      containerStyle={{
                        marginBottom: '20px',
                      }}
                    />
                  </div>
                  <input
                    placeholder="Password"
                    id="password"
                    className="signup_input"
                    type="password"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />
                  <div>
                    <button onClose={closeModal} onClick={signin}>
                      Sign up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default Head;
