import useAuth from './useAuth';
import axios from '../api/axios';
import { ScaleLoader } from 'react-spinners';

function useRefreshToken() {
  const { setAuth } = useAuth();

  const refresh = async () => {
    // add try catch block in axios fetch
    const response = await axios.post('/api/v1/auth/refresh', {
      withCredentials: true,
    });
    setAuth((prev) => {
      return {
        ...prev,
        user: response.data.data.user,
        accessToken: response.data.data.accessToken,
      };
    });

    return response.data.accessToken;
  };

  return refresh;
}

export default useRefreshToken;
