import React from 'react';
import Card from '../card/Card.js';
import './Cardlist.css';

const Cardlist = ({ propertyResult }) => {
  return (
    <div className="cardlist">
      {propertyResult.map((item, i) => {
        return (
          <Card
            key={i}
            index={propertyResult[i].id}
            title={propertyResult[i].title}
            images={propertyResult[i].images}
            description={propertyResult[i].description}
            address={propertyResult[i].address}
            price={propertyResult[i].price}
            bedrooms={propertyResult[i].bedrooms}
            bathrooms={propertyResult[i].bathrooms}
            currency={propertyResult[i].currency}
            duration={propertyResult[i].duration}
          />
        );
      })}
    </div>
  );
};

export default Cardlist;
