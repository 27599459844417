import { RiArrowRightSLine } from 'react-icons/ri';
import './Account.transaction.css';

function AccountTransaction() {
  return (
    <div className="account__transaction">
      <section className="personal__section__one">
        <h3>
          account <RiArrowRightSLine className="personal__icon__one" /> payment and payouts
          <RiArrowRightSLine className="personal__icon__one" /> transactions
        </h3>
        <h1>Transaction history</h1>
      </section>
      <section className="account__transaction__section__two">
        <p>You have not made any transaction</p>
      </section>
    </div>
  );
}

export default AccountTransaction;
