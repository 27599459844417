import React, { useContext, useState } from 'react';
import './Footer.css';
import gpslogodark from '../../../../assets/gps logo dark.png';
import gpslogowhite from '../../../../assets/gps logo light.png';
import { FaFacebookF } from 'react-icons/fa';
import { GrInstagram } from 'react-icons/gr';
import { FaLinkedinIn } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import AuthContext from '../../../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const { isDark } = useContext(AuthContext);
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);

  return (
    <div className="footer" data-theme={isDark ? 'dark' : 'light'}>
      <section className="footer__section__one">
        <div className="footer__section__one__head">
          <img src={isDark ? gpslogowhite : gpslogodark} alt="" className="header__logo" />
          <span>
            <FaFacebookF
              className="footer__section__one__head__socials"
              onClick={() => {
                window.open(
                  'https://www.facebook.com/GetPropertiesSolutionLimited/',
                  '_blank',
                  'noopener, noreferrer',
                );
              }}
            />
            <GrInstagram
              className="footer__section__one__head__socials"
              onClick={() => {
                window.open(
                  'https://www.instagram.com/getpropertiessolution/',
                  '_blank',
                  'noopener, noreferrer',
                );
              }}
            />
            <FaLinkedinIn
              className="footer__section__one__head__socials"
              onClick={() => {
                window.open('https://www.google.com', '_blank', 'noopener, noreferrer');
              }}
            />
            <BsTwitterX
              className="footer__section__one__head__socials"
              onClick={() => {
                window.open('https://x.com/getproperties_1', '_blank', 'noopener, noreferrer');
              }}
            />
          </span>
        </div>
        <div className="footer__section__one__main">
          {/* <article className="footer__section__one__main__destinations ">
            <div
              className="footer__section__one__main__destinations__header"
              onClick={() => {
                setClicked(!clicked);
              }}
            >
              <p>Popular destinations</p>
              <MdKeyboardArrowDown
                className={
                  clicked ? 'ls__main__section__three__faq__icon  clicked' : 'ls__main__section__three__faq__icon'
                }
              />
            </div>
            <div>
              <div
                className={
                  clicked
                    ? 'footer__section__one__main__destinations__body'
                    : 'footer__section__one__main__destinations__body  clicked '
                }
              >
                <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
                <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
                <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
                <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
              </div>
            </div>
          </article> */}
          <article className="footer__section__one__main__foot">
            <div>
              <h1>Product</h1>
              <p
                onClick={() => {
                  navigate('/list-property');
                }}
              >
                List property
              </p>
              <p
                onClick={() => {
                  navigate('/terms-and-conditions');
                }}
              >
                Terms and Conditions
              </p>
            </div>
            <div>
              <h1>Contact</h1>
              <p
                onClick={() => {
                  navigate('/list-property');
                }}
              >
                FAQ
              </p>
              <p
                onClick={() => {
                  navigate('/contact-us');
                }}
              >
                Get in touch
              </p>
            </div>
          </article>
        </div>
      </section>
      <section className="footer__section__two">
        <p>&copy; Copyright Get Properties Solution 2024</p>
        {/* <p>Terms | Privacy | Legal</p> */}
      </section>
    </div>
  );
};

export default Footer;
