import { createContext, useState } from 'react';
import useLocalStorage from 'use-local-storage';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    properties: [],
    accessToken: null,
    wishlist: {
      note: null,
    },
    queryString: null,
  });
  const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || false);

  const preference = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const themePreference = '#008000';
  const [isDark, setIsDark] = useLocalStorage('isDark', preference);
  const [theme, setTheme] = useLocalStorage('theme', themePreference);
  const [chartTheme, setChartTheme] = useLocalStorage('chartTheme', {
    primaryColor: theme,
    secondaryColor: '#000000',
  });
  const [applicationId, setApplicationId] = useLocalStorage('applicationId', null);
  const [userId, setUserId] = useLocalStorage('userId', null);
  const [noticeId, setNoticeId] = useLocalStorage('noticeId', null);
  const [ticketId, setTicketId] = useLocalStorage('ticketId', null);
  const [propertyId, setPropertyId] = useLocalStorage('propertyId', null);
  const defaultCurrency = 'NGN';
  const defaultRate = 15;
  const [currency, setCurrency] = useLocalStorage('currency', defaultCurrency);
  const [rate, setRate] = useLocalStorage('rate', defaultRate);
  const [manualCurrencySet, setManualCurrencySet] = useLocalStorage('manualCurrencySet', false);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        persist,
        setPersist,
        isDark,
        setIsDark,
        theme,
        setTheme,
        chartTheme,
        setChartTheme,
        applicationId,
        setApplicationId,
        noticeId,
        setNoticeId,
        ticketId,
        setTicketId,
        userId,
        setUserId,
        propertyId,
        setPropertyId,
        currency,
        setCurrency,
        rate,
        setRate,
        manualCurrencySet,
        setManualCurrencySet,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
