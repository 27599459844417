import './Unauthorized.css';
import Header from '../../user/components/layout/header/Header';
import Footer from '../../user/components/layout/footer/Footer';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div>
      <Helmet>
        <title>403 forbidden - GPS</title>
      </Helmet>
      <Header />
      <section className="unauthorized">
        <h1>403 ACCESS FORBIDDEN</h1>
        <p>You do not have access to this page</p>
        <h3
          onClick={() => {
            navigate('/');
          }}
        >
          Go back to home page
        </h3>
      </section>
      <Footer />
    </div>
  );
}

export default NotFound;
