import React, { useContext, useEffect, useState } from 'react';
import './CardAgent.css';
import { useNavigate } from 'react-router-dom';
import { BsStarFill } from 'react-icons/bs';
import AuthContext from '../../../context/AuthProvider';
import { axiosPrivate } from '../../../api/axios';
import { Image, Placeholder, Transformation } from 'cloudinary-react';

function CardAgents({ name, position, office, mobile, email, index, userId, image }) {
  const { setApplicationId, setUserId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [average, setAverage] = useState(1);

  useEffect(() => {
    const getAllReviews = async () => {
      try {
        const response = await axiosPrivate.post(
          `api/v1/review/reviews`,
          JSON.stringify({
            type: 'agent',
            id: index,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );
        const res = response.data.data.review;
        setReviews(res);

        var total = 0;
        for (var i = 0; i < res.length; i++) {
          total += res[i].rating;
        }
        var avg = total / res.length;
        setAverage(avg);
      } catch (error) {
        console.log(error);
      }
    };

    getAllReviews();

    return;
  }, []);

  return (
    <div
      className="card__agent"
      onClick={() => {
        setUserId(userId);
        setApplicationId(index);
        navigate(`/agent/${index}`);
      }}
    >
      <div className="card__agent__segment1">
        <div>
          {
            <Image quality="auto:best" loading="lazy" cloudName="du1dvxjo8" publicId={image} width="500" crop="scale">
              {/* <Placeholder type="blur"></Placeholder> */}
            </Image>
          }
          <span>
            <h1>{name}</h1>
            <h3>{position}</h3>
            <span>
              {Array(Math.round(average))
                .fill()
                .map(() => {
                  return <BsStarFill />;
                })}
            </span>
          </span>
        </div>
      </div>
      <div className="card__agent__segment2">
        <span>
          <h3>Office</h3>
          <h1>{office}</h1>
        </span>
        <span>
          <h3>Mobile</h3>
          <h1>{mobile}</h1>
        </span>
        <span>
          <h3>Email</h3>
          <h1>{email}</h1>
        </span>
      </div>
    </div>
  );
}

export default CardAgents;
